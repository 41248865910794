import {
  SAVE_CHANGE_MODAL,
  UPDATE_ACCOUNTS_MODAL,
  UPDATE_ERROR_MODAL,
  UPDATE_TERMS_MODAL,
  UPDATE_MAX_SESSION_MODAL,
  SURVEY_MONKEY_MODAL,
  SET_API_ERROR,
} from '../config/actionTypes';

export const toggleAccountsModal = (visible) => (dispatch) => {
  const toggleModal = (visible) => {
    return {
      type: UPDATE_ACCOUNTS_MODAL,
      payload: visible,
    };
  };

  dispatch(toggleModal(visible));
};

export const setErrorModal = (visible, message, code) => {
  return {
    type: UPDATE_ERROR_MODAL,
    payload: {visible, message, code},
  };
};

export const closeErrorModal = (visible, message, code) => (dispatch) => {
  dispatch(setErrorModal(visible, message, code));
};

export const setSaveChangeModalStatus = (visible) => (dispatch) => {
  const setSaveChangeModal = (visible) => {
    return {
      type: SAVE_CHANGE_MODAL,
      payload: visible,
    };
  };

  dispatch(setSaveChangeModal(visible));
};

export const setMaxSessionModal = (visible) => (dispatch) => {
  const updateMaxSessionModal = (visible) => {
    return {
      type: UPDATE_MAX_SESSION_MODAL,
      payload: visible,
    };
  };

  dispatch(updateMaxSessionModal(visible));
};

export const setSurveyMonkeyFormModal = (visible, modal) => (dispatch) => {
  const setSurveyMonkeyForm = (visible, modal) => {
    return {
      type: SURVEY_MONKEY_MODAL,
      payload: {visible, modal},
    };
  };

  dispatch(setSurveyMonkeyForm(visible, modal));
};

export const updateTermsModal = (visible) => (dispatch) => {
  const setTermsModal = (visible) => {
    return {
      type: UPDATE_TERMS_MODAL,
      payload: {visible},
    };
  };

  dispatch(setTermsModal(visible));
};

export const setApiError = (visible, message) => {
  return {
    type: SET_API_ERROR,
    payload: {visible, message},
  };
};
