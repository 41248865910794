import React from 'react';
import {useMediaQuery} from 'react-responsive';
import {Form} from 'react-bootstrap';

import {styles} from './styles';
import {Badge, BoldText} from 'config/articles';

const introBody1 = [
  'Targets help you to set, monitor, and achieve realistic savings goals. The math is done for you so that you know how much you need to get from ',
  BoldText('where you are at now'),
  ' ',
  Badge('A'),
  ' to your ',
  BoldText('end goal'),
  ' ',
  Badge('D'),
  '.',
];
const introBody2 = [
  'You can stay on track by keeping tabs on your ',
  BoldText('daily target'),
  ' ',
  Badge('B'),
  ' and ',
  BoldText('how much you have saved'),
  ' ',
  Badge('C'),
  '.',
];
const introImageAlt =
  "A section of the Envelope's target page, featuring the target status. The status consists of the starting amount at the beginning of a progress bar, and the ending (goal) amount at the end of the progress bar. The progress bar shows how much you have saved. A daily target over the progress bar shows how much you should have in your envelope be to be on track.";

const TargetIntroduction = ({showIntro, setShowIntro}) => {
  const desktop = useMediaQuery({query: '(min-width: 768px)'});

  return (
    <div>
      <div style={{paddingTop: 40}}>
        <h2>Target Introduction</h2>
        <p>{introBody1}</p>
        <p>{introBody2}</p>
      </div>
      <div style={{paddingTop: 40, paddingBottom: 40}}>
        <img
          src={desktop ? require('../../../assets/images/targets/introduction-desktop.png') : require('../../../assets/images/targets/introduction-mobile.png')}
          alt={introImageAlt}
          width={'100%'}
        />
      </div>
      <Form.Group controlId="toggleShowIntro">
        <Form.Check
          label={"Don't show me this information again"}
          checked={showIntro}
          style={styles.toggleLabel}
          onChange={(e) => setShowIntro(e.target.checked)}
        />
      </Form.Group>
    </div>
  );
};

export default TargetIntroduction;
