import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Flexbox from 'flexbox-react';
import { Card } from 'react-bootstrap';
import { HistoryItem } from '../HistoryItem';
import ReactPaginate from 'react-paginate';

import { styles } from './styles';
import { getEnvelopeHistory } from 'actions';
import { editingAccountSelector, updatedEnvelopesSelector } from 'selectors';
import { getNumWithCommas } from 'utils/envelopeDisplay';

const HistoryListCard = ({ envelope }) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(1);
  const editingAccount = useSelector(editingAccountSelector);
  const envelopesUpdated = useSelector(updatedEnvelopesSelector);

  useEffect(() => {
    if (envelopesUpdated) {
      if (active === 1) {
        dispatch(getEnvelopeHistory(envelope.envelopeId, editingAccount.accountNumber, 1));
      }
    }
  }, [envelopesUpdated]);

  const handlePageChange = (e) => {
    dispatch(getEnvelopeHistory(envelope.envelopeId, editingAccount.accountNumber, e.selected + 1));
    setActive(e.selected + 1);
  };

  return (
    <Card style={styles.card}>
      <h3>History</h3>
      {envelope.pageCount === 0 ? (
        <p style={styles.noHistory}>No Envelope activity yet</p>
      ) : (
        <Flexbox flexDirection='column' alignItems='center'>
          <ul role='list' aria-label='Envelope History' style={{marginTop: 8, marginBottom: 0, padding: 0, width: '100%'}}>
            {envelope.transactions && envelope.transactions.map((item, index) => {
              let transactionAmount = getNumWithCommas(item.transactionAmount.toFixed(2));
              if (transactionAmount[1] === '-') {
                transactionAmount =  transactionAmount.slice(1, 2) + '$' + transactionAmount.slice(2);
              }
              return HistoryItem(item, transactionAmount, index, (index === envelope.transactions.length - 1));
            })}
          </ul>
          {(envelope.pageCount > 1) && 
            <ReactPaginate
              pageCount={envelope.pageCount}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              breakLabel='...'
              nextLabel='>'
              previousLabel='<'
              containerClassName='pagination'
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              activeClassName='active'
              onPageChange={handlePageChange}
            />
          }
        </Flexbox>
      )}
    </Card>
  );
};

export default HistoryListCard;
