import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import FocusLock from 'react-focus-lock';

import { universalStyles } from 'styles/universalStyles';
import { deleteTarget, resetTargetDeleted } from 'actions/targets';
import { targetDeletedSelector } from 'selectors';
import { tagDeleteTargetEnd } from 'services/gtm';
import { setFirstTimeUser } from 'actions';

const TargetDeleteModal = ({modalVisible, toggleModal, envelope, accountNumber, setSuccessBanner, editingAccount, user}) => {
  const dispatch = useDispatch();
  const targetDeleted = useSelector(targetDeletedSelector);

  useEffect(() => {
    if (targetDeleted) {
      tagDeleteTargetEnd(editingAccount, user, envelope.balance, envelope.target);
      envelope.target = null;
      toggleModal(false);
      dispatch(resetTargetDeleted());
      setSuccessBanner({visible: true, message: 'Target deleted successfully!'});
      if (user.isFirstTimeUser) {
        dispatch(setFirstTimeUser(false));
      }
    }
  }, [targetDeleted]);

  const confirmDelete = () => {
    dispatch(deleteTarget(envelope.envelopeId, accountNumber));
  };

  return (
    <Modal id="TargetDeleteModal" show={modalVisible} onHide={() => toggleModal(false)}>
      <FocusLock>
        <Modal.Header closeButton>
          <Modal.Title style={universalStyles.modalHeader}>Delete Target</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this target?</p>
        </Modal.Body>
        <Modal.Footer style={{justifyContent: 'center'}}>
          <Button
            id="SSOCancelLogout"
            className={'envelope-cancel-button'}
            variant="primary"
            style={universalStyles.fullWidth}
            onClick={confirmDelete}
          >
            Delete Target
          </Button>
          <Button
            id="SSOLogout"
            className={'envelope-confirmation-button'}
            variant="secondary"
            style={universalStyles.fullWidth}
            onClick={() => toggleModal(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </FocusLock>
    </Modal>
  );
};

export default TargetDeleteModal;
