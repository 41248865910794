import React from 'react';
import Flexbox from 'flexbox-react';
import {Button, OverlayTrigger, Popover, ProgressBar} from 'react-bootstrap';
import moment from 'moment';

import {styles} from './styles';
import {TARGET_STATUS} from 'config/constants';
import {calculateTargetPercentage, calculateTodayBalance, calculateTodayTarget} from 'utils/targets';
import {getNumWithCommas} from 'utils/envelopeDisplay';

const TargetTracker = ({status, target, envelopeBalance, smallDisplay}) => {
  const startAmountPercent = calculateTargetPercentage(target.startAmount, target.targetAmount);
  const progressPercent = calculateTargetPercentage(envelopeBalance, target.targetAmount);
  const todayTargetValue = calculateTodayBalance(target.targetStartDate, target.targetEndDate, target.targetAmount, target.startAmount);
  const todayPlacement = calculateTodayTarget(target.targetStartDate, target.targetEndDate, target.targetAmount, target.startAmount);

  return (
    <Flexbox justifyContent={'space-between'} style={{margin: smallDisplay ? '16px 0 24px' : '88px 0 48px', position: 'relative'}}>
      <ProgressBar
        className={'custom-progress' + (status === TARGET_STATUS.BEHIND ? ' danger' : '')}
        role="progressbar"
        now={progressPercent}
        style={styles.progressBar}
        aria-label="Target Progress Tracker"
        aria-valuenow={progressPercent.toFixed(2)}
        aria-valuetext={progressPercent.toFixed(2) + ' percent towards your target.'}
        aria-valuemin="0"
        aria-valuemax="100"
      />
      {!smallDisplay && (
        <div
          style={
            startAmountPercent > 50
              ? {...styles.startLabel, ...styles.flipStartLabel, right: `${100 - startAmountPercent}%`}
              : {...styles.startLabel, left: `${startAmountPercent}%`}
          }
        >
          <h2 className={'small-text'} style={{marginBottom: 0}}>
            Starting Amount
          </h2>
          <p style={{marginBottom: 0}}>
            {`${getNumWithCommas(target.startAmount.toFixed(2))} `}
            <span className={'small-text'}>on {moment(target.targetStartDate).format('MM/DD/YYYY')}</span>
          </p>
        </div>
      )}
      {!smallDisplay && (
        <div style={styles.endLabel}>
          <h2 className={'small-text'} style={{marginBottom: 0}}>
            Ending Amount
          </h2>
          <p style={{marginBottom: 0}}>
          <span id="targetGoal">{`${getNumWithCommas(target.targetAmount.toFixed(2))} `}</span>
            <span className={'small-text'} id="targetEndDate">by {moment(target.targetEndDate).format('MM/DD/YYYY')}</span>
          </p>
        </div>
      )}
      <OverlayTrigger
        placement={'top'}
        overlay={
          <Popover id={'TargetTodayValue'}>
            <Popover.Content>Target: {getNumWithCommas(todayTargetValue)} by today</Popover.Content>
          </Popover>
        }
      >
        <Button
          variant={'secondary'}
          className={'today-target-button'}
          title={'Today\'s Target Value'}
          style={{...styles.todayTargetButton, left: `${todayPlacement}%`}}
        />
      </OverlayTrigger>
    </Flexbox>
  );
};

export default TargetTracker;
