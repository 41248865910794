import {colors} from 'styles/config';

export const styles = {
  accordionCardWrapper: {
    border: `1px solid ${colors.storm}`,
    boxShadow: 'none',
    borderRadius: 0,
  },
  accordionCardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 0,
    marginBottom: 0,
  },
};
