import {GET_SCHEDULE, SET_ENVELOPE_SCHEDULE_UPDATED, UPDATE_SCHEDULE_ERROR, UPDATE_SCHEDULE_SUCCESS} from '../config/actionTypes';
import {API, getAuthHeader} from '../services/apiConfig';
import {handleGenericError} from '../services/errorConfig';
import {ACCOUNT_TYPE_SAVINGS, API_ERROR_MESSAGE, SCHEDULE_API} from '../config/constants';
import {setApiError} from './ui';
import moment from 'moment';

export const getSchedule = (accountNumber) => (dispatch) => {
  API.get(SCHEDULE_API, {
    headers: getAuthHeader(),
    params: {AccountNumber: accountNumber, AccountType: ACCOUNT_TYPE_SAVINGS},
  })
    .then((res) => {
      dispatch(success(res.data.envelopeSchedule));
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
      if (err.response.status !== 401) {
        dispatch(setApiError(true, API_ERROR_MESSAGE));
      }
    });

  const success = (envelopeSchedule) => {
    return {
      type: GET_SCHEDULE,
      payload: envelopeSchedule,
    };
  };
};

export const updateSchedule = (envelopeSchedule, accountNumber) => (dispatch) => {
  const body = {
    envelopeSchedule: {
      ...envelopeSchedule,
      startDate: moment(envelopeSchedule.startDate).format('YYYY-MM-DD'),
    },
    accountType: ACCOUNT_TYPE_SAVINGS,
    accountNumber,
  };

  API.put(SCHEDULE_API, body, {headers: getAuthHeader()})
    .then((res) => {
      dispatch(success(res.data.envelopeSchedule));
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
      dispatch(failure(err));
    });

  const success = (data) => {
    return {
      type: UPDATE_SCHEDULE_SUCCESS,
      payload: data,
    };
  };

  const failure = (err) => {
    return {
      type: UPDATE_SCHEDULE_ERROR,
      payload: err,
    };
  };
};

export const setEnvelopesScheduleUpdated = (updated) => (dispatch) => {
  const envelopesScheduleUpdated = (updated) => {
    return {
      type: SET_ENVELOPE_SCHEDULE_UPDATED,
      payload: updated,
    };
  };

  dispatch(envelopesScheduleUpdated(updated));
};
