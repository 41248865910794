import React from 'react';
import {PropTypes} from 'prop-types';
import {Col, Container, Row} from 'react-bootstrap';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';

import {colors} from 'styles/config';
import {styles} from './styles';

const Subheader = ({title, titleAriaLabel, arrowAction, unSaveChangesModalHidden, setModalVisibleStatus, ariaLabel, hideArrowButton, setSaveModalFunction}) => {
  return (
    <div style={styles.subheaderWrapper}>
      <Container>
        <Row>
          <Col>
            {!hideArrowButton && (
              <button
                id={'BackArrow'}
                aria-label={ariaLabel}
                type={'button'}
                className={'link-button reverse-color-focus'}
                style={styles.subheaderBackArrow}
                onClick={() => {
                  if (unSaveChangesModalHidden) {
                    arrowAction();
                  } else {
                    setModalVisibleStatus(true);
                    setSaveModalFunction(() => arrowAction);
                  }
                }}
              >
                <FontAwesomeIcon id={'BackButton'} icon={faArrowLeft} color={colors.snow} />
              </button>
            )}
            <h1 tabIndex={-1} id='subheader' className={'subpage-header'} style={styles.subheaderTitle} aria-label={titleAriaLabel}>
              {title}
            </h1>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Subheader.propTypes = {
  title: PropTypes.string,
  titleAriaLabel: PropTypes.string,
  arrowAction: PropTypes.func,
  unSaveChangesModalHidden: PropTypes.bool,
  setModalVisibleStatus: PropTypes.func,
  ariaLabel: PropTypes.string,
  hideArrowButton: PropTypes.bool,
  setSaveModalFunction: PropTypes.func,
};

Subheader.defaultProps = {
  title: '',
  titleAriaLabel: '',
  arrowAction: () => {},
  unSaveChangesModalHidden: true,
  setModalVisibleStatus: () => {},
  hideArrowButton: true,
  setSaveModalFunction: () => {},
};

export default Subheader;
