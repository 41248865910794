import React from 'react';
import {useDispatch} from 'react-redux';
import {Button, Modal} from 'react-bootstrap';

import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';
import {deleteEnvelope} from 'actions';

const ConfirmEnvelopeDeleteModal = ({deleteModal, setDeleteModal, editingAccount}) => {
  const dispatch = useDispatch();

  return (
    <Modal
      id={'Delete-Modal'}
      show={deleteModal.visible}
      onHide={() => setDeleteModal({visible: false})}
    >
      <Modal.Header closeButton>
        <Modal.Title style={universalStyles.modalHeader}>Delete Envelope</Modal.Title>
      </Modal.Header>
      <Modal.Body style={styles.modalBody}>
        Deleting an envelope cannot be undone. Any money in that envelope will return to your Allocatable funds. Do you wish to continue?
      </Modal.Body>
      <Modal.Footer style={{justifyContent: 'center'}}>
        <Button
          className={'envelope-confirmation-button'}
          variant="primary"
          style={universalStyles.fullWidth}
          onClick={() => dispatch(deleteEnvelope(deleteModal.envelopeId, editingAccount.accountNumber))}
        >
          Yes, Delete
        </Button>
        <Button
          className={'envelope-cancel-button'}
          variant="secondary"
          style={universalStyles.fullWidth}
          onClick={() => setDeleteModal({visible: false})}
        >
          No, Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmEnvelopeDeleteModal;
