export const TERMS_AND_CONDITIONS = [
  {
    text:
      'When you use BECU Envelopes, you agree to the following terms and conditions governing the application. We may change these terms at any time and we may notify you of any change either by an in-application message, USPS mail or electronic communication. You may log in to the BECU Envelopes application by using your BECU Online Banking username and password.',
    type: 'body',
  },
  {
    text:
      'BECU’s virtual envelope application is a digital tool intended to help BECU members establish budgeting goals and visualize their progress toward meeting those goals (“Envelopes”). Envelopes will display the available balance in your eligible account. The available balance in your eligible account is the amount of funds you may allocate to any of your envelopes in the application (“Allocatable”). Your Allocatable amount will increase and decrease in accordance with increases and decreases in your eligible account’s available balance. Activity in Envelopes will not impact your BECU accounts or account balances. In other words, allocating funds in Envelopes is only for budgeting purposes and will not cause any actual money movement in your BECU accounts.',
    type: 'body',
  },
  {
    text: 'Product Eligibility',
    type: 'heading',
  },
  {
    text: 'You must be a Primary Member or Joint Account Holder in good standing with an eligible BECU Account and be enrolled in Online Banking.',
    type: 'body',
  },
  {
    text: 'Account Eligibility',
    type: 'heading',
  },
  {
    text:
      'Any of your open BECU Savings Accounts are eligible for Envelopes. Account ownerships not eligible for Envelopes include, Business, HSA, Trust, Fiduciary, and Benevolent.',
    type: 'body',
  },
  {
    text: 'Changing Your Goals and Allocations',
    type: 'heading',
  },
  {
    text:
      'You can create or delete envelopes and change the amount of allocated funds anytime you are logged in to the application. When the available balance in your eligible account is reduced, your Allocatable amount will also be reduced. If the Allocatable amount drops below zero, money will be pulled from your envelope(s) in the order you have designated. If you have more than one envelope, you may designate which envelope’s allocated amount should be reduced first. For example, you can designate your least important envelope to be reduced first and designate your most important envelope to be reduced last.',
    type: 'body',
  },
];
