import {colors} from 'styles/config';

export const styles = {
  subheaderWrapper: {
    backgroundColor: colors.obsidian,
    padding: '42px 0 16px',
    textAlign: 'center',
  },
  subheaderBackArrow: {
    position: 'absolute',
    left: 12,
  },
  subheaderTitle: {
    color: colors.snow,
  },
};
