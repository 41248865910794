export const styles = {
  cardBody: {
    padding: '32px 24px 48px 24px',
  },
  cardBodyActive: {
    padding: '32px 24px 16px 24px',
  },
  envelopeName: {
    overflow: 'hidden',
    hyphens: 'auto',
    maxWidth: '88%',
  },
  envelopeControls: {
    position: 'absolute',
    bottom: -22,
    right: 24,
  },
  envelopeControlsActive: {
    position: 'relative',
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  envelopeControlsMobile: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  newAmount: {
    fontWeight: 500,
    fontSize: '1.618em',
  },
  addButton: {
    minHeight: 44,
    marginRight:'5px',
    padding: '0px',
    width: '110px',
    borderRadius: '8px',
  },
  removeButton: {
    minHeight: 44,
    padding: '0px',
    width: '130px',
    borderRadius: '8px',
  },
  cancelButton: {
    minWidth: 44,
    minHeight: 44,
    borderRadius: '0.25rem',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  clickableBadge: {
    cursor: 'pointer',
  }
};
