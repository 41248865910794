// LOGIN
export const BUSINESS_USER = 'The BECU Envelopes application is not available for Business Accounts at this time.';
export const GENERAL_ERROR = "There's a problem. Please try to log in later.";
export const LOGIN_FAILURE = 'Invalid User ID or Password. Please verify that your credentials are entered correctly.';
export const MAX_SESSION = 'You have reached the maximum session length. Please log in again to continue.';
export const RESTRICTED = 'Your Online Banking access has been restricted for further access at this time.';
export const TERMS_NOT_MET = 'You must agree to the terms and conditions to use BECU Envelopes. You can log in again to review.';

//MANAGE ENVELOPES
export const MAX_ENVELOPES = 'Each account is limited to a maximum of 12 Envelopes.';

// FIELD LEVEL VALIDATION
export const ENVELOPE_NAME_LENGTH = 'Envelope names must be between 1 and 30 characters.';
export const ENVELOPE_NAME_ALPHANUMERIC = 'Envelope names may only contain letters, numbers, and spaces.';
export const ENVELOPE_NAME_EXISTS = 'Duplicate envelope names cannot exist within an account.';

// ALLOCATION
export const OVER_MAX_ALLOCATION = 'You may not distribute more than 100% of your allocatable balance.';
