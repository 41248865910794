import React, {useEffect, useRef, useState} from 'react';
import { useSelector } from 'react-redux';
import Flexbox from 'flexbox-react';
import {Alert, Button, Card} from 'react-bootstrap';
import TargetSetupModal from '../../modals/TargetSetupModal';
import TargetProgressCard from '../TargetProgressCard';
import TargetSavingsCard from '../TargetSavingsCard';
import TargetDeleteModal from 'components/modals/TargetDeleteModal';
import TargetRecommendedActions from '../TargetRecommendedActions';
import MoveMoneyModal from 'components/modals/MoveMoneyModal';
import TargetCompleted from '../TargetCompleted';
import HistoryListCard from 'components/history/HistoryListCard';

import {colors} from 'styles/config';
import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';
import {getNumWithCommas} from 'utils/envelopeDisplay';
import { editingAccountSelector, envelopeHistorySelector } from 'selectors';
import { getTargetStatus } from 'utils/targets';
import { TARGET_STATUS } from 'config/constants';
import { tagAddTargetStart, tagDeleteTargetStart } from 'services/gtm';
import success from '../../../assets/images/icons/success.svg';

const NoTarget = (setTargetModalVisible, tagStartTarget, targetStatusRef) => (
  <Card style={styles.noTargetCard}>
    <h2 tabIndex={-1} id="targetStatus" ref={targetStatusRef} style={styles.noTargetTitle}>No Active Target</h2>
    <div>
      <p>Whether you are saving for something specific, or just starting a healthy habit, targets can help you track progress!</p>
      <Button className="add-target-button" style={{...universalStyles.noSetWidth}} onClick={() => {setTargetModalVisible(true); tagStartTarget();}}>
        Add Target
      </Button>
    </div>
  </Card>
);

const TargetSuccessBanner = ({successBanner, setSuccessBanner}) => {
  return (
    <Alert
      id="globalSaveSuccessBox"
      variant={'success'}
      show={successBanner.visible}
      dismissible
      onClose={() => setSuccessBanner({visible: false, message: ''})}
      style={{...styles.alertContainer, backgroundColor: '#d9f7ea'}}
    >
      <img src={success} style={{marginRight: 16}} aria-hidden='true' />
      <div style={{color: colors.obsidian}}>
        {successBanner.message}
      </div>
    </Alert>
  );
};

const TargetDetails = ({envelope, setTargetView, user, selectTab}) => {
  const targetStatus = envelope.target ? getTargetStatus(envelope.target, envelope.balance) : null;
  const [targetModalVisible, setTargetModalVisible] = useState(false);
  const [successBanner, setSuccessBanner] = useState({visible: false, type: ''});
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [moveMoneyModalVisible, setMoveMoneyModalVisible] = useState(false);
  const editingAccount = useSelector(editingAccountSelector);
  const targetStatusRef = useRef(null);
  const envelopeHistory = useSelector(envelopeHistorySelector);

  useEffect(() => {
    if (targetStatusRef.current) {
      targetStatusRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (successBanner && successBanner.visible) {
      const autoCloseBanner = setTimeout(() => {
        setSuccessBanner({visible: false, type: ''});
      }, 10000);

      return () => {
        clearTimeout(autoCloseBanner);
      };
    }
  }, [successBanner]);

  useEffect(() => {
    if (deleteModalVisible) {
      tagDeleteTargetStart(editingAccount, user, envelope.balance, envelope.target);
    }
  }, [deleteModalVisible]);

  const tagStartTarget = () => {
    tagAddTargetStart(editingAccount, user, envelope.balance);
  };

  return (
    <div style={{marginBottom: 32}}>
      <div style={styles.headerWrapper}>
        <Flexbox justifyContent={'space-between'} alignItems={'center'} style={styles.envelopeNameContainer}>
          <h2 id="EnvelopeName" style={styles.headerText}>
            {envelope.name}
          </h2>
          <h2 id="EnvelopeBalance" style={styles.headerText}>
            <span style={styles.smallText}>Amount </span>
            {getNumWithCommas(parseFloat(envelope.balance).toFixed(2))}
          </h2>
        </Flexbox>
        <TargetSuccessBanner successBanner={successBanner} setSuccessBanner={setSuccessBanner} />
      </div>
      <div style={{marginTop: 24}}>
        {!envelope.target ? NoTarget(setTargetModalVisible, tagStartTarget, targetStatusRef) : null}
        {envelope.target && 
          <>
            {(targetStatus === TARGET_STATUS.COMPLETED || targetStatus === TARGET_STATUS.MISSED) ? (
              <TargetCompleted envelope={envelope} setSuccessBanner={setSuccessBanner} setTargetView={setTargetView} targetStatusRef={targetStatusRef} />
            ) : (
              <>
                <TargetProgressCard target={envelope.target} envelopeBalance={envelope.balance} toggleDeleteModal={setDeleteModalVisible} toggleTargetModal={setTargetModalVisible} targetStatusRef={targetStatusRef} />
                <TargetSavingsCard target={envelope.target} envelopeBalance={envelope.balance} />
              </>
            )}
            {targetStatus === TARGET_STATUS.BEHIND && <TargetRecommendedActions envelope={envelope} toggleTargetModal={setTargetModalVisible} selectTab={selectTab} toggleMoveMoneyModal={setMoveMoneyModalVisible} />}
          </>
        }
        <HistoryListCard envelope={envelopeHistory} />
      </div>
      <TargetSetupModal
        targetModalVisible={targetModalVisible}
        setTargetModalVisible={setTargetModalVisible}
        envelope={envelope}
        user={user}
        setSuccessBanner={setSuccessBanner}
        updatingTarget={envelope.target !== null}
        toggleDeleteModal={setDeleteModalVisible}
      />
      <TargetDeleteModal
        modalVisible={deleteModalVisible}
        toggleModal={setDeleteModalVisible}
        envelope={envelope}
        accountNumber={editingAccount?.accountNumber}
        setSuccessBanner={setSuccessBanner}
        editingAccount={editingAccount}
        user={user}
      />
      {targetStatus === TARGET_STATUS.BEHIND &&
        <MoveMoneyModal
          envelope={envelope}
          modalVisible={moveMoneyModalVisible}
          setModalVisible={setMoveMoneyModalVisible}
          setTargetView={setTargetView}
          toggleSuccessBanner={setSuccessBanner}
        />
      }
    </div>
  );
};

export default TargetDetails;
