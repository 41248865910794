import axios from 'axios';
import {SERVICE_URL, HEALTH_SERVICE_URL, LOGIN_SERVICE_URL, TOKEN_TIME} from '../config/constants';
import store from '../store';

export const API = axios.create({
  baseURL: SERVICE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'ocp-apim-subscription-key': 'd7adb62db6cf48ea8674c951152f32e0',
    Pragma: 'no-cache',
  },
});

export const HEALTH_SERVICE_API = axios.create({
  baseURL: HEALTH_SERVICE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'ocp-apim-subscription-key': 'd7adb62db6cf48ea8674c951152f32e0',
    Pragma: 'no-cache',
  },
});

export const AUTH_API = axios.create({
  baseURL: LOGIN_SERVICE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'ocp-apim-subscription-key': 'd7adb62db6cf48ea8674c951152f32e0',
    'Token-Elapse-Setting': TOKEN_TIME,
    Pragma: 'no-cache',
  },
});

export const getAuthHeader = () => {
  try {
    const token = store.getState().session.token;
    return {Authorization: 'Bearer ' + token};
  } catch (err) {
    // ignore errors, silently fail if session storage is off
  }
};
