import React, { useState } from 'react';
import {useMediaQuery} from 'react-responsive';
import {Button, Col, OverlayTrigger, Popover, Row} from 'react-bootstrap';
import AlertBanner from 'components/app/AlertBanner';
import InfoTip from 'components/app/InfoTip';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle, faPlusSquare} from '@fortawesome/free-solid-svg-icons';

import {colors, fontSize} from 'styles/config';
import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';
import {MAX_ENVELOPES} from 'config/errorMessaging';
import {tagActivateChangeOrder} from 'services/gtm';
import withdraw from '../../../assets/images/icons/withdraw.svg';

const ConditionalWrapper = ({condition, wrapper, children}) => (condition ? wrapper(children) : children);

const WithdrawRow = ({envelopes, allocatableAmount, withdrawToggle, setWithdrawalToggle, editingAccount, user, addNewEnvelope}) => {
  const classes = styles();
  const fullWidthButton = useMediaQuery({query: '(max-width: 768px)'});
  const disabledToggle = envelopes.filter((env) => !env.new).length <= 1;
  const [showMaxEnvAlert, setShowMaxEnvAlert] = useState(false);

  return (
    <Row>
      <Col sm={12} md={8} style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', padding: '8px 12px 24px'}}>
        <img src={withdraw} style={{width: 24, height: 24, marginRight: 16}} alt={'withdraw'} aria-hidden={true} />
        <div>
          <div id={'withdrawal-row'} style={{color: colors.stone}}>
            <strong style={{color: colors.obsidian}}> Withdrawal Order </strong>
            Top to Bottom
            <InfoTip
              index={1}
              ariaLabel={'View withdrawal order tooltip'}
              popoverId={'Withdrawal'}
              content={
                'If a withdrawal from your savings account cannot be covered by the funds in your Allocatable balance, money will be removed from envelopes in the following order, starting with the top.'
              }
              imgName={'question'}
              allocatableAmount={allocatableAmount}
            />
          </div>
          <div className={'custom-control custom-switch'}>
            <input
              id={'WithdrawalToggle'}
              type={'checkbox'}
              readOnly
              className={withdrawToggle ? 'custom-control-input toggle-enabled' : 'custom-control-input'}
              aria-disabled={disabledToggle}
              checked={withdrawToggle}
              onClick={(e) => {
                if (envelopes.filter((env) => !env.new).length > 1) {
                  if (e.target.checked) tagActivateChangeOrder(editingAccount, user, allocatableAmount);
                  setWithdrawalToggle(e.target.checked);
                }
              }}
            />
            <label
              htmlFor={'WithdrawalToggle'}
              className={'custom-control-label'}
              style={{
                fontSize: fontSize.small,
                fontWeight: 'bold',
                color: disabledToggle ? '#B8BFC3' : colors.obsidian,
                paddingTop: 2,
              }}
            >
              Change Order
            </label>
          </div>
        </div>
      </Col>
      {!withdrawToggle && (
        <Col sm={12} md={4} style={{padding: '8px 12px 24px'}}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
            <ConditionalWrapper
              condition={envelopes.length >= 12}
              wrapper={(children) => (
                <OverlayTrigger
                  placement='top'
                  overlay={
                    <Popover id='MaxEnvelopesPopover'>
                      <Popover.Content>
                        <p style={{marginBottom: 0}}><strong>Why is this button disabled?</strong></p>
                        <p style={{marginBottom: 0}}>Each account is limited to a maximum of 12 envelopes.</p>
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <div tabIndex={0} style={{width: fullWidthButton ? '100%' : 'inherit'}}>
                    {children}
                  </div>
                </OverlayTrigger>
              )}
            >
              <Button
                id={'AddEnvelopeCardButton'}
                variant={'secondary'}
                style={{width: fullWidthButton ? '100%' : 'inherit'}}
                disabled={envelopes.length >= 12}
                aria-disabled={envelopes.length >= 12}
                className={classes.addEnvelopes + (envelopes.length < 12 ? '' : ' btn-hide-pointer')}
                aria-label={envelopes.length >= 12 ? 'Why is this button disabled? Each account is limited to a maximum of 12 envelopes. Add Envelope button.' : 'Add Envelope'}
                onClick={() => {
                  if (envelopes.length < 12) {
                    if (envelopes.length === 11) {
                      setShowMaxEnvAlert(true);
                    }
                    addNewEnvelope();
                  }
                }}
              >
                <FontAwesomeIcon icon={faPlusSquare} style={universalStyles.iconMarginRight} />
                Add Envelope
              </Button>
            </ConditionalWrapper>
          </div>
        </Col>
      )}
      <Col sm={12} md={12}>
        <AlertBanner show={() => showMaxEnvAlert} onClose={() => setShowMaxEnvAlert(false)} type={'info'} name={'alert-banner'} message={MAX_ENVELOPES} dismissible={true} icon={faInfoCircle} />
      </Col>
    </Row>
  );
};

export default WithdrawRow;
