import React from 'react';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { colors } from 'styles/config';

export const TargetMatchesBalanceAlert = ({ showAlert, setAlert }) => {
  return (
    <Alert
      id="targetMatchesBalanceAlert"
      variant={'info'}
      show={showAlert}
      dismissible
      onClose={() => setAlert(false)}
      style={{ borderColor: '#4470D6', backgroundColor: '#D7E4F4', color: colors.obsidian }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 16 }} color={'#4470d6'} />
        <div style={{ color: colors.obsidian }}>
          You already have that amount in your envelope. <strong>If you save these changes, you will reach this target and be provided with next steps.</strong>
        </div>
      </div>
    </Alert>
  );
};

export const TargetLessThanStartAmountAlert = ({ showAlert, setAlert, closeAndDelete }) => {
  return (
    <Alert
      id="targetLessThanStartAmountAlert"
      variant={'info'}
      show={showAlert}
      dismissible
      onClose={() => setAlert(false)}
      style={{ borderColor: '#4470D6', backgroundColor: '#D7E4F4', color: colors.obsidian }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 16 }} color={'#4470d6'} />
        <div style={{ color: colors.obsidian }}>
          You can <button id={`DeleteTargetLinkButton`} className={'link-button dark-button-text'} onClick={() => closeAndDelete()}>delete this Target</button> and start a new one to accommodate this new Total Amount.
        </div>
      </div>
    </Alert>
  );
};

export const TargetEndDateMatchesToday = ({ showAlert, setAlert }) => {
  return (
    <Alert
      id="targetMatchesBalanceAlert"
      variant={'info'}
      show={showAlert}
      dismissible
      onClose={() => setAlert(false)}
      style={{ borderColor: '#4470D6', backgroundColor: '#D7E4F4', color: colors.obsidian }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 16 }} color={'#4470d6'} />
        <div style={{ color: colors.obsidian }}>
          You have specified that you need this amount saved by today, but have less than the total amount in your envelope. <strong>If you save these changes, you will miss this target and be provided with next steps.</strong>
        </div>
      </div>
    </Alert>
  );
};