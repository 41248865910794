import {
  CREATE_AND_UPDATE_ENVELOPES_SUCCESS,
  CREATE_ENVELOPES_SUCCESS,
  DELETE_ENVELOPE_SUCCESS,
  GET_ENVELOPES_SUCCESS,
  RENAME_ENVELOPE_SUCCESS,
  RESET_DELETE_ENVELOPE_SUCCESS,
  RESET_RENAME_ENVELOPE_SUCCESS,
  SAVE_ENVELOPES_FAILURE,
  PAGE_REFRESH_REQUIRED,
  SET_ENVELOPES_UPDATED,
  UPDATE_ENVELOPES_SUCCESS,
  GET_ENVELOPE_HISTORY,
} from '../config/actionTypes';

const INITIAL_STATE = {
  currentEnvelopes: [],
  unAllocatedAmount: 0,
  loading: false,
  envelopeDeleted: null,
  envelopeRenamed: false,
  envelopesUpdated: false,
  initialized: false,
  saveFailed: false,
  currentHistory: [],
};

export const envelopes = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_AND_UPDATE_ENVELOPES_SUCCESS:
    case CREATE_ENVELOPES_SUCCESS: {
      const envelopeCopy = [...state.currentEnvelopes];
      const index = state.currentEnvelopes.findIndex((el) => el.accountNumber === action.payload.accountNumber);

      if (index !== -1) {
        envelopeCopy[index].envelope = action.payload.envelopes;
        envelopeCopy[index].unAllocatedAmount = action.payload.unAllocatedAmount;
      }

      return {
        ...state,
        currentEnvelopes:
          index !== -1
            ? envelopeCopy
            : state.currentEnvelopes.concat({
                accountNumber: action.payload.accountNumber,
                envelope: action.payload.envelopes,
                unAllocatedAmount: action.payload.unAllocatedAmount,
              }),
        envelopesUpdated: true,
      };
    }
    case DELETE_ENVELOPE_SUCCESS: {
      const updateEnvelopeCopy = [...state.currentEnvelopes];
      const updateIndex = state.currentEnvelopes.findIndex((el) => el.accountNumber === action.payload.envelopeAccountNumber);
      const envelopeIndex = updateEnvelopeCopy[updateIndex].envelope.findIndex((el) => el.envelopeId === action.payload.envelopeDeleted);
      updateEnvelopeCopy[updateIndex].envelope.splice(envelopeIndex,1);

      return {
        ...state,
        envelopeDeleted: {
          deletedId: action.payload.envelopeDeleted,
          unAllocatedAmount: action.payload.unAllocatedAmount,
          currentEnvelopes: updateEnvelopeCopy,
        },
      };
    }
    case GET_ENVELOPES_SUCCESS:
      return {
        ...state,
        currentEnvelopes: action.payload.envelope,
        loading: false,
        initialized: true,
      };
    case RENAME_ENVELOPE_SUCCESS: {
      const updateEnvelopeCopy = [...state.currentEnvelopes];
      const updateIndex = state.currentEnvelopes.findIndex((el) => el.accountNumber === action.payload.accountNumber);

      const envelopeIndex = updateEnvelopeCopy[updateIndex].envelope.findIndex((el) => el.envelopeId === action.payload.envelopeId);
      updateEnvelopeCopy[updateIndex].envelope[envelopeIndex].name = action.payload.newName;

      return {
        ...state,
        currentEnvelopes: updateEnvelopeCopy,
        envelopeRenamed: true,
      };
    }
    case RESET_DELETE_ENVELOPE_SUCCESS:
      return {
        ...state,
        envelopeDeleted: action.payload,
      };
    case RESET_RENAME_ENVELOPE_SUCCESS:
      return {
        ...state,
        envelopeRenamed: action.payload,
      };
    case SAVE_ENVELOPES_FAILURE:
      return {
        ...state,
        saveFailed: action.payload,
      };
    case PAGE_REFRESH_REQUIRED:
      return {
        ...state,
        refreshRequired: action.payload,
      };
    case SET_ENVELOPES_UPDATED:
      return {
        ...state,
        envelopesUpdated: action.payload,
      };
    case UPDATE_ENVELOPES_SUCCESS: {
      const updateEnvelopeCopy = [...state.currentEnvelopes];
      const updateIndex = state.currentEnvelopes.findIndex((el) => el.accountNumber === action.payload.accountNumber);

      updateEnvelopeCopy[updateIndex].envelope = action.payload.envelopes;
      updateEnvelopeCopy[updateIndex].unAllocatedAmount = action.payload.unAllocatedAmount;

      return {
        ...state,
        currentEnvelopes: updateEnvelopeCopy,
        envelopesUpdated: true,
      };
    }
    case GET_ENVELOPE_HISTORY:
      return {
        ...state,
        currentHistory: action.payload,
      };
    default:
      return state;
  }
};
