import {ENVELOPE_NAME_LENGTH, ENVELOPE_NAME_ALPHANUMERIC, ENVELOPE_NAME_EXISTS} from '../../config/errorMessaging';
import {lengthValidation, alphanumericValidation, uniqueValidation} from '../../config/validators';

const MAX_LENGTH = 30;
const NAME_FIELDNAME = 'name';

// causes a side effect and addes errors
export const validateNewEnvelope = (index, envelopes, returnErrors = false) => {
  let errors = [];
  if (lengthValidation(NAME_FIELDNAME, envelopes[index], MAX_LENGTH)) errors.push(ENVELOPE_NAME_LENGTH);
  if (alphanumericValidation(NAME_FIELDNAME, envelopes[index])) errors.push(ENVELOPE_NAME_ALPHANUMERIC);
  if (uniqueValidation(NAME_FIELDNAME, index, envelopes)) errors.push(ENVELOPE_NAME_EXISTS);

  if (returnErrors) {
    return errors;
  } else {
    envelopes[index].errors[NAME_FIELDNAME] = errors;
  }
};
