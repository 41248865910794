import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

const PageMetaData = ({pageTitle}) => {
  return (
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
  );
};

PageMetaData.propTypes = {
  pageTitle: PropTypes.string,
};

export default PageMetaData;
