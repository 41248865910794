import moment from 'moment';
import TagManager from 'react-gtm-module';
import { calculateDuration, getTargetStatus, getTargetStatusLabel } from 'utils/targets';

const mapSubscriberCode = (code) => {
  switch (code) {
    case '4':
      return 'dFHC';
    case '3':
      return 'mobile';
    case '2':
      return 'olb';
    case '0':
    case '1':
    default:
      return 'login-page';
  }
};

export const tagLogin = (personNumber, subscriberCode) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'login',
      personNumber: personNumber,
      referrer: mapSubscriberCode(subscriberCode),
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};

export const tagSelectAccount = (account, triggerLocation) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'selectAccount',
      accountType: account.accountType,
      accountNumber: account.accountNumber,
      triggerLocation,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};

export const tagCreateEnvelope = (envelope, account, allocatableAmount) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'createEnvelope',
      accountType: account.accountType,
      accountNumber: account.accountNumber,
      availableBalance: account.availableBalance,
      amountAllocatable: allocatableAmount,
      amountInitialized: envelope.balance,
      envelopeId: envelope.envelopeId,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};

export const tagDeleteEnvelope = (envelope, account, allocatableAmount) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'deleteEnvelope',
      accountType: account.accountType,
      accountNumber: account.accountNumber,
      accountBalance: account.availableBalance,
      amountAllocatable: allocatableAmount,
      envelopeAmount: envelope.balance,
      envelopeID: envelope.envelopeId,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};

export const tagAddMoney = (envelope, account, allocatableAmount) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'addMoney',
      accountType: account.accountType,
      accountNumber: account.accountNumber,
      availableBalance: account.availableBalance,
      amountAllocatable: allocatableAmount,
      originalAmount: envelope.balance,
      amountAdded: envelope.envelopeUpdateAmount,
      newAmount: envelope.balance + envelope.envelopeUpdateAmount,
      envelopeID: envelope.envelopeId,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};

export const tagSubtractMoney = (envelope, account, allocatableAmount) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'subtractMoney',
      accountType: account.accountType,
      accountNumber: account.accountNumber,
      availableBalance: account.availableBalance,
      amountAllocatable: allocatableAmount,
      originalAmount: envelope.balance,
      amountSubtracted: Math.abs(envelope.envelopeUpdateAmount),
      newAmount: envelope.balance + envelope.envelopeUpdateAmount,
      envelopeID: envelope.envelopeId,
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};

const dataLayerWithAccountAndUser = (account, user, allocatableAmount) => {
  return {
    accountType: account.accountType,
    accountNumber: account.accountNumber,
    amountAllocatable: allocatableAmount,
    availableBalance: account.availableBalance,
    personNumber: user.personNumber,
  };
};

export const tagActivateChangeOrder = (account, user, allocatableAmount) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'changeOrderActive',
      ...dataLayerWithAccountAndUser(account, user, allocatableAmount),
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagSaveChangeOrder = (account, user, allocatableAmount) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'changeOrder',
      ...dataLayerWithAccountAndUser(account, user, allocatableAmount),
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagEngagesWithChangeOrderTooltip = (account, user, allocatableAmount) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'changeOrderToolTip',
      ...dataLayerWithAccountAndUser(account, user, allocatableAmount),
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagEngagesWithAllocatableTooltip = (account, user, allocatableAmount) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'allocatableToolTip',
      ...dataLayerWithAccountAndUser(account, user, allocatableAmount),
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagForgotUser = () => {
  const tagManagerArgs = {dataLayer: {event: 'forgotUser'}};
  TagManager.dataLayer(tagManagerArgs);
};

export const tagRetrieveUser = () => {
  const tagManagerArgs = {dataLayer: {event: 'retrieveUser'}};
  TagManager.dataLayer(tagManagerArgs);
};

export const tagReturnLoginUser = () => {
  const tagManagerArgs = {dataLayer: {event: 'returnLoginUser'}};
  TagManager.dataLayer(tagManagerArgs);
};

export const tagForgotPassword = () => {
  const tagManagerArgs = {dataLayer: {event: 'forgotPassword'}};
  TagManager.dataLayer(tagManagerArgs);
};

export const tagResetPassword = () => {
  const tagManagerArgs = {dataLayer: {event: 'resetPassword'}};
  TagManager.dataLayer(tagManagerArgs);
};

export const tagReturnLoginPassword = () => {
  const tagManagerArgs = {dataLayer: {event: 'returnLoginPW'}};
  TagManager.dataLayer(tagManagerArgs);
};

export const tagHelpAndSupport = () => {
  const tagManagerArgs = {dataLayer: {event: 'helpSupport'}};
  TagManager.dataLayer(tagManagerArgs);
};

export const tagBecuOrgLink = () => {
  const tagManagerArgs = {dataLayer: {event: 'becuOrgLink'}};
  TagManager.dataLayer(tagManagerArgs);
};

export const tagBecuPhone = () => {
  const tagManagerArgs = {dataLayer: {event: 'becuPhone'}};
  TagManager.dataLayer(tagManagerArgs);
};

export const tagReturnLoginSupport = () => {
  const tagManagerArgs = {dataLayer: {event: 'returnLoginSupport'}};
  TagManager.dataLayer(tagManagerArgs);
};

export const tagLogout = (user) => {
  const tagManagerArgs = {dataLayer: {event: 'logout', personNumber: user.personNumber}};
  TagManager.dataLayer(tagManagerArgs);
};

export const tagAddEnvelopeFromHeader = (user) => {
  const tagManagerArgs = {dataLayer: {event: 'addEnvelope', personNumber: user.personNumber}};
  TagManager.dataLayer(tagManagerArgs);
};

export const tagSelectFAQCategory = (categoryName, user) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'faqCategory',
      categoryName,
      personNumber: user.personNumber,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagSelectFAQTopic = (topicName, user) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'faqTopic',
      topicName,
      personNumber: user.personNumber,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagReportBugLink = (user) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'reportBug',
      personNumber: user.personNumber,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagFeedbackLink = (user) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'feedback',
      personNumber: user.personNumber,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagTermsResponse = (accepted, user) => {
  const tagManagerArgs = {
    dataLayer: {
      event: accepted ? 'TCAgree' : 'TCDisagree',
      personNumber: user.personNumber,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagAllocationTab = (account, user) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'allocationTab',
      personNumber: user.personNumber,
      accountType: account.accountType,
      accountNumber: account.accountNumber,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagAllocationToggle = (allocationToggle, account, user) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'AAToggle',
      personNumber: user.personNumber,
      accountType: account.accountType,
      accountNumber: account.accountNumber,
      toggleState: allocationToggle ? 'On' : 'Off',
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagAllocationSettings = (schedule, amount, account, user) => {
  const numEnvelopesWithAllocation = schedule.envelope.filter((env) => env.percentageDistributionAmount !== 0).length;

  const tagManagerArgs = {
    dataLayer: {
      event: 'allocationSettings',
      personNumber: user.personNumber,
      accountType: account.accountType,
      accountNumber: account.accountNumber,
      typeOfAA: schedule.isDollarAllocation ? 'dollar' : 'percentage',
      amtAllocated: amount,
      allocationFrequency: schedule.frequencyType,
      allocationRunDate: schedule.startDate,
      numEnvelopesWithAllocation,
      allocationToggle: schedule.isActive,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagAccountOpen = (account, user) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'accountOpen',
      personNumber: user.personNumber,
      accountType: account.accountType,
      accountNumber: account.accountNumber,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagAddTargetOpen = (account, user) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'addTargetOpen',
      personNumber: user.personNumber,
      accountType: account.accountType,
      accountNumber: account.accountNumber,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagAddTargetStart = (account, user, amount) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'addTargetStart',
      personNumber: user.personNumber,
      accountType: account.accountType,
      accountNumber: account.accountNumber,
      envelopeAmount: amount,
      targetDate: undefined,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagAddTargetNext = (account, user, amount, step) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'addTargetNext',
      personNumber: user.personNumber,
      accountType: account.accountType,
      accountNumber: account.accountNumber,
      envelopeAmount: amount,
      currentStep: step,
      targetDate: undefined,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagAddTargetEnd = (account, user, amount, target) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'addTargetEnd',
      personNumber: user.personNumber,
      accountType: account.accountType,
      accountNumber: account.accountNumber,
      envelopeAmount: amount,
      targetAmount: target.targetAmount,
      targetDate: target.targetEndDate,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagDeleteTargetStart = (account, user, amount, target) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'deleteTargetStart',
      personNumber: user.personNumber,
      accountType: account.accountType,
      accountNumber: account.accountNumber,
      envelopeAmount: amount,
      targetAmount: target.targetAmount,
      targetDate: target.targetEndDate,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagDeleteTargetEnd = (account, user, amount, target) => {
  const tagManagerArgs = {
    dataLayer: {
      event: 'deleteTargetEnd',
      personNumber: user.personNumber,
      accountType: account.accountType,
      accountNumber: account.accountNumber,
      envelopeAmount: amount,
      targetAmount: target.targetAmount,
      targetDate: target.targetEndDate,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagEndTargetLink = (account, user, amount, target) => {
  const status = getTargetStatus(target, amount);
  const targetStatus = getTargetStatusLabel(status);

  const tagManagerArgs = {
    dataLayer: {
      event: 'endTargetLink',
      personNumber: user.personNumber,
      accountType: account.accountType,
      accountNumber: account.accountNumber,
      envelopeAmount: amount,
      targetAmount: target.targetAmount,
      targetDate: target.targetEndDate,
      targetStatus,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

export const tagEndTargetSubmit = (account, user, amount, target, selectionType) => {
  const savedAmount = (target.targetAmount - target.startAmount).toFixed(2);
  const duration = calculateDuration(target.targetStartDate, moment(target.targetEndDate).isSameOrBefore(moment()) ? target.targetEndDate : moment().format('YYYY-MM-DD'));

  const tagManagerArgs = {
    dataLayer: {
      event: 'endTargetSubmit',
      personNumber: user.personNumber,
      accountType: account.accountType,
      accountNumber: account.accountNumber,
      envelopeAmount: amount,
      savedAmount,
      duration,
      selectionType,
      targetDate: undefined,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};
