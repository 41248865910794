import React, {useEffect, useState, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Modal} from 'react-bootstrap';
import FocusLock from 'react-focus-lock';

import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';

import {logout, setMaxSessionModal} from 'actions';
import {setAuthError} from 'services/errorConfig';
import { getSsoAuthKey } from 'services/sessionStorage';
import {LOGIN_PAGE, MAX_SESSION_MODAL_TIME, SSO_SESSION_LOGOUT_PAGE, TOKEN_TIME} from 'config/constants';
import {uiMaxSessionModalVisibleSelector} from 'selectors';

const MaxSessionModal = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const maxSessionModalVisible = useSelector(uiMaxSessionModalVisibleSelector);
  const [minutes, setMinutes] = useState(Math.floor(TOKEN_TIME - MAX_SESSION_MODAL_TIME));
  const [seconds, setSeconds] = useState(Number(((TOKEN_TIME - MAX_SESSION_MODAL_TIME) % 1).toFixed(1)) * 60);

  const [count, setCount] = useState((TOKEN_TIME - MAX_SESSION_MODAL_TIME) * 60000); //milliseconds
  const requestRef = useRef();
  const previousTimeRef = useRef();

  const animateCountdown = (currentTime) => {
    if (previousTimeRef.current != undefined) {
      const deltaTime = currentTime - previousTimeRef.current;
      if (deltaTime > 1000 && count > 0) {
        // console.log('decrement time: ' + deltaTime); // great for debugging
        setCount((prevCount) => {
          const newCount = prevCount - deltaTime;
          setMinutes((prevMinutes) => Math.floor(newCount / 60000));
          setSeconds((prevSeconds) => Math.floor((newCount / 1000) % 60));
          return newCount;
        });
        previousTimeRef.current = currentTime;
      }
    } else {
      previousTimeRef.current = currentTime;
    }
    requestRef.current = requestAnimationFrame(animateCountdown);
  };

  useEffect(() => {
    if (maxSessionModalVisible) {
      requestRef.current = requestAnimationFrame(animateCountdown);
      return () => cancelAnimationFrame(requestRef.current);
    }
  }, [maxSessionModalVisible]); // Make sure the effect runs only once

  useEffect(() => {
    if (maxSessionModalVisible) {
      window.onpopstate = (e) => {
        dispatch(setMaxSessionModal(false));
      };
    }
  }, [maxSessionModalVisible]);

  const handleLogout = () => {
    if (getSsoAuthKey() === 'true') {
      history.replace(SSO_SESSION_LOGOUT_PAGE);
    } else {
      dispatch(logout());
      history.replace(LOGIN_PAGE);
      dispatch(setAuthError(999));
    }
  };

  return (
    <Modal id={'SessionTimeoutModal'} show={maxSessionModalVisible} onHide={() => dispatch(setMaxSessionModal(false))}>
      <FocusLock>
        <Modal.Header closeButton>
          <Modal.Title style={universalStyles.modalHeader}>Session Timeout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{marginBottom: '1rem'}}>Your session may not exceed the maximum duration.</div>
          <div id="countDownText" style={styles.countdownText}>
            Logging out in {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </div>
        </Modal.Body>
        <Modal.Footer style={{justifyContent: 'center'}}>
          <Button
            id="MaxSessionModalLogoutNow"
            className={'envelope-confirmation-button'}
            variant="secondary"
            style={universalStyles.fullWidth}
            onClick={handleLogout}
          >
            Log Out now
          </Button>
          <Button
            id="MaxSessionModalContinue"
            className={'envelope-cancel-button'}
            variant="primary"
            style={universalStyles.fullWidth}
            onClick={() => dispatch(setMaxSessionModal(false))}
          >
            {getSsoAuthKey() === 'true' ? 'Continue to use Envelopes' : 'Continue for remaining time'}
          </Button>
        </Modal.Footer>
      </FocusLock>
    </Modal>
  );
};

export default MaxSessionModal;
