// checks that the vield value respects the maximum length
// returns true if the validator finds a value greater than the provided length or 0
export const lengthValidation = (fieldName, obj, length) => {
  return obj[fieldName].length > length || obj[fieldName].length <= 0;
};

// returns true if the validator catchs a non-alphanumeric (or space) character
export const alphanumericValidation = (fieldName, obj) => {
  return /([^a-zA-Z\d\s])+/g.test(obj[fieldName]);
};

// returns true if the validator catches a duplicate
export const uniqueValidation = (fieldName, index, existingSet) => {
  const foundDuplicate = existingSet.find(
    (elem, idx) =>
      idx !== index && ((elem.touched && elem.new) || !elem.new) && elem[fieldName].toLowerCase().trim() === existingSet[index][fieldName].toLowerCase().trim()
  );
  return foundDuplicate !== undefined;
};
