import React, {useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Accordion, Card} from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import {useAccordionToggle} from 'react-bootstrap/AccordionToggle';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';

import {colors} from 'styles/config';
import {styles} from './styles';
import {SURVEY_MONKEY_MODALS} from 'config/constants';
import {tagFeedbackLink, tagSelectFAQTopic} from 'services/gtm';
import {setSurveyMonkeyFormModal} from 'actions';

const FAQAccordion = ({index, topic, categoryIndex, user}) => {
  const dispatch = useDispatch();
  const [activeAccordion, setActiveAccordion] = useState('');

  const FeedbackModalLink = () => (
    <button
      key={'FeedbackModalLink'}
      id="FAQFeedbackModalLink"
      className={'link-button'}
      onClick={() => {
        tagFeedbackLink(user);
        dispatch(setSurveyMonkeyFormModal(true, SURVEY_MONKEY_MODALS.FEEDBACK));
      }}
    >
      click this link to provide feedback
    </button>
  );

  const CustomAccordionToggle = ({eventKey, topic, callback, toggleAccordionStyle}) => {
    const currentEventKey = useContext(AccordionContext);
    const isCurrentEventKey = currentEventKey === eventKey;

    if (isCurrentEventKey) {
      tagSelectFAQTopic(topic, user);
    }

    const decoratedOnClick = useAccordionToggle(eventKey, () =>  {
      callback && callback(eventKey);
      if (!isCurrentEventKey) {
        toggleAccordionStyle(eventKey);
      } else {
        toggleAccordionStyle('');
      }
    });

    return (
      <button
        id={`AccordionToggle-${eventKey}`}
        aria-expanded={isCurrentEventKey}
        aria-controls={`Question-${eventKey}`}
        className={'link-button'}
        style={{width: 50, margin: '8px 0'}}
        onClick={decoratedOnClick}
        aria-label={isCurrentEventKey ? 'Hide answer' : 'Show answer'}
      >
        <FontAwesomeIcon icon={isCurrentEventKey ? faChevronUp : faChevronDown} />
      </button>
    );
  };

  return (
    <Accordion id={`FAQQuestionList-${index}`} style={{marginBottom: 32}}>
      {topic.questions.map((question, questionIndex) => {
        return question.q !== '' ? (
          <Card key={questionIndex} style={{...styles.accordionCardWrapper, borderBottom: (questionIndex !== topic.questions.length - 1) ? 0 : `1px solid ${colors.storm}`}}>
            <Card.Header className={'accordion-header'} style={{...styles.accordionCardHeader, backgroundColor: (categoryIndex.toString() + questionIndex.toString() === activeAccordion) ? colors.snow : colors.cloud}}>
              <div className={'FAQ-Question'} style={{width: '92%'}}>
                {question.q}
              </div>
              <CustomAccordionToggle eventKey={categoryIndex.toString() + questionIndex.toString()} topic={question.q} toggleAccordionStyle={setActiveAccordion} />
            </Card.Header>
            <Accordion.Collapse
              id={`Question-${categoryIndex}`}
              eventKey={categoryIndex.toString() + questionIndex.toString()}
              style={{backgroundColor: colors.cloud}}
              role="region"
              aria-labelledby={`AccordionToggle-${categoryIndex}`}
            >
              <Card.Body className={'FAQ-Answer'} style={{padding: 24, backgroundColor: colors.snow}}>
                {question.a}
                {question.feedbackModalLink && (
                  <>
                    <FeedbackModalLink />.
                  </>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ) : null;
      })}
    </Accordion>
  );
};

export default FAQAccordion;
