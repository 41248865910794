export const styles = {
  feedbackTab: {
    position: 'fixed',
    minHeight: 44,
    right: -30,
    zIndex: 1000,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
};
