import React, {useState} from 'react';
import {Container, Nav, Navbar} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import SSOLogoutModal from 'components/modals/SSOLogoutModal';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusSquare} from '@fortawesome/free-regular-svg-icons';

import {authSelector, mobileAppUserSelector, sessionTokenSelector, uiSaveChangeModalVisibleSelector, userSelector} from '../../../selectors';
import {FAQ_PAGE, HOME_PAGE, LOGIN_PAGE, SAVE_MODAL_DATA} from '../../../config/constants';
import {logout, setSaveChangeModalStatus, toggleAccountsModal, setSessionPath} from '../../../actions';

import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';
import {tagAddEnvelopeFromHeader, tagLogout} from 'services/gtm';
import { getSsoAuthKey } from 'services/sessionStorage';
import logo from '../../../assets/images/logo.svg';

const EnvelopeHeader = ({ noHeaderLink = false }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(userSelector);
  const sessionToken = useSelector(sessionTokenSelector);
  const loggedIn = useSelector(authSelector);
  const unSaveChangesModalHidden = useSelector(uiSaveChangeModalVisibleSelector);
  const mobileAppUser = useSelector(mobileAppUserSelector);
  const [saveChangeModalVisible, setSaveChangeModalVisible] = useState(false);
  const [saveChangeModalFunction, setSaveChangeModalFunction] = useState();
  const [ssoLogoutMoalVisible, setSsoLogoutModalVisible] = useState(false);

  const handleLogout = () => {
    dispatch(setSaveChangeModalStatus(true));
    if (getSsoAuthKey() === 'true') {
      setSsoLogoutModalVisible(true);
    } else {
      tagLogout(user);
      dispatch(logout());
      const path = {pathname: LOGIN_PAGE};
      dispatch(setSessionPath(path));
      history.replace(path);
    }
  };

  const redirectToHomepage = () => {
    dispatch(setSaveChangeModalStatus(true));
    const path = {pathname: HOME_PAGE};
    setSessionPath(path);
    history.push(path);
  };

  const navigateToFAQ = () => {
    dispatch(setSaveChangeModalStatus(true));
    const path = {pathname: FAQ_PAGE};
    dispatch(setSessionPath(path));
    history.push(path);
  };

  const setModalVisibleStatus = (status) => {
    setSaveChangeModalVisible(status);
  };

  const setModalFunction = (callback) => {
    setSaveChangeModalFunction(() => callback);
  };

  const linkClickHandler = (action) => {
    if (unSaveChangesModalHidden) {
      action();
    } else {
      setModalFunction(action);
      setModalVisibleStatus(true);
    }
  };

  const NavbarHomeLink = () => (
    <Navbar.Brand>
      <a
        id="HomeButton"
        style={{...styles.linkWrapper, pointerEvents: noHeaderLink ? 'none' : 'auto'}}
        tabIndex={noHeaderLink ? -1 : 1}
        onClick={() => linkClickHandler(redirectToHomepage)}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            linkClickHandler(redirectToHomepage);
          }
        }}
      >
        <img src={logo} style={styles.faviconImg} width={95} alt={'BECU logo'} />
        Envelopes
      </a>
    </Navbar.Brand>
  );

  return (
    <>
      <a className="skip-link" href="#main-content">Skip to main content</a>
      <Navbar id="NavBar" expand="lg" style={styles.navbar}>
        <Container>
          {NavbarHomeLink()}
          {loggedIn && sessionToken && !mobileAppUser && (
            <>
              <Navbar.Toggle id="NavBarToggle" aria-controls="basic-navbar-nav" style={{border: 'none'}} />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                  <Nav.Link
                    id="NavBarAddEnvLink"
                    onClick={() => {
                      dispatch(toggleAccountsModal(true));
                      tagAddEnvelopeFromHeader(user);
                    }}
                  >
                    <FontAwesomeIcon icon={faPlusSquare} style={{...universalStyles.iconMarginRight, ...styles.faPlusIcon}} />
                    Add Envelopes
                  </Nav.Link>
                  <Nav.Link id="NavBarFAQink" onClick={() => linkClickHandler(navigateToFAQ)} aria-label="Frequently asked questions and tips">
                    FAQs and Tips
                  </Nav.Link>

                  <Nav.Link id="NavBarLogOutLink" onClick={() => linkClickHandler(handleLogout)}>
                    Log Out
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </>
          )
        }
        </Container>
        <ConfirmationModal
          modalData={SAVE_MODAL_DATA}
          modalFunction={saveChangeModalFunction}
          showModal={saveChangeModalVisible}
          setModalVisibleStatus={setModalVisibleStatus}
        />
        <SSOLogoutModal
          modalVisible={ssoLogoutMoalVisible}
          setModalVisible={setSsoLogoutModalVisible}
        />
      </Navbar>
    </>
  );
};

export default EnvelopeHeader;
