import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import FocusLock from 'react-focus-lock';

import { colors } from 'styles/config';
import { universalStyles } from 'styles/universalStyles';
import { calculateAmountDiff, calculateTodayBalance, getTargetStatus } from 'utils/targets';
import { getNumWithCommas } from 'utils/envelopeDisplay';
import { activeEnvelopesSelector, editingAccountSelector, updatedEnvelopesSelector } from 'selectors';
import { setEnvelopesUpdated, updateEnvelopes } from 'actions';
import { ENVELOPE_UPDATE, TARGET_STATUS } from 'config/constants';
import AlertBanner from 'components/app/AlertBanner';

const MoveMoneyModal = ({ envelope, modalVisible, setModalVisible, setTargetView, toggleSuccessBanner }) => {
  const dispatch = useDispatch();
  const [moveSource, setMoveSource] = useState('');
  const [moveAmount, setMoveAmount] = useState('');
  const [sourceOptions, setSourceOptions] = useState([]);
  const [sourceBehindTarget, setSourceBehindTarget] = useState(false);
  const amountInputRef = useRef(null);
  const [saveAmountInputError, setSaveAmountInputError] = useState(false);
  const envelopesUpdated = useSelector(updatedEnvelopesSelector);
  const editingAccount = useSelector(editingAccountSelector);
  const currentEnvelopes = useSelector(activeEnvelopesSelector);
  const editingAccountEnvelopes = currentEnvelopes.find((c) => c.accountNumber === editingAccount?.accountNumber);
  const editingEnvelopes = editingAccountEnvelopes && editingAccountEnvelopes.envelope !== null ? [...editingAccountEnvelopes.envelope] : [];

  useEffect(() => {
    const envelopeSources = [{name: 'Allocatable', balance: editingAccountEnvelopes.unAllocatedAmount}, ...editingEnvelopes.filter((env) => env.envelopeId !== envelope.envelopeId)];
    setSourceOptions(envelopeSources);
  }, []);

  useEffect(() => {
    if (envelopesUpdated) {
      const updatedEnvelope = (currentEnvelopes.find((c) => c.accountNumber === editingAccount?.accountNumber)?.envelope?.find((e) => e.envelopeId === envelope.envelopeId));
      setTargetView({visible: true, envelope: updatedEnvelope});
      dispatch(setEnvelopesUpdated(false));
      resetModal();
      toggleSuccessBanner({visible: true, message: 'Funds moved successfully!'});
    }
  }, [envelopesUpdated, currentEnvelopes]);

  const updateMoneySource = (source) => {
    setMoveSource(source);
    setSaveAmountInputError(moveAmount && moveAmount > sourceOptions[source]?.balance);
    checkBehindStatus(source);
  };

  const checkBehindStatus = (source) => {
    if (sourceOptions[source]?.target) {
      const targetStatus = getTargetStatus(sourceOptions[source]?.target, sourceOptions[source]?.balance - moveAmount);
      setSourceBehindTarget(targetStatus === TARGET_STATUS.BEHIND);
    } else {
      setSourceBehindTarget(false);
    }
  };

  const submit = () => {
    let updatedEnvelopes = [{...envelope, envelopeUpdateAmount: parseFloat(moveAmount)}];

    if (moveSource !== '0') {
      updatedEnvelopes.push({...sourceOptions[moveSource], envelopeUpdateAmount: -1 * parseFloat(moveAmount)});
    }

    dispatch(updateEnvelopes(updatedEnvelopes, editingAccount, editingAccountEnvelopes.unAllocatedAmount, 0, parseFloat(moveAmount), ENVELOPE_UPDATE, true));
  };

  const resetModal = () => {
    setModalVisible(false);
    setMoveSource('');
    setMoveAmount('');
    setSaveAmountInputError(false);
    setSourceBehindTarget(false);
  };

  const behindAmount = () => {
    if (sourceOptions[moveSource]?.target) {
      const target = sourceOptions[moveSource].target;
      const value = (moveAmount < sourceOptions[moveSource]?.balance) ? sourceOptions[moveSource]?.balance - moveAmount : 0;
      return (calculateTodayBalance(target.targetStartDate, target.targetEndDate, target.targetAmount, target.startAmount) - value).toFixed(2);
    } else {
      return '0';
    }
  };

  return (
    <Modal id="MoveMoneyModal" show={modalVisible} onHide={resetModal} size='lg'>
      <FocusLock>
        <Modal.Header closeButton>
          <Modal.Title style={universalStyles.modalHeader}>Move Money</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{envelope.name} is <strong>Behind Target</strong> by {getNumWithCommas(calculateAmountDiff(envelope.balance, envelope.target))}</p>
          <Form>
            <Form.Group controlId={'moveSource'}>
              <Form.Label style={universalStyles.form.inputLabel}>From</Form.Label>
              <Form.Control
                as={'select'}
                custom
                value={moveSource}
                onChange={(e) => updateMoneySource(e.target.value)}
                style={universalStyles.form.inputContainer}
              >
                <option disabled value={''}>{''}</option>
                {sourceOptions.map((option, index) => (
                  <option key={index} value={index} disabled={option.balance === 0}>
                    {option.name}: {getNumWithCommas(option.balance.toFixed(2))}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <div>
              <Form.Label style={universalStyles.form.inputLabel}>To</Form.Label>
              <p style={{paddingLeft: 12, color: colors.stone}}>{envelope.name}: {getNumWithCommas(envelope.balance.toFixed(2))}</p>
            </div>
            <Form.Group controlId={'moveAmount'}>
              <Form.Label style={universalStyles.form.inputLabel}>Amount</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <span style={universalStyles.form.dollarSpan}>$</span>
                </InputGroup.Prepend>
                <Form.Control
                  ref={amountInputRef}
                  inputMode={'decimal'}
                  maxLength={18}
                  value={moveAmount}
                  style={{...universalStyles.form.inputContainer, borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                  onChange={(e) => {
                    const regex = /[\d.]*/;
                    const value = e.target.value.replace(/[a-zA-Z$\-,\s]/g, '').match(regex);
                    if (value !== null) {
                      setMoveAmount(value[0]);
                    }
                  }}
                  onBlur={(e) => {
                    const regex = /^\d*\.?\d{1,2}$/;
                    const value = e.target.value.replace(/^0{2,}/g, 0).replace(/^0{2,}|\$|,|-/g, '');
                    if (value.match(regex) !== null) {
                      const newValue = parseFloat(value).toFixed(2);
                      setMoveAmount(newValue);
                      setSaveAmountInputError(newValue > sourceOptions[moveSource]?.balance);
                      checkBehindStatus(moveSource);
                    } else {
                      if (value !== '') {
                        setSaveAmountInputError(true);
                      } else {
                        setMoveAmount(value);
                        setSaveAmountInputError(value > sourceOptions[moveSource]?.balance);
                        checkBehindStatus(moveSource);
                      }
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13 || e.keyCode === 32) {
                      e.preventDefault();
                      amountInputRef.current.blur();
                    }
                  }}
                  isInvalid={saveAmountInputError}
                />
                <Form.Control.Feedback type={'invalid'} role={'alert'}>
                  {moveAmount > sourceOptions[moveSource]?.balance
                    ? `Cannot move more than ${getNumWithCommas(sourceOptions[moveSource].balance.toFixed(2))}. Please change the amount to move.`
                    : 'Please enter a valid amount.'
                  }
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <AlertBanner
              show={() => sourceBehindTarget && !saveAmountInputError}
              onClose={() => setSourceBehindTarget(false)}
              type={'warning'}
              message={''}
              template={<div>This action will put your <strong>{sourceOptions[moveSource]?.name}</strong> Envelope behind target by {getNumWithCommas(behindAmount())}</div>}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer style={{justifyContent: 'flex-end'}}>
          <button
            id="CancelMoveMoneyButton"
            className={'link-button'}
            style={{marginRight: 32}}
            onClick={resetModal}
          >
            Cancel
          </button>
          <Button
            id={'ConfirmMoveMoneyButton'}
            style={universalStyles.noSetWidth}
            onClick={submit}
            disabled={!moveSource || !moveAmount || saveAmountInputError}
            aria-disabled={!moveSource || !moveAmount || saveAmountInputError}
            className={!moveSource || !moveAmount || saveAmountInputError ? 'btn-hide-pointer' : null}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </FocusLock>
    </Modal>
  );
};

export default MoveMoneyModal;
