import {colors, fontSize} from 'styles/config';

export const styles = {
  cardBody: {
    padding: '57px 24px 17px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  name: {
    fontFamily: 'MuseoSans-700',
    fontSize: fontSize.normal,
    color: colors.obsidian,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginLeft: 8,
  },
  amount: {
    fontSize: '1.618rem',
    fontFamily: 'MuseoSans-500',
    color: colors.obsidian,
    minHeight: 40,
  },
};
