/*========== ACCOUNTS ==========*/
export const GET_ACCOUNTS_SUCCESS = 'get_accounts_success';
export const SET_EDITING_ACCOUNT = 'set_editing_account';

/*========== AUTH ==========*/
export const LOGIN_FAILURE = 'login_failure';
export const LOGIN_SUCCESS = 'login_success';
export const LOGOUT_SUCCESS = 'logout_success';
export const TERMS_ACCEPTED = 'terms_accepted';

/*========== ENVELOPE ==========*/
export const CREATE_ENVELOPES_SUCCESS = 'create_envelopes_success';
export const CREATE_AND_UPDATE_ENVELOPES_SUCCESS = 'create_and_update_envelopes_success';
export const DELETE_ENVELOPE_SUCCESS = 'delete_envelope_success';
export const GET_ENVELOPES_SUCCESS = 'get_envelopes_success';
export const RENAME_ENVELOPE_SUCCESS = 'rename_envelope_success';
export const RESET_DELETE_ENVELOPE_SUCCESS = 'reset_delete_envelope_success';
export const RESET_RENAME_ENVELOPE_SUCCESS = 'reset_rename_envelope_success';
export const SAVE_ENVELOPES_FAILURE = 'save_envelopes_failure';
export const PAGE_REFRESH_REQUIRED = 'page_refresh_required_error';
export const SET_ENVELOPES_UPDATED = 'set_envelopes_updated';
export const UPDATE_ENVELOPES_SUCCESS = 'update_envelopes_success';

/*========== ENVELOPE SCHEDULE ==========*/
export const GET_ENVELOPE_HISTORY = 'get_envelope_history';

/*========== ENVELOPE SCHEDULE ==========*/
export const GET_SCHEDULE = 'get_schedule';
export const UPDATE_SCHEDULE_SUCCESS = 'update_schedule_success';
export const UPDATE_SCHEDULE_ERROR = 'update_schedule_failure';
export const SET_ENVELOPE_SCHEDULE_UPDATED = 'update_envelope_schedule';

/*========== SESSION ==========*/
export const UPDATE_SESSION_TOKEN = 'set_session_token';
export const UPDATE_SESSION_PATH = 'set_session_path';

/*========== SETTINGS ==========*/
export const UPDATE_SETTINGS_SUCCESS = 'update_settings_success';

/*========== TARGETS ==========*/
export const CREATE_TARGET_SUCCESS = 'create_target_success';
export const DELETE_TARGET_SUCCESS = 'delete_target_success';
export const END_TARGET_SUCCESS = 'end_target_success';
export const SAVE_TARGET_FAILURE = 'save_target_failure';
export const UPDATE_TARGET_SUCCESS = 'update_target_success';

/*========== UI ==========*/
export const SAVE_CHANGE_MODAL = 'update_save_change_modal';
export const UPDATE_ACCOUNTS_MODAL = 'update_accounts_modal';
export const UPDATE_ERROR_MODAL = 'update_error_modal';
export const UPDATE_TERMS_MODAL = 'update_terms_modal';
export const UPDATE_MAX_SESSION_MODAL = 'update_max_session_modal';
export const SET_API_ERROR = 'set_api_error';
export const SURVEY_MONKEY_MODAL = 'update_survey_monkey_modal';

/*========== USER ==========*/
export const GET_USER_SUCCESS = 'get_user_success';
export const SET_FIRST_TIME_USER = 'set_first_time_user';
export const UPDATE_MOBILE_APP_USER = 'update_mobile_app_user';

/*========== FINANCIAL HEALTH ==========*/
export const GET_FINANCIAL_GOAL_SUCCESS = 'get_financial_goal_success';
export const GET_FINANCIAL_TOPIC_SUCCESS = 'get_financial_topic_success';
export const CREATE_FINANCIAL_GOAL_SUCCESS = 'create_financial_goal_success';
export const CREATE_FINANCIAL_TOPIC_SUCCESS = 'create_financial_topic_success';
export const DELETE_FINANCIAL_GOAL_SUCCESS = 'delete_financial_goal_success';
export const DELETE_FINANCIAL_TOPIC_SUCCESS = 'delete_financial_goal_success';