import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Flexbox from 'flexbox-react';
import { Button, Card, Form } from 'react-bootstrap';

import { styles } from './styles';
import { universalStyles } from 'styles/universalStyles';
import { getNumWithCommas } from 'utils/envelopeDisplay';
import { calculateDuration } from 'utils/targets';
import { activeEnvelopesSelector, deletedEnvelopeSelector, editingAccountSelector, targetEndedSelector, updatedEnvelopesSelector, userSelector } from 'selectors';
import moment from 'moment';
import { EXTERNAL_TARGET_STATUS } from 'config/constants';
import { deleteIt, leaveIt, protectIt, resetTargetEnded, setFirstTimeUser, spendIt } from 'actions';
import { tagEndTargetSubmit } from 'services/gtm';
import success from '../../../assets/images/targets/success.svg';

const TargetCompleted = ({ envelope, setSuccessBanner, setTargetView, targetStatusRef }) => {
  const dispatch = useDispatch();
  const targetEnded = useSelector(targetEndedSelector);
  const envelopesUpdated = useSelector(updatedEnvelopesSelector);
  const envelopeDeleted = useSelector(deletedEnvelopeSelector);
  const editingAccount = useSelector(editingAccountSelector);
  const user = useSelector(userSelector);
  const currentEnvelopes = useSelector(activeEnvelopesSelector);
  const editingAccountEnvelopes = currentEnvelopes.find((c) => c.accountNumber === editingAccount?.accountNumber);
  const editingEnvelopes = editingAccountEnvelopes && editingAccountEnvelopes.envelope !== null ? [...editingAccountEnvelopes.envelope] : [];

  const totalSaved = envelope.target.targetAmount - envelope.target.startAmount;
  const duration = calculateDuration(envelope.target.targetStartDate, moment(envelope.target.targetEndDate).isSameOrBefore(moment()) ? envelope.target.targetEndDate : moment().format('YYYY-MM-DD'));
  const completedBelowAmount = envelope.target.targetComplete === EXTERNAL_TARGET_STATUS.REACHED && envelope.balance < envelope.target.targetAmount;
  const targetMissed = envelope.target.targetComplete === EXTERNAL_TARGET_STATUS.MISSED;
  const [selectedOption, setSelectedOption] = useState(0);
  const [options, setOptions] = useState([
    {
      id: 0,
      radioId: 'LeaveItOption',
      label: 'Leave It',
      description: 'Leave this envelope as is.',
      checked: true,
      action: () => dispatch(leaveIt(envelope.envelopeId, editingAccount.accountNumber)),
      visible: true,
    },
    {
      id: 1,
      radioId: 'SpendItOption',
      label: 'Spend It: Move this money to your Allocatable balance.',
      description: 'If you withdraw money from your account, money in your Allocatable balance will be spent first.',
      checked: false,
      action: () => dispatch(spendIt({...envelope}, editingAccount)),
      visible: envelope.balance > 0,
    },
    {
      id: 2,
      radioId: 'ProtectItOption',
      label: 'Protect It: Move this envelope to the bottom of the Withdrawal Order.',
      description: 'If you withdraw money from your account, this envelope will be the last to be taken from.',
      checked: false,
      action: () => dispatch(protectIt(envelope.envelopeId, editingAccount, [...editingEnvelopes], envelope.sortNumber)),
      visible: editingEnvelopes.length > 1 && envelope.sortNumber !== editingEnvelopes.length - 1,
    },
    {
      id: 3,
      radioId: 'DeleteItOption',
      label: 'Delete It: Remove the envelope.',
      description: 'All money in this envelope will go back to your Allocatable.',
      checked: false,
      action: () => dispatch(deleteIt(envelope.envelopeId, editingAccount)),
      visible: true,
    },
  ]);

  useEffect(() => {
    switch(options[selectedOption].id) {
      case 0:
        if (targetEnded) {
          endTargetSuccess();
        }
        break;
      case 1:
        if (targetEnded && envelopesUpdated) {
          endTargetSuccess();
          envelope.balance = 0;
        }
        break;
      case 2:
        if (targetEnded && envelopesUpdated) {
          endTargetSuccess();
        }
        break;
      case 3:
        if (targetEnded && envelopeDeleted) {
          setTargetView({visible: false, envelope: null});
          checkNewUser();
        }
        break;
      default:
        break;
    }
  }, [targetEnded, envelopesUpdated, envelopeDeleted]);

  const checkNewUser = () => {
    if (user.isFirstTimeUser) {
      dispatch(setFirstTimeUser(false));
    }
  };

  const endTargetSuccess = () => {
    envelope.target = null;
    setSuccessBanner({visible: true, message: 'Target ended successfully!'});
    dispatch(resetTargetEnded());
    checkNewUser();
  };

  const toggleRadio = (index) => {
    if (!options[index].checked) {
      let optionsCopy = [...options];
      optionsCopy.forEach((option, copyIndex) => {
        option.checked = index === copyIndex;
      });
      setOptions(optionsCopy);
      setSelectedOption(index);
    }
  };

  const RadioLabel = (label, description) => (
    <div>
      <strong>{label}</strong>
      <p style={{...styles.bodyText, fontSize: '90%'}}>{description}</p>
    </div>
  );

  return (
    <Card style={styles.card}>
      <Flexbox justifyContent={'space-between'} alignItems={'flex-start'}>
        <div style={{maxWidth: '80%'}}>
          <h3 tabIndex={-1} ref={targetStatusRef} id='targetStatus' style={{marginBottom: 0}}>{targetMissed ? (envelope.balance < envelope.target.targetAmount ? 'Target Was Missed' : 'Target Reached Late') : 'Target Reached!'}</h3>
          <p style={{...styles.bodyText, marginBottom: completedBelowAmount ? 0 : '1rem'}}>{targetMissed ? 'You were not able to reach' : 'You saved'} <span>{getNumWithCommas(totalSaved.toFixed(2))}</span> in <span>{duration}</span></p>
          {completedBelowAmount && <p style={styles.bodyText}>You met this target by <span>{moment(envelope.target.targetEndDate).format('MM/DD/YYYY')}</span>. Money has since been removed from this Envelope.</p>}
        </div>
        {!targetMissed && <img src={success} aria-hidden='true' />}
      </Flexbox>
      <Form>
        <p style={styles.bodyText}>What would you like to do with this envelope?</p>
        {options.map((option, index) => (
          option.visible &&
            <div key={index} onClick={() => toggleRadio(index)}>
              <Form.Check
                id={option.radioId}
                type={'radio'}
                className="radio-button"
                label={RadioLabel(option.label, option.description)}
                checked={option.checked}
                onChange={() => toggleRadio(index)}
              />
            </div>
        ))}
        <Button
          id={'EndTarget'}
          variant={'primary'}
          style={universalStyles.noSetWidth}
          onClick={() => {
            options[selectedOption].action();
            tagEndTargetSubmit(editingAccount, user, envelope.balance, envelope.target, options[selectedOption].label);
          }}
        >
          End Target
        </Button>
      </Form>
    </Card>
  );
};

export default TargetCompleted;
