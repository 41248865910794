import { colors } from 'styles/config';

export const styles = {
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {
    color: colors.obsidian,
    width: '3rem',
    height: '3rem',
  },
  title: {
    fontFamily: 'MuseoSans-700',
    fontSize: '1.5rem',
    margin: '2rem 0',
  },
};