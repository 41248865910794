import {colors} from 'styles/config';

export const styles = {
  footer: {
    width: '100%',
    backgroundColor: colors.cloud,
    padding: '16px 0',
  },
  footerTerms: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
