import {colors, fontSize} from 'styles/config';

export const styles = {
  container: {
    backgroundColor: colors.snow,
    marginLeft: -24,
    marginRight: -24,
  },
  headerWrapper: {
    backgroundColor: colors.cloud,
    boxShadow: '0px 3px 3px #bcbfc2',
    padding: '16px 24px',
    borderBottom: '1px solid #b8bfc3',
    position: 'sticky',
    top: 0,
    zIndex: 100,
    margin: '-16px 0 0',
  },
  allocatableAmount: {
    fontFamily: 'MuseoSans-500',
    fontWeight: 500,
    fontSize: fontSize.big,
    marginRight: '0.5rem',
  },
  allocatableLabel: {
    color: colors.stone,
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontSize: fontSize.normal,
  },
  saveChangesButton: {
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: '0.25rem',
    position: 'sticky',
    bottom: 0,
    zIndex: 100,
  },
  toggleLabel: {
    fontSize: fontSize.small,
    fontWeight: 'bold',
    color: colors.obsidian,
    paddingTop: 2,
  },
  allocationTile: {
    borderTop: `1px solid ${colors.cloud}`,
    margin: 0,
    padding: '0 12px',
  },
  smallBoldText: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  tableHeader: {
    width: '50%',
    backgroundColor: colors.cloud,
    border: '1px solid #D3D3CC',
    padding: 12,
  },
  inputLabel: {
    fontSize: '0.786em',
    fontWeight: 600,
    color: colors.obsidian,
  },
  radioInputLabel: {
    color: colors.stone,
    fontWeight: 600,
    fontSize: '0.875em',
  },
  inputContainer: {
    border: `1px solid ${colors.stone}`,
    borderRadius: 2,
    height: 38,
  },
  noEnvelopesContainer: {
    width: '100%',
    border: `1px solid ${colors.stone}`,
    borderRadius: '0 0 4px 4px',
    padding: '0.375rem 0.75rem',
  },
  tableInputAppend: {
    width: '100%',
    backgroundColor: colors.cloud,
    border: '1px solid #D3D3CC',
    borderRadius: 0,
    padding: 12,
    fontSize: 14,
    color: '#000000',
  },
  envelopeName: {
    width: '100%',
    overflow: 'hidden',
    hyphens: 'auto',
    wordBreak: 'break-word',
  },
};
