import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Flexbox from 'flexbox-react';
import {Card} from 'react-bootstrap';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import WithdrawOrderTile from '../WithdrawOrderTile';

import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';
import {envelopeType} from 'types';
import {getNumWithCommas} from 'utils/envelopeDisplay';
import grip from '../../../assets/images/icons/grip.svg';

const useSensor = (api) => {
  const move = (e) => {
    const preDrag = api.tryGetLock(e.detail.draggableId);
    if (!preDrag) {
      return;
    }

    const drag = preDrag.snapLift();

    setTimeout(() => {
      if (e.detail.direction === 'up') {
        drag.moveUp();
      } else {
        drag.moveDown();
      }
      setTimeout(() => {
        drag.drop();
      }, 200);
    }, 100);
  };

  useEffect(() => {
    window.addEventListener('move', move);

    return () => {
      window.removeEventListener('move', move);
    };
  }, []);
};

const EnvelopeWithdrawOrder = ({envelopes, onDragEnd}) => {
  const sortableEnvelopes = envelopes.filter((env) => !env.new);

  const getEnvelopeAmount = (envelope) => {
    const calculatedNewAmount = getNumWithCommas((parseFloat(envelope.balance) + envelope.envelopeUpdateAmount).toFixed(2));
    return calculatedNewAmount[1] !== '-' ? calculatedNewAmount : calculatedNewAmount.slice(1, 2) + '$' + calculatedNewAmount.slice(2);
  };

  const ReorderCard = ({provided, envelope}) => {
    const envelopeAmount = getEnvelopeAmount(envelope);
    return (
      <Card
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{...universalStyles.envelopeCard.card, ...provided.draggableProps.style}}
      >
        <WithdrawOrderTile
          currentIndex={envelope.sortNumber + 1}
          previousIndex={envelope.originalSort + 1}
          numEnvelopes={sortableEnvelopes.length}
          reordering={true}
          draggableId={String(envelope.envelopeId)}
        />
        <Card.Body style={styles.cardBody}>
          <Flexbox alignItems={'center'} style={{maxWidth: '71%', minHeight: 40}}>
            <img src={grip} className={'grip'} alt={'grip'} style={{width: 24, height: 24, margin: 8}} />
            <div name="envelopeName" style={styles.name}>
              {envelope.name}
            </div>
          </Flexbox>
          <Flexbox name="envelopeAmount" alignItems={'center'} style={styles.amount}>
            {envelopeAmount}
          </Flexbox>
        </Card.Body>
      </Card>
    );
  };

  ReorderCard.propTypes = {
    provided: PropTypes.shape({
      innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({current: PropTypes.any})]),
      draggableProps: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({current: PropTypes.any})]),
      droppableProps: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({current: PropTypes.any})]),
      dragHandleProps: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({current: PropTypes.any})]),
      placeholder: PropTypes.string,
    }),
    envelope: envelopeType,
  };

  return (
    <DragDropContext style={{overflow: 'auto'}} sensors={[useSensor]} onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {sortableEnvelopes.map((envelope, index) => (
              <Draggable key={envelope.envelopeId} draggableId={String(envelope.envelopeId)} index={index}>
                {(provided) => <ReorderCard provided={provided} envelope={envelope} index={index} />}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

EnvelopeWithdrawOrder.propTypes = {
  envelopes: PropTypes.arrayOf(envelopeType),
  onDragEnd: PropTypes.func,
};

export default EnvelopeWithdrawOrder;
