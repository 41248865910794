export const styles = {
  toggleButton: {
    marginLeft: 4,
  },
  toggleIcon: {
    width: 16,
    height: 16,
    marginTop: -3,
    marginLeft: 4,
    marginRight: 4,
  },
};
