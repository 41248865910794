import {API, getAuthHeader} from 'services/apiConfig';
import {ACCOUNT_TYPE_SAVINGS, ENVELOPES_API, ENVELOPE_SORT_UPDATE, TARGETS_API} from 'config/constants';
import {handleGenericError} from 'services/errorConfig';
import {CREATE_TARGET_SUCCESS, DELETE_ENVELOPE_SUCCESS, DELETE_TARGET_SUCCESS, END_TARGET_SUCCESS, SAVE_TARGET_FAILURE, UPDATE_ENVELOPES_SUCCESS, UPDATE_TARGET_SUCCESS} from 'config/actionTypes';
import { getSchedule } from './envelopeSchedule';

export const createTarget = (target, accountNumber) => (dispatch) => {
  const body = {
    target,
    accountNumber,
    accountType: ACCOUNT_TYPE_SAVINGS,
  };

  API.post(TARGETS_API, body, {headers: getAuthHeader()})
    .then((res) => {
      dispatch(success());
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
      dispatch(setTargetFailedStatus(true));
    });

  const success = () => {
    return {
      type: CREATE_TARGET_SUCCESS,
      payload: true,
    };
  };
};

export const updateTarget = (target, accountNumber) => (dispatch) => {
  const body = {
    target,
    accountNumber,
    accountType: ACCOUNT_TYPE_SAVINGS,
  };

  API.put(TARGETS_API, body, {headers: getAuthHeader()})
    .then((res) => {
      dispatch(success());
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
      dispatch(setTargetFailedStatus(true));
    });

  const success = () => {
    return {
      type: UPDATE_TARGET_SUCCESS,
      payload: true,
    };
  };
};

export const deleteTarget = (envelopeId, accountNumber) => (dispatch) => {
  const params = {
    envelopeId,
    accountNumber,
    accountType: ACCOUNT_TYPE_SAVINGS,
  };

  API.delete(TARGETS_API, {headers: getAuthHeader(), params})
    .then((res) => {
      dispatch(success());
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
    });

  const success = () => {
    return {
      type: DELETE_TARGET_SUCCESS,
      payload: true,
    };
  };
};

export const leaveIt = (envelopeId, accountNumber) => (dispatch) => {
  const params = {
    envelopeId,
    accountNumber,
    accountType: ACCOUNT_TYPE_SAVINGS,
  };

  API.delete(TARGETS_API, {headers: getAuthHeader(), params})
    .then(() => {
      dispatch(endSuccess());
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
  });
};

export const spendIt = (envelope, editingAccount) => (dispatch) => {
  const params = {
    envelopeId: envelope.envelopeId,
    accountNumber: editingAccount.accountNumber,
    accountType: ACCOUNT_TYPE_SAVINGS,
  };

  const body = {
    envelopeRequest: [{...envelope, envelopeUpdateAmount: -1 * envelope.balance, balance: 0}],
    accountTransactionRequest: {
      accountNumber: editingAccount.accountNumber,
      type: ACCOUNT_TYPE_SAVINGS,
    },
  };

  API.delete(TARGETS_API, {headers: getAuthHeader(), params})
    .then(() => {
      dispatch(endSuccess());
      API.put(ENVELOPES_API, body, {headers: getAuthHeader()})
        .then((res) => {
          dispatch(updateSuccess(res.data.envelope[0], editingAccount));
        })
        .catch((err) => {
          handleGenericError(err, dispatch);
      });
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
  });
};

const addToUpdateList = (updateList, env) => {
  updateList.push({
    balance: env.balance,
    sortNumber: env.sortNumber,
    envelopeId: env.envelopeId,
    name: env.name,
    updateType: ENVELOPE_SORT_UPDATE,
  });
};

export const protectIt = (envelopeId, editingAccount, envelopes, envelopeSortNumber) => (dispatch) => {
  const params = {
    envelopeId,
    accountNumber: editingAccount.accountNumber,
    accountType: ACCOUNT_TYPE_SAVINGS,
  };

  let updateList = [];
  envelopes.forEach((env, index) => {
    if (env.envelopeId === envelopeId) {
      env.sortNumber = envelopes.length - 1;
      env.originalSort = env.sortNumber;
      addToUpdateList(updateList, env);
    } else {
      if (env.sortNumber > envelopeSortNumber) {
        env.sortNumber -= 1;
        env.originalSort = env.sortNumber;
        addToUpdateList(updateList, env);
      }
    }
  });

  const body = {
    envelopeRequest: updateList,
    accountTransactionRequest: {
      accountNumber: editingAccount.accountNumber,
      type: ACCOUNT_TYPE_SAVINGS,
    },
  };

  API.delete(TARGETS_API, {headers: getAuthHeader(), params})
    .then(() => {
      dispatch(endSuccess());
      API.put(ENVELOPES_API, body, {headers: getAuthHeader()})
        .then((res) => {
          dispatch(updateSuccess(res.data.envelope[0], editingAccount));
        })
        .catch((err) => {
          handleGenericError(err, dispatch);
      });
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
  });
};

export const deleteIt = (envelopeId, editingAccount) => (dispatch) => {
  const targetParams = {
    envelopeId,
    accountNumber: editingAccount.accountNumber,
    accountType: ACCOUNT_TYPE_SAVINGS,
  };

  const envelopeParams = {
    envelopeId,
    accountNumber: editingAccount.accountNumber,
  };

  API.delete(TARGETS_API, {headers: getAuthHeader(), params: targetParams})
    .then(() => {
      dispatch(endSuccess());
      API.delete(ENVELOPES_API, {headers: getAuthHeader(), params: envelopeParams})
        .then((res) => {
          if (process.env.REACT_APP_AA_FLAG === 'true') {
            dispatch(getSchedule(editingAccount.accountNumber));
          }
          dispatch(deleteSuccess(envelopeId, res.data.envelope[0], editingAccount.accountNumber));
        })
        .catch((err) => {
          handleGenericError(err, dispatch);
      });
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
  });
};

export const resetTargetCreated = () => {
  return {
    type: CREATE_TARGET_SUCCESS,
    payload: false,
  };
};

export const resetTargetUpdated = () => {
  return {
    type: UPDATE_TARGET_SUCCESS,
    payload: false,
  };
};

export const resetTargetDeleted = () => {
  return {
    type: DELETE_TARGET_SUCCESS,
    payload: false,
  };
};

export const resetTargetEnded = () => {
  return {
    type: END_TARGET_SUCCESS,
    payload: false,
  };
};

export const setTargetFailedStatus = (failed) => {
  return {
    type: SAVE_TARGET_FAILURE,
    payload: failed,
  };
};

const endSuccess = () => {
  return {
    type: END_TARGET_SUCCESS,
    payload: true,
  };
};

const updateSuccess = (data, editingAccount) => {
  return {
    type: UPDATE_ENVELOPES_SUCCESS,
    payload: {
      envelopes: data.envelope,
      unAllocatedAmount: data.unAllocatedAmount,
      accountNumber: editingAccount.accountNumber,
    },
  };
};

const deleteSuccess = (envelopeDeleted, data, envelopeAccountNumber) => {
  return {
    type: DELETE_ENVELOPE_SUCCESS,
    payload: {envelopeDeleted, unAllocatedAmount: data.unAllocatedAmount, envelopeAccountNumber},
  };
};
