import React, { forwardRef, useCallback, useState } from 'react';
import {Button, Col, Dropdown} from 'react-bootstrap';
import {useMediaQuery} from 'react-responsive';
import {isMobile, isIOS} from 'react-device-detect';

import {colors} from 'styles/config';
import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';

const Navigation = (data, columnStyle, handleStepClick) => {
  const isLargeScreen = useMediaQuery({query: '(min-width: 768px)'});
  const [expanded, setExpanded] = useState(false);

  const handleToggle = useCallback((isOpen, e) => {
    setExpanded(isOpen);
  }, []);

  const CustomToggle = forwardRef(({children, onClick, expanded, ...props}, ref) => (
    <Button
      ref={ref}
      {...props}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      variant='secondary'
      aria-label={`Jump to a Section, ${expanded ? 'expanded' : 'collapsed'}`}
    >
      {children}
    </Button>
  ));

  CustomToggle.displayName = 'CustomToggle';

  return data.steps ? (
    <Col md={{span: 3, order: 12}} className={'pb-0'} style={{borderLeft: `1px solid ${colors.cloud}`, ...columnStyle}}>
      {isLargeScreen ? (
        <div>
          <p style={styles.bold}>Jump to a Section</p>
          {data.steps.map((step, index) => (
            <p key={index}>
              Step {index + 1} |{' '}
              <span
                tabIndex={0}
                aria-label={`Jump to ${step.title.toLowerCase()} section`}
                onKeyDown={(e) => {
                  if (e.keyCode === 13 || e.keyCode === 32) {
                    handleStepClick(index + 1);
                  }
                }}
                onClick={() => handleStepClick(index + 1)}
                className={'link-button'}
              >
                <button className={'link-button'} style={{display: 'contents'}}>
                  {step.title}
                </button>
              </span>
            </p>
          ))}
        </div>
      ) : (
        <Dropdown onToggle={handleToggle} className={'help-category'} style={styles.dropdown}>
          <Dropdown.Toggle
            as={(isMobile && isIOS) ? CustomToggle : Button}
            ref={(refProps) => {
              if (refProps) {
                return { ...refProps };
              }
              return null;
            }}
            id="dropdownMenuLink"
            variant={'secondary'}
            style={universalStyles.fullWidth}
            expanded={expanded}
          >
            Jump to a Section
          </Dropdown.Toggle>
          <Dropdown.Menu style={{transform: 'none'}}>
            {data.steps.map((step, index) => (
              <Dropdown.Item
                key={index}
                aria-label={`Jump to ${step.title.toLowerCase()} section`}
                className={'article-dropdown-item'}
                onClick={() => handleStepClick(index + 1)}
              >
                Step {index + 1} | <span style={styles.bold}>{step.title}</span>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Col>
  ) : (
    null
  );
};

export default Navigation;
