import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Card, Form, InputGroup} from 'react-bootstrap';

import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';
import {envelopeType} from 'types';

const NewEnvelopeCard = ({envelope, index, updateNewEnvelopeName, updateEnvelopeValues, removeNewEnvelope}) => {
  const [name, setName] = useState(envelope.name);
  const [envelopeBalance, setEnvelopeBalance] = useState('');
  const amountInputRef = useRef(null);
  const nameInputRef = useRef(null);
  const classes = styles();

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  return (
    <Card style={universalStyles.envelopeCard.card}>
      <Card.Body className={classes.cardBody}>
        <div className={classes.bodyContent}>
          <Form>
            <Form.Group controlId={`EnvelopeNameInput-${envelope.envelopeId}`}>
              <Form.Label style={universalStyles.form.inputLabel}> Envelope Name </Form.Label>
              <Form.Control
                required
                type={'text'}
                value={name}
                ref={nameInputRef}
                onChange={(e) => {
                  setName(e.target.value);
                  updateNewEnvelopeName(index, e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    nameInputRef.current.blur();
                  }
                }}
                style={universalStyles.form.inputContainer}
                isInvalid={envelope.errors?.name?.length > 0}
              />
              <Form.Control.Feedback type={'invalid'}>
                {envelope.errors.name?.map((error, index) => (
                  <div role={'alert'} key={'e' + index}>
                    {error}
                  </div>
                ))}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </div>
      </Card.Body>
      <Card.Footer style={{borderTop: 'none'}}>
        <Form>
          <Form.Group controlId={`EnvelopeBalanceInput-${envelope.envelopeId}`}>
            <Form.Label style={universalStyles.form.inputLabel}> Initial Amount </Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <span style={universalStyles.form.dollarSpan}>$</span>
              </InputGroup.Prepend>
              <Form.Control
                ref={amountInputRef}
                inputMode={'decimal'}
                disabled={!name}
                maxLength={18} //max length 13 digits plus symbols
                value={envelopeBalance}
                style={{...universalStyles.form.inputContainer, textAlign: 'right', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                onChange={(e) => {
                  const regex = /[\d.]*/;
                  const value = e.target.value.replace(/[a-zA-Z$\-,\s]/g, '').match(regex);
                  if (value !== null) {
                    setEnvelopeBalance(value[0]);
                  }
                }}
                onBlur={(e) => {
                  const regex = /^\d*\.?\d{1,2}$/;
                  const value = e.target.value.replace(/^0{2,}/g, 0).replace(/^0{2,}|\$|,|-/g, '');
                  if (value.match(regex) !== null) {
                    setEnvelopeBalance(parseFloat(value).toFixed(2));
                    updateEnvelopeValues(index, parseFloat(value).toFixed(2), 1);
                  } else {
                    if (value !== '') {
                      updateEnvelopeValues(index, 0, 1, true);
                    } else {
                      setEnvelopeBalance(value);
                      updateEnvelopeValues(index, 0, 1);
                    }
                  }
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13 || e.keyCode === 32) {
                    e.preventDefault();
                    amountInputRef.current.blur();
                  }
                }}
                isInvalid={envelope.errors?.invalidEntry}
              />
              <Form.Control.Feedback type={'invalid'} role={'alert'} style={{textAlign: 'right'}}>
                Please enter a valid amount.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Form>
      </Card.Footer>
      <button
        aria-label={'Remove new envelope'}
        name="close"
        className={classes.close}
        onClick={() => removeNewEnvelope(index, envelope.errors?.invalidEntry ? 0 : parseFloat(envelopeBalance))}
      >
        <span aria-hidden={true}>x</span>
      </button>
    </Card>
  );
};

NewEnvelopeCard.propTypes = {
  envelope: envelopeType,
  index: PropTypes.number,
  updateNewEnvelopeName: PropTypes.func,
  updateEnvelopeValues: PropTypes.func,
  removeNewEnvelope: PropTypes.func,
};
export default NewEnvelopeCard;
