import {GET_USER_SUCCESS, SET_FIRST_TIME_USER, TERMS_ACCEPTED, UPDATE_MOBILE_APP_USER, UPDATE_SETTINGS_SUCCESS} from '../config/actionTypes';
import {API, getAuthHeader} from '../services/apiConfig';
import {handleGenericError} from '../services/errorConfig';
import {AGREEMENT_API, API_ERROR_MESSAGE, SETTINGS_API, TERMS_AGREEMENT_TYPE, USER_API} from '../config/constants';
import {setApiError} from './ui';

export const getUser = () => (dispatch) => {
  API.get(USER_API, {headers: getAuthHeader()})
    .then((res) => {
      dispatch(success(res.data));
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
      if (err.response.status !== 401) {
        dispatch(setApiError(true, API_ERROR_MESSAGE));
      }
    });

  const success = (data) => {
    return {
      type: GET_USER_SUCCESS,
      payload: data.user,
    };
  };
};

export const setFirstTimeUser = (firstTimeUser) => (dispatch) => {
  const setFirstTime = (firstTimeUser) => {
    return {
      type: SET_FIRST_TIME_USER,
      payload: firstTimeUser,
    };
  };

  dispatch(setFirstTime(firstTimeUser));
};

export const setTermsAccepted = () => (dispatch) => {
  const body = {
    hasAcceptedAgreement: true,
    agreementType: TERMS_AGREEMENT_TYPE,
  };

  API.post(USER_API + AGREEMENT_API, body, {headers: getAuthHeader()})
    .then(() => {
      dispatch(success());
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
    });

  const success = () => {
    return {
      type: TERMS_ACCEPTED,
      payload: true,
    };
  };
};

// Save user setting returned from successful call
export const createOrUpdateUserSetting = (settingType, settingValue, update) => (dispatch) => {
  const apiCall = update ? API.put : API.post;
  const body = {
    settingType,
    settingValue,
  };

  apiCall(USER_API + SETTINGS_API, body, {headers: getAuthHeader()})
    .then((res) => {
      dispatch(success(res.data.user.userSetting));
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
  });

  const success = (userSetting) => {
    return {
      type: UPDATE_SETTINGS_SUCCESS,
      payload: userSetting,
    };
  };
};

export const updateMobileAppUser = (isMobileUser) => {
  return {
    type: UPDATE_MOBILE_APP_USER,
    payload: isMobileUser,
  };
};
