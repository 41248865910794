import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Modal} from 'react-bootstrap';
import FocusLock from 'react-focus-lock';

import {TERMS_AND_CONDITIONS} from 'config/legal';
import {uiTermsModalSelector} from 'selectors';
import {updateTermsModal} from 'actions';

import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilePdf} from '@fortawesome/free-solid-svg-icons';

const TermsModal = () => {
  const dispatch = useDispatch();
  const termsModal = useSelector(uiTermsModalSelector);
  
  const closeModal = () => {
   dispatch(updateTermsModal(false));
  };

  return (
    <Modal id="TermsAndConditionsModal" show={termsModal.visible} scrollable size={'lg'} onHide={() => closeModal()}>
      <FocusLock group={'termsAndConditions'}>
        <Modal.Header closeButton>
          <Modal.Title style={universalStyles.modalHeader}>Envelopes Terms and Conditions</Modal.Title>
        </Modal.Header>
      </FocusLock>
      <Modal.Body>
        <FocusLock group={'termsAndConditions'}>
          <div style={styles.termsBody}>
            <a href={require('../../../assets/documents/BECU_Envelopes_Terms_and_Conditions.pdf')} target="_blank" rel="noreferrer">
              <FontAwesomeIcon id={'PdfIcon'} icon={faFilePdf} style={styles.pdfIcon} />
              Download Terms & Conditions (PDF)
            </a>
          </div>
          {TERMS_AND_CONDITIONS.map((terms, index) => (
            <div key={index} style={terms.type === 'heading' ? styles.termsHeader : styles.termsBody}>
              {terms.text}
            </div>
          ))}
        </FocusLock>
      </Modal.Body>
    </Modal>
  );
};

export default TermsModal;
