import {colors, fontSize} from 'styles/config';

export const styles = {
  toggleLabel: {
    fontSize: fontSize.small,
    fontWeight: 'bold',
    color: colors.obsidian,
    paddingTop: 2,
  },
};
