import React from 'react';
import moment from 'moment-timezone';

import { colors } from 'styles/config';
import { getNumWithCommas } from 'utils/envelopeDisplay';

export const HistoryItem = (item, transactionAmount, index, lastItem) => (
  <li role='listitem' tabIndex={0} key={index} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', listStyleType: 'none', padding: '11px 8px 11px 0', borderBottomWidth: lastItem ? 0 : 1, borderBottomStyle: 'solid', borderBottomColor: colors.storm}}>
    <div>
      <p style={{marginBottom: 0, fontWeight: 'bold'}}>{item.description}</p>
      <p style={{marginBottom: 0, color: colors.stone, fontWeight: 300}}>{moment.utc(item.transactionDate).tz('America/Los_Angeles').format('MMM D, YYYY')}</p>
    </div>
    <div style={{textAlign: 'right'}}>
      <p style={{marginBottom: 0, fontWeight: 'bold'}}>{transactionAmount}</p>
      <p style={{marginBottom: 0, color: colors.stone, fontWeight: 300}}>Balance {getNumWithCommas(item.totalEnvelopeBalance.toFixed(2))}</p>
    </div>
  </li>
);
