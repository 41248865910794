import React, {useEffect, useRef, useState} from 'react';
import {Form} from 'react-bootstrap';

import {styles} from './styles';

const AllocationInupt = ({envSchedule, index, updateParent, envelopeSchedule, overallPercent, envelopeNameCellRef}) => {
  const isDollarAllocation = envelopeSchedule.isDollarAllocation;
  const inputRef = useRef(null);
  const [invalidEntry, setInvalidEntry] = useState(false);
  const [dollarAmount, setDollarAmount] = useState(envSchedule.dollarDistributionAmount !== 0 ? Math.abs(envSchedule.dollarDistributionAmount * 100) : '');
  const [percentage, setPercentage] = useState(envSchedule.percentageDistributionAmount);

  useEffect(() => {
    setDollarAmount(envSchedule.dollarDistributionAmount.toFixed(2));
    setPercentage(envSchedule.percentageDistributionAmount);
  }, [envSchedule]);

  const validInput = (value) => {
    setInvalidEntry(false);
    setDollarAmount(value);
    updateParent(parseFloat(value ? value : 0), index);
  };

  return (
    <>
      {isDollarAllocation ? (
        <Form.Control
          key={index}
          id={`DollarDistributionInput-${envSchedule.name}`}
          name={'envelope-schedule-dollar-amount'}
          aria-label={`Dollar amount allocated to ${envSchedule.name}`}
          className={'setting-card-group'}
          ref={inputRef}
          type={'text'}
          inputMode={'decimal'}
          maxLength={18}
          value={dollarAmount}
          style={{...styles.inputContainer, height: envelopeNameCellRef?.clientHeight ? envelopeNameCellRef?.clientHeight + 24 : 'auto'}}
          onChange={(e) => {
            const regex = /[\d.]*/;
            const value = e.target.value.replace(/[a-zA-Z$\-,\s]/g, '').match(regex);
            if (value !== null) {
              setDollarAmount(value[0]);
            }
          }}
          onBlur={(e) => {
            const regex = /^\d*\.?\d{1,2}$/;
            const value = e.target.value.replace(/^0{2,}/g, 0).replace(/^0{2,}|\$|,|-/g, '');
            if (value.match(regex) !== null) {
              validInput(parseFloat(value).toFixed(2));
            } else {
              if (value !== '') {
                setInvalidEntry(true);
              } else {
                validInput('');
              }
            }
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13 || e.keyCode === 32) {
              e.preventDefault();
              inputRef.current.blur();
            }
          }}
          disabled={!envelopeSchedule.isActive}
          aria-disabled={!envelopeSchedule.isActive}
          isInvalid={invalidEntry}
        />
      ) : (
        <Form.Control
          key={index}
          inputMode={'numeric'}
          min={0}
          max={100}
          value={percentage}
          onBlur={(e) => {
            updateParent(Number(e.target.value ? e.target.value : 0), index);
            if (!e.target.value) {
              setPercentage(0);
            }
          }}
          onChange={(e) => setPercentage(e.target.value.replace(/\D+/g, ''))}
          style={{...styles.inputContainer, height: envelopeNameCellRef?.clientHeight ? envelopeNameCellRef?.clientHeight + 24 : 'auto'}}
          name={'envelope-schedule-percentage-amount'}
          aria-label={`Percent allocated to ${envSchedule.name}`}
          className={'setting-card-group'}
          disabled={!envelopeSchedule.isActive}
          aria-disabled={!envelopeSchedule.isActive}
          isInvalid={overallPercent > 100 && percentage > 0}
        />
      )}
    </>
  );
};

export default AllocationInupt;
