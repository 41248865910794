import {colors} from 'styles/config';

export const styles = {
  categoryTitle: {
    marginBottom: '1.5rem',
  },
  subcategoryTitle: {
    fontFamily: 'MuseoSans-700',
    fonSize: '1rem',
    color: colors.obsidian,
    marginBottom: '1rem',
  },
  breadcrumbList: {
    backgroundColor: colors.snow,
    marginBottom: 0,
  },
};
