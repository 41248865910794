import PropTypes from 'prop-types';

export const envelopeType = PropTypes.shape({
  envelopeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // string in NewEnvelopeCard
  name: PropTypes.string,
  dnaAccount: PropTypes.string,
  active: PropTypes.bool,
  balance: PropTypes.number,
  envelopeUpdateAmount: PropTypes.number,
  additionMultiplier: PropTypes.number,
  originalSort: PropTypes.number,
  accountId: PropTypes.number,
  customerId: PropTypes.number,
  type: PropTypes.number,
  startDate: PropTypes.string,
  sortNumber: PropTypes.number,
  updateType: PropTypes.number,
  touched: PropTypes.bool,
});

export const accountType = PropTypes.shape({
  accountNumber: PropTypes.string,
  accountNickName: PropTypes.string,
  accountType: PropTypes.string,
  availableBalance: PropTypes.number,
  currentBalance: PropTypes.number,
  majorAccountCode: PropTypes.string,
  minorAccountCode: PropTypes.string,
});

export const modalType = PropTypes.shape({
  title: PropTypes.string,
  body: PropTypes.string,
  yesText: PropTypes.string,
  noText: PropTypes.string,
});
