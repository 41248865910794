import {createUseStyles} from 'react-jss';
import {colors} from 'styles/config';

export const styles = createUseStyles({
  cardBody: {
    padding: '32px 24px 0',
  },
  bodyContent: {
    marginBottom: 46,
  },
  close: {
    position: 'absolute',
    right: 24,
    top: 16,
    padding: 0,
    border: 0,
    backgroundColor: 'transparent',
    fontWeight: 600,
    fontSize: '1.5rem',
    color: '#000000',
    textShadow: `0 1px 0 ${colors.snow}`,
  },
});
