// Case to fix, reload on this page, and the back arrow thinks there are changes. Strange.
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { tagAddTargetOpen, tagAllocationTab, tagDeleteEnvelope, tagEndTargetLink, tagSaveChangeOrder } from '../../services/gtm';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { v4 as uuidv4 } from 'uuid';
import Flexbox from 'flexbox-react';
import { Button, Breadcrumb, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import PageMetaData from 'components/app/PageMetaData';
import FeedbackTab from 'components/app/FeedbackTab';
import EnvelopeHeader from 'components/app/Header';
import Subheader from 'components/app/Subheader';
import AllocationSettings from 'components/automatic-allocation/AllocationSettings';
import EnvelopeCard from 'components/envelopes/EnvelopeCard';
import NewEnvelopeCard from 'components/envelopes/NewEnvelopeCard';
import EnvelopeWithdrawOrder from 'components/envelopes/EnvelopeWithdrawOrder';
import WithdrawRow from 'components/envelopes/WithdrawRow';
import InfoTip from 'components/app/InfoTip';
import { DeleteSuccessfulAlert, ManageEnvelopesErrorAlert, SaveSuccessfulAlert } from 'components/envelopes/EnvelopeAlerts';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import RenameEnvelopeModal from 'components/modals/RenameEnvelopeModal';
import ConfirmEnvelopeDeleteModal from 'components/modals/ConfirmEnvelopeDeleteModal';
import TargetDetails from 'components/targets/TargetDetails';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faEnvelope, faSave } from '@fortawesome/free-solid-svg-icons';

import { colors } from 'styles/config';
import { styles } from './styles';
import { universalStyles } from 'styles/universalStyles';
import {
  ALLOCATION_TAB_KEY,
  HOME_PAGE,
  LOGIN_PAGE,
  ENVELOPE_TAB_KEY,
  NOT_FOUND_PAGE,
  MANAGE_ENVELOPES_PAGE_TITLE,
  SAVE_MODAL_DATA,
} from '../../config/constants';
import {
  authSelector,
  userSelector,
  editingAccountSelector,
  updatedEnvelopesSelector,
  activeEnvelopesSelector,
  deletedEnvelopeSelector,
  uiSaveChangeModalVisibleSelector,
  accountsSelector,
  sessionTokenSelector,
  intializedSelector,
  saveEnvelopesFailedSelector,
  pageRefreshRequiredSelector,
  uiApiErrorSelector,
  renamedEnvelopeSelector,
} from '../../selectors';
import {
  updateAndCreateEnvelopes,
  resetDeleteSuccess,
  setFirstTimeUser,
  setEnvelopesUpdated,
  updateEnvelopes,
  setSaveChangeModalStatus,
  setEditingAccount,
  loginWithToken,
  setSessionPath,
  setSurveyMonkeyFormModal,
  setEnvelopesSavedFailed,
  setApiError,
  createEnvelopes,
  getEnvelopes,
  resetRenameSuccess,
  getEnvelopeHistory,
} from '../../actions';
import { getActiveAccountNumber, getActiveTabKey, setActiveTabKey } from '../../services/sessionStorage';
import { validateNewEnvelope } from './validation';

const ManageEnvelopes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedIn = useSelector(authSelector);
  const user = useSelector(userSelector);
  const sessionToken = useSelector(sessionTokenSelector);
  const initialized = useSelector(intializedSelector);
  const [allocatableAmount, setAllocatableAmount] = useState(0);
  const [envelopes, setEnvelopes] = useState([]);
  const [renameModal, setRenameModal] = useState({ visible: false });
  const [deleteModal, setDeleteModal] = useState({ visible: false });
  const [unallocatedAmount, setUnallocatedAmount] = useState(0);
  const currentAccounts = useSelector(accountsSelector);
  const editingAccount = useSelector(editingAccountSelector);
  const envelopesUpdated = useSelector(updatedEnvelopesSelector);
  const currentEnvelopes = useSelector(activeEnvelopesSelector);
  const envelopeDeleted = useSelector(deletedEnvelopeSelector);
  const envelopeRenamed = useSelector(renamedEnvelopeSelector);
  const unSaveChangesModalHidden = useSelector(uiSaveChangeModalVisibleSelector);
  const saveEnvelopesFailed = useSelector(saveEnvelopesFailedSelector);
  const pageRefreshRequired = useSelector(pageRefreshRequiredSelector);
  const apiError = useSelector(uiApiErrorSelector);
  const [withdrawToggle, setWithdrawalToggle] = useState(false);
  const [menuButtonEnabled, setMenuButtonEnabled] = useState(true);
  const [saveChangeModalVisible, setSaveChangeModalVisible] = useState(false);
  const [negativeAllocatableAlert, setNegativeAllocatableAlert] = useState(false);
  const [managePageTitle, setManagePageTitle] = useState('');
  const [envelopeDeletedBanner, setEnvelopeDeletedBanner] = useState(false);
  const [envelopesSavedBanner, setEnvelopesSavedBanner] = useState(false);
  const [onFocus, setOnFocus] = useState('');
  const [accessibilityLinkId, setAccessibilityLinkId] = useState('');
  const isBigScreen = useMediaQuery({ query: '(min-width: 576px)' });
  const [tabKey, setTabKey] = useState('envelopes');
  const [saveModalFunction, setSaveModalFunction] = useState();
  const [targetView, setTargetView] = useState({ visible: false, envelope: null });
  const desktop = useMediaQuery({ query: '(min-width: 768px)' });

  useEffect(() => {
    return () => {
      dispatch(setEditingAccount(null));
    };
  }, []);

  useEffect(() => {
    if (envelopesSavedBanner) {
      const savedTimeout = setTimeout(() => {
        setEnvelopesSavedBanner(false);
      }, 10000);

      return () => {
        clearTimeout(savedTimeout);
      };
    }

    if (envelopeDeletedBanner) {
      const deletedTimeout = setTimeout(() => {
        setEnvelopeDeletedBanner(false);
      }, 10000);

      return () => {
        clearTimeout(deletedTimeout);
      };
    }
  }, [envelopesSavedBanner, envelopeDeletedBanner]);

  useEffect(() => {
    if (!loggedIn && !sessionToken) {
      dispatch(setSessionPath({ pathname: LOGIN_PAGE }));
      history.replace(LOGIN_PAGE);
    } else if (!loggedIn && !initialized && sessionToken) {
      dispatch(loginWithToken());
    }
  }, [loggedIn, initialized]);

  useEffect(() => {
    if (onFocus !== '') {
      setAccessibilityFocusOnLink();
    }
  }, [onFocus]);

  useEffect(() => {
    const selectedAccountNumber = getActiveAccountNumber();
    const activeAccount = currentAccounts.filter((acc) => acc.accountNumber === selectedAccountNumber)[0];
    if (currentAccounts.length > 0 && !activeAccount) {
      dispatch(setSessionPath({ pathname: NOT_FOUND_PAGE }));
      history.replace(NOT_FOUND_PAGE);
    }
    dispatch(setEditingAccount(activeAccount));
  }, [currentAccounts]);

  const setAccountEnvelopes = () => {
    const editingAccountEnvelopes = currentEnvelopes.find((c) => c.accountNumber === editingAccount.accountNumber);
    const editingEnvelopes = editingAccountEnvelopes && editingAccountEnvelopes.envelope !== null ? [...editingAccountEnvelopes.envelope] : [];
    const editingAccountUnAllocatedAmount = editingAccountEnvelopes ? editingAccountEnvelopes.unAllocatedAmount : editingAccount.availableBalance;

    editingEnvelopes.forEach((envelope, index) => {
      envelope.envelopeUpdateAmount = 0;
      envelope.active = false;
      envelope.originalSort = index;
      envelope.negativeError = false;
      envelope.errors = {};
    });
    setEnvelopes(editingEnvelopes);
    setMenuButtonEnabled(trackSavedChangesEnabled(editingEnvelopes));
    setUnallocatedAmount(editingAccountUnAllocatedAmount);
    setAllocatableAmount(editingAccountUnAllocatedAmount);
    setManagePageTitle(MANAGE_ENVELOPES_PAGE_TITLE);
  };

  useEffect(() => {
    if (editingAccount) {
      setAccountEnvelopes();
      setEnvelopesSavedBanner(false);
      setTabKey(ENVELOPE_TAB_KEY);
    }
  }, [editingAccount, currentEnvelopes]);

  useEffect(() => {
    if (envelopesUpdated) {
      setAccountEnvelopes();

      if (!targetView.visible) {
        setEnvelopesSavedBanner(true);
        dispatch(setEnvelopesUpdated(false));
      }
    }
  }, [envelopesUpdated, targetView]);

  useEffect(() => {
    if (envelopeRenamed) {
      dispatch(resetRenameSuccess());
      setEnvelopesSavedBanner(true);
    }
  }, [envelopeRenamed]);

  useEffect(() => {
    if (envelopeDeleted !== null) {
      const updatedEnvelopeList = envelopes.filter((env) => env.envelopeId !== envelopeDeleted.deletedId);
      const [removedEnvelope] = envelopes.filter((env) => env.envelopeId === envelopeDeleted.deletedId);
      const updatedAllocatableValue = Math.round((envelopeDeleted.unAllocatedAmount - getTotalChange(updatedEnvelopeList)) * 100) / 100;

      tagDeleteEnvelope(removedEnvelope, editingAccount, updatedAllocatableValue);

      setEnvelopes(updatedEnvelopeList);
      setDeleteModal({ visible: false });
      setEnvelopeDeletedBanner(true);
      dispatch(resetDeleteSuccess());
      dispatch(getEnvelopes());

      if (user.isFirstTimeUser) {
        dispatch(setFirstTimeUser(false));
      }
    }
  }, [envelopeDeleted, envelopes, editingAccount, user]);

  useEffect(() => {
    if (allocatableAmount < 0) {
      setNegativeAllocatableAlert(true);
    } else {
      setNegativeAllocatableAlert(false);
    }
  }, [allocatableAmount]);

  const renamedWithSameName = () => {
    setRenameModal({ visible: false });
    setEnvelopesSavedBanner(true);
  };

  const saveEnvelopes = () => {
    if (!trackSavedChangesEnabled(envelopes)) {
      if (saveEnvelopesFailed) {
        dispatch(setEnvelopesSavedFailed(false));
      }

      const newEnvelopes = [...envelopes.filter((env) => env.new && env.name !== '')];
      const resortedEnvelopes = [...envelopes.filter((env) => env.originalSort !== env.sortNumber && !env.new)];
      let updatedEnvelopes = [...envelopes.filter((env) => (env.envelopeUpdateAmount !== 0 && !env.new) || (!env.new && env.originalSort !== env.sortNumber))];
      let updatedAllocatableValue = unallocatedAmount;

      if (resortedEnvelopes.length > 0) {
        tagSaveChangeOrder(editingAccount, user, allocatableAmount);
      }

      if (user.isFirstTimeUser) {
        dispatch(setFirstTimeUser(false));
      }

      if (newEnvelopes.length > 0) {
        updatedEnvelopes = [...envelopes.filter((env) => !env.new)];

        if (updatedEnvelopes.length > 0) {
          dispatch(updateAndCreateEnvelopes(newEnvelopes, updatedEnvelopes, editingAccount, updatedAllocatableValue));
        } else {
          dispatch(createEnvelopes(newEnvelopes, editingAccount, updatedAllocatableValue));
        }
      } else {
        updatedEnvelopes = [...envelopes.filter((env) => !env.new)];
        dispatch(updateEnvelopes(updatedEnvelopes, editingAccount, updatedAllocatableValue));
      }

      dispatch(setSaveChangeModalStatus(true));

      if (withdrawToggle) {
        setWithdrawalToggle(false);
      }
    }
  };

  const setAccessibilityFocusOnLink = () => {
    if (accessibilityLinkId && accessibilityLinkId !== '') {
      document.getElementById(accessibilityLinkId).focus();
    }
  };

  const getTotalChange = (array) => {
    let totalChange = 0;

    array.forEach((env) => {
      if (env.envelopeId !== envelopeDeleted && env.balance + env.envelopeUpdateAmount >= 0) {
        totalChange += env.envelopeUpdateAmount;
      }
    });

    return totalChange;
  };

  const addNewEnvelope = () => {
    const envelopeCopy = [...envelopes];
    envelopeCopy.unshift({ envelopeId: uuidv4(), name: '', balance: 0, envelopeUpdateAmount: 0, new: true, additionMultiplier: 0, errors: {} });
    setEnvelopes(envelopeCopy);
    setMenuButtonEnabled(trackSavedChangesEnabled(envelopeCopy));
    dispatch(setSaveChangeModalStatus(trackSavedChangesEnabled(envelopeCopy)));
  };

  const updateEnvelopeValues = (index, inputValue, multiplier, invalidEntry = false, activate = true) => {
    const envelopesCopy = [...envelopes];
    envelopesCopy[index].envelopeUpdateAmount = !invalidEntry ? inputValue * multiplier : 0;
    envelopesCopy[index].active = activate;
    if (!activate) {
      envelopesCopy[index].additionMultiplier = 0;
    }
    envelopesCopy[index].errors = {
      ...envelopesCopy[index].errors,
      invalidEntry,
    };

    if (envelopesCopy[index].balance + envelopesCopy[index].envelopeUpdateAmount < 0) {
      envelopesCopy[index].negativeError = true;
    } else {
      envelopesCopy[index].negativeError = false;
    }

    setEnvelopes(envelopesCopy);
    setAllocatableAmount(Math.round((unallocatedAmount - getTotalChange(envelopesCopy)) * 100) / 100);
    setMenuButtonEnabled(trackSavedChangesEnabled(envelopesCopy));
    dispatch(setSaveChangeModalStatus(trackSavedChangesEnabled(envelopesCopy)));

    if (envelopesSavedBanner && !trackSavedChangesEnabled(envelopesCopy)) {
      setEnvelopesSavedBanner(false);
    }
  };

  const updateNewEnvelopeName = (index, name) => {
    const envelopesCopy = [...envelopes];
    envelopesCopy[index].name = name;
    envelopesCopy[index].touched = true;
    envelopesCopy.forEach((env, index) => {
      if (env.new && env.touched) {
        validateNewEnvelope(index, envelopesCopy);
      }
    });

    setEnvelopes(envelopesCopy);
    setMenuButtonEnabled(trackSavedChangesEnabled(envelopesCopy));
    dispatch(setSaveChangeModalStatus(trackSavedChangesEnabled(envelopesCopy)));

    if (envelopesSavedBanner) {
      setEnvelopesSavedBanner(false);
    }
  };

  const removeNewEnvelope = (index, envelopeValue) => {
    const envelopesCopy = [...envelopes];
    envelopesCopy.splice(index, 1);
    envelopesCopy.forEach((env, index) => {
      if (env.new && env.touched) {
        validateNewEnvelope(index, envelopesCopy);
      }
    });
    setEnvelopes(envelopesCopy);
    if (envelopeValue) {
      setAllocatableAmount(Math.round((allocatableAmount + envelopeValue) * 100) / 100);
    }
    setMenuButtonEnabled(trackSavedChangesEnabled(envelopesCopy));
    dispatch(setSaveChangeModalStatus(trackSavedChangesEnabled(envelopesCopy)));
  };

  const setModalVisibleStatus = (status) => {
    setSaveChangeModalVisible(status);
  };

  const redirectToHomepage = () => {
    dispatch(setSaveChangeModalStatus(true));
    const path = { pathname: HOME_PAGE };
    dispatch(setSessionPath(path));
    history.push({ pathname: HOME_PAGE });
  };

  const trackSavedChangesEnabled = (envelope) => {
    return (
      allocatableAmount < 0 ||
      envelope.filter((env) => !env.new && env.negativeError).length !== 0 ||
      envelope.length === 0 ||
      envelope.filter((env) => env.new && env.errors?.name?.length > 0).length !== 0 ||
      envelope.filter((env) => env.name === '').length > 0 ||
      envelope.filter((env) => env.errors?.invalidEntry).length > 0 ||
      (envelope.filter((env) => env.new).length === 0 &&
        !envelope.filter((env) => (env.envelopeUpdateAmount !== 0 && !env.new) || env.sortNumber !== env.originalSort).length > 0)
    );
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const envelopeCopy = [...envelopes.filter((env) => !env.new)];
    const [removed] = envelopeCopy.splice(result.source.index, 1);

    envelopeCopy.forEach((envelope) => {
      if (envelope.sortNumber < result.source.index && envelope.sortNumber >= result.destination.index) {
        envelope.sortNumber += 1;
      } else if (envelope.sortNumber > result.source.index && envelope.sortNumber <= result.destination.index) {
        envelope.sortNumber -= 1;
      }
    });
    removed.sortNumber = result.destination.index;
    envelopeCopy.splice(result.destination.index, 0, removed);

    setEnvelopes(envelopes.filter((env) => env.new).concat(envelopeCopy));
    setMenuButtonEnabled(trackSavedChangesEnabled(envelopes.filter((env) => env.new).concat(envelopeCopy)));
    dispatch(setSaveChangeModalStatus(trackSavedChangesEnabled(envelopes.filter((env) => env.new).concat(envelopeCopy))));
  };

  const selectTabEvent = (selectedKey) => {
    if (selectedKey === ALLOCATION_TAB_KEY && tabKey !== selectedKey) {
      tagAllocationTab(editingAccount, user);
    }
  };

  const setModalFunction = () => {
    setSaveModalFunction(() => updateActiveTabAfterConfirmation);
  };

  const updateActiveTabAfterConfirmation = () => {
    var activeTab = getActiveTabKey();
    dispatch(getEnvelopes());
    setWithdrawalToggle(false);
    dispatch(setSaveChangeModalStatus(true));
    setModalVisibleStatus(false);
    selectTabEvent(activeTab);
    setTabKey(activeTab);
  };

  const selectTab = (key) => {
    if (process.env.REACT_APP_AA_FLAG === 'true') {
      setActiveTabKey(key);
      if (!unSaveChangesModalHidden && getActiveTabKey() !== tabKey) {
        setModalFunction();
        setModalVisibleStatus(true);
      } else {
        setAccountEnvelopes();
        selectTabEvent(key);
        setTabKey(key);
      }
    }
  };

  const viewTarget = (envelope, targetCompleted) => {
    setTargetView({ visible: true, envelope });
    dispatch(getEnvelopeHistory(envelope.envelopeId, editingAccount.accountNumber));

    if (!envelope.target) {
      tagAddTargetOpen(editingAccount, user);
    }

    if (targetCompleted) {
      tagEndTargetLink(editingAccount, user, envelope.balance, envelope.target);
    }
  };

  const EnvelopeListSection = () => (
    <>
      <div style={styles.headerWrapper}>
        <Flexbox justifyContent={'space-between'} alignItems={'center'}>
          <Flexbox flexDirection={'row'} flexWrap={'wrap'} alignItems={'center'}>
            <div id="AllocatableBalance" style={{ ...styles.allocatableAmount, color: allocatableAmount >= 0 ? colors.obsidian : '#ab111a' }}>
              {(allocatableAmount >= 0 ? '$' : '-$') +
                Math.abs(allocatableAmount)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </div>
            <div style={styles.allocatableLabel}>
              Allocatable
              <InfoTip
                ariaLabel={'View allocatable tooltip'}
                index={0}
                popoverId={'Allocatable'}
                content={'Funds available to distribute to Envelopes.'}
                imgName={'question'}
                allocatableAmount={allocatableAmount}
              />
            </div>
          </Flexbox>
          <Button
            id={'SaveChangesButton'}
            style={{ ...styles.saveChangesButton, width: isBigScreen ? 180 : 80 }}
            aria-disabled={trackSavedChangesEnabled(envelopes)}
            disabled={trackSavedChangesEnabled(envelopes)}
            onClick={saveEnvelopes}
            className={trackSavedChangesEnabled(envelopes) ? 'btn-hide-pointer' : null}
          >
            <FontAwesomeIcon icon={faSave} style={universalStyles.iconMarginRight} />
            {isBigScreen ? 'Save Changes' : 'Save'}
          </Button>
        </Flexbox>
        <ManageEnvelopesErrorAlert
          dispatch={dispatch}
          setSurveyMonkeyFormModal={setSurveyMonkeyFormModal}
          saveEnvelopesFailed={saveEnvelopesFailed}
          pageRefreshError={pageRefreshRequired}
          apiError={apiError}
          setApiError={setApiError}
          negativeAllocatableAlert={negativeAllocatableAlert}
          setNegativeAllocatableAlert={setNegativeAllocatableAlert}
        />
        <DeleteSuccessfulAlert
          deleteBanner={envelopeDeletedBanner}
          setDeleteBanner={setEnvelopeDeletedBanner}
        />
        <SaveSuccessfulAlert
          envelopesSavedBanner={envelopesSavedBanner}
          setEnvelopesSavedBanner={setEnvelopesSavedBanner}
          trackSavedChangesEnabled={trackSavedChangesEnabled}
          envelopes={envelopes}
        />
      </div>
      <WithdrawRow
        envelopes={envelopes}
        allocatableAmount={allocatableAmount}
        withdrawToggle={withdrawToggle}
        setWithdrawalToggle={setWithdrawalToggle}
        editingAccount={editingAccount}
        user={user}
        addNewEnvelope={addNewEnvelope}
      />
      {withdrawToggle ? (
        <EnvelopeWithdrawOrder envelopes={envelopes} onDragEnd={onDragEnd} />
      ) : (
        envelopes.map((envelope, index) => {
          if (envelope.new) {
            return (
              <NewEnvelopeCard
                key={envelope.envelopeId}
                envelope={envelope}
                index={index}
                updateNewEnvelopeName={updateNewEnvelopeName}
                updateEnvelopeValues={updateEnvelopeValues}
                removeNewEnvelope={removeNewEnvelope}
              />
            );
          }
          return (
            <EnvelopeCard
              key={envelope.envelopeId}
              envelope={envelope}
              index={index}
              updateEnvelopeValues={updateEnvelopeValues}
              setRenameModal={setRenameModal}
              setDeleteModal={setDeleteModal}
              setAccessibilityLinkId={setAccessibilityLinkId}
              setOnFocus={setOnFocus}
              currentIndex={envelope.sortNumber + 1}
              menuButtonEnabled={menuButtonEnabled}
              viewTarget={viewTarget}
            />
          );
        })
      )}
    </>
  );

  const EnvelopeTab = (title, faIcon) => (
    <Flexbox alignItems={'center'}>
      <FontAwesomeIcon icon={faIcon} style={universalStyles.iconMarginRight} />
      <div>{title}</div>
    </Flexbox>
  );

  const EnvelopeBreadCrumb = () => (
    <nav>
    <div>    
    <Breadcrumb listProps={{ style: styles.breadcrumbList }}>
      <>
        <Breadcrumb.Item onClick={() => redirectToHomepage()}> Home</Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => setTargetView({ visible: false, envelope: null })} active={!targetView.envelope}>Account</Breadcrumb.Item>
      </>
      {targetView.envelope && (
        <Breadcrumb.Item active className="breadcrumb-overflow" style={{ minWidth: 0 }}><span>{targetView.envelope.name}</span></Breadcrumb.Item>
      )}
    </Breadcrumb>
    </div>
    </nav>    
  );

  const EnvelopeManagement = () => {
    const accountInfo = editingAccount
      ? editingAccount.accountNumber + ' * ' + (editingAccount.accountNickName ? editingAccount.accountNickName : editingAccount.accountType)
      : '';
    const availableBalance = editingAccount ? `Available Balance $${editingAccount.availableBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : '';
    return (
      <Container role={'main'} id="main-content">
        <Row>
          <Col id="AccountInfo" md={4} style={styles.accountInfoContainer}>
            <h2 style={styles.accountTitle} id="AccountNumberAndName">
              {accountInfo}
            </h2>
            <div id="AccountAvailableBalance">{availableBalance}</div>
          </Col>
          <Col md={8} className={'manage-wrapper'} style={styles.contentContainer}>
            {desktop && EnvelopeBreadCrumb()}
            <Tabs activeKey={tabKey} id="ManageEnvelopesTab" className="manage-envelopes-tab-nav" style={targetView.visible ? styles.tabNavDisplayOnTarget : styles.tabNavContainer} onSelect={selectTab}>
              <Tab eventKey={`${ENVELOPE_TAB_KEY}`} title={EnvelopeTab('Envelopes', faEnvelope)} className="manage-envelopes-tab">
                {targetView.visible ? <TargetDetails envelope={targetView.envelope} user={user} selectTab={selectTab} setTargetView={setTargetView} /> : EnvelopeListSection()}
              </Tab>
              {process.env.REACT_APP_AA_FLAG === 'true' && (
                <Tab
                  eventKey={`${ALLOCATION_TAB_KEY}`}
                  title={EnvelopeTab(isBigScreen ? 'Allocation Settings' : 'Settings', faCog)}
                  className="manage-envelopes-tab"
                >
                  <AllocationSettings editingAccount={editingAccount} user={user} alertFunction={selectTab} tabKey={tabKey} allocatableAmount={allocatableAmount} />
                </Tab>
              )}
            </Tabs>
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <Flexbox flexDirection={'column'} style={universalStyles.container}>
      <PageMetaData pageTitle={managePageTitle} />
      <EnvelopeHeader />
      <Subheader
        title={'Account Envelopes'}
        arrowAction={redirectToHomepage}
        unSaveChangesModalHidden={unSaveChangesModalHidden}
        setModalVisibleStatus={setModalVisibleStatus}
        ariaLabel={'Back to homepage'}
        hideArrowButton={true}
        setSaveModalFunction={setSaveModalFunction}
      />
      {!desktop && EnvelopeBreadCrumb()}
      {EnvelopeManagement()}
      <RenameEnvelopeModal
        renameModal={renameModal}
        setRenameModal={setRenameModal}
        renamedWithSameName={renamedWithSameName}
        envelopes={envelopes}
        editingAccount={editingAccount}
      />
      <ConfirmEnvelopeDeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} editingAccount={editingAccount} />
      <ConfirmationModal
        modalData={SAVE_MODAL_DATA}
        modalFunction={saveModalFunction}
        showModal={saveChangeModalVisible}
        setModalVisibleStatus={setModalVisibleStatus}
      />
      <FeedbackTab />
    </Flexbox>
  );
};

export default ManageEnvelopes;
