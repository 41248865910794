import {UPDATE_SESSION_PATH, UPDATE_SESSION_TOKEN} from '../config/actionTypes';

const INITIAL_STATE = {
  pathname: null,
  token: null,
};

export const session = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_SESSION_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case UPDATE_SESSION_PATH:
      return {
        ...state,
        pathname: action.payload,
      };
    default:
      return state;
  }
};
