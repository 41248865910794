import React from 'react';
import {Alert} from 'react-bootstrap';
import InfoTip from 'components/app/InfoTip';

import {colors} from 'styles/config';
import {styles} from './styles';
import {setEnvelopesSavedFailed, setPageRefreshError} from 'actions';
import danger from '../../../assets/images/icons/danger.svg';
import success from '../../../assets/images/icons/success.svg';

export const ManageEnvelopesErrorAlert = ({
  dispatch,
  setSurveyMonkeyFormModal,
  saveEnvelopesFailed,
  apiError,
  setApiError,
  negativeAllocatableAlert,
  setNegativeAllocatableAlert,
  pageRefreshError,
}) => {
  let body = '';
  
  let closeAction = null;
  if (saveEnvelopesFailed) {
    body = (
      <>
        We are unable to save your changes. Please refresh the page and try again.
      </>
    );
    closeAction = () => dispatch(setEnvelopesSavedFailed(false));
  } else if (apiError.visible) {
    body = (
      <>
        {apiError.message}
      </>
    );
    closeAction = () => dispatch(setApiError(false, ''));
  } else if (negativeAllocatableAlert) {
    body = (
      <>
        Allocatable balance cannot be negative. Please reduce the amount of money in your envelope(s).
        <InfoTip
          index={2}
          popoverId={'NegativeAllocatableBalance'}
          content={'Money allocated to envelopes cannot exceed the available balance in your account.'}
          imgName={'question-danger'}
        />
      </>
    );
    closeAction = () => setNegativeAllocatableAlert(false);
  } else if (pageRefreshError) {
    body = (
      <>
       We are unable to save your changes. Please refresh the page and try again.
      </>
    );
    closeAction = () => dispatch(setPageRefreshError(false));
  }

  return (
    <Alert
      id="globalErrorBox"
      show={negativeAllocatableAlert || saveEnvelopesFailed || apiError.visible || (pageRefreshError !== undefined && pageRefreshError === true)}
      dismissible
      onClose={closeAction}
      style={{...styles.alertContainer, backgroundColor: '#f5d9db'}}
    > 
      {body && <img src={danger} style={{marginRight: 16}} aria-hidden='true' />}
      <div style={{color: colors.obsidian}}>{body}</div>
    </Alert>
  );
};

export const DeleteSuccessfulAlert = ({deleteBanner, setDeleteBanner}) => {
  return (
    <Alert
      id="globalDeleteSuccessBox"
      variant={'success'}
      show={deleteBanner}
      dismissible
      onClose={() => setDeleteBanner(false)}
      style={{...styles.alertContainer, backgroundColor: '#d9f7ea'}}
    >
      <img src={success} style={{marginRight: 16}} aria-hidden='true' />
      <div style={{color: colors.obsidian}}>Envelope deleted successfully</div>
    </Alert>
  );
};

export const SaveSuccessfulAlert = ({envelopesSavedBanner, setEnvelopesSavedBanner, trackSavedChangesEnabled, envelopes}) => {
  return (
    <Alert
      id="globalSaveSuccessBox"
      variant={'success'}
      show={envelopesSavedBanner && trackSavedChangesEnabled(envelopes)}
      dismissible
      onClose={() => setEnvelopesSavedBanner(false)}
      style={{...styles.alertContainer, backgroundColor: '#d9f7ea'}}
    >
      <img src={success} style={{marginRight: 16}} aria-hidden='true' />
      <div style={{color: colors.obsidian}}>Changes saved successfully</div>
    </Alert>
  );
};

export const SaveSuccessfulAllocationAlert = ({allocationSavedBanner, setAllocationSavedBanner}) => {
  return (
    <Alert
      id="globalSaveSuccessBox"
      variant={'success'}
      show={allocationSavedBanner}
      dismissible
      onClose={() => setAllocationSavedBanner(false)}
      style={{...styles.alertContainer, backgroundColor: '#d9f7ea'}}
    >
      <img src={success} style={{marginRight: 16}} aria-hidden='true' />
      <div style={{color: colors.obsidian}}>Changes saved successfully</div>
    </Alert>
  );
};
