import React from 'react';
import {useMediaQuery} from 'react-responsive';
import Flexbox from 'flexbox-react';

import {
  CheckCircle,
  CircleFill,
} from 'react-bootstrap-icons';

import {colors} from 'styles/config';
import {styles} from './styles';

const steps = ['Introduction', 'Details', 'Confirmation'];

const TargetStepTracker = ({step = 0, setStep}) => {
  const largeScreen = useMediaQuery({query: '(min-width: 768px)'});
  
  return (
    <>
      <div style={{...styles.trackerLine, left: largeScreen ? '17%' : '15%', width: largeScreen ? '66%' : '70%'}}>
        <div style={{...styles.filledTrackerLine, width: `${step * 50}%`}} />
      </div>
      {largeScreen ? (
        <ol aria-label="Add target progress bar" style={styles.trackerContainer}>
          {steps.map((stepName, index) => {
            return (
              <li key={index} style={styles.trackerItem}>
                <Flexbox justifyContent={'center'} alignItems={'center'} style={step === index ? styles.currentStep : styles.step}>
                  {step === index ? <CircleFill color={colors.harbor} size={20} /> : (
                    step > index ? <CheckCircle color={colors.harbor} size={24} /> : <CircleFill color={colors.storm} size={24} />
                  )}
                  {step <= index && <span style={{position: 'absolute', color: step === index ? colors.snow : colors.stone, fontSize: '87.5%'}}> {index + 1} </span>}
                </Flexbox>
                <button
                  id={`TargetStep-${index}`}
                  className={step <= index ? 'inactive-step btn-hide-pointer link-button' : 'link-button'}
                  onClick={() => setStep(index)}
                  disabled={step <= index}
                  aria-disabled={step <= index}
                  aria-label={`${stepName}, step ${index + 1} of ${steps.length}`}
                >
                  {stepName}
                </button>
              </li>
            );
          })}
        </ol>
      ) : (
        <div style={{...styles.trackerContainer, justifyContent: 'flex-start'}}>
          <div style={{transition: 'width 0.5s linear', width: `${step * 36}%`}} />
          <div style={{margin: '0 1rem'}}>
            <div style={styles.trackerItem}>
              <Flexbox justifyContent={'center'} alignItems={'center'} style={styles.currentStep}>
                <CircleFill color={colors.harbor} size={20} />
                <span style={{position: 'absolute', color: colors.snow, fontSize: '87.5%'}}> {step + 1} </span>
              </Flexbox>
              Step {step + 1} of {steps.length}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TargetStepTracker;
