import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import Flexbox from 'flexbox-react';
import {Col, Container, Row} from 'react-bootstrap';

import {PRIVACY_URL} from '../../../config/constants';
import {updateTermsModal} from 'actions';
import {styles} from './styles';

const EnvelopeFooter = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isSsoPage, setIsSsoPage] = useState(false);

  const termsDisplay = () => {
    dispatch(updateTermsModal(true, false));
  };

  useEffect(() => {
    if (location.pathname.includes('sso')) {
     setIsSsoPage(true);
    }else{
      setIsSsoPage(false);
    }
  }, [location]);

  return (
    <>
      <div style={{flex: 1}} />
      <Flexbox justifyContent={'center'} alignItems={'center'} style={styles.footer}>
        <Container role={'contentinfo'}>
          <Row>
            <Col md={6} className={'footer-copyright'}>
              <div id={'All-Rights-Reserved'}>&#169; {new Date().getFullYear()} BECU. All Rights Reserved.</div>
              <div id={'Federally-Insured'}>Federally Insured by NCUA.</div>
            </Col>
            {!isSsoPage &&
              <Col md={6} style={styles.footerTerms}>
                <div id={'Terms-Conditions'} className={'footer-terms-conditions'}>
                  <button type={'button'} className={'link-button'} id={'Terms-Conditions-Link'} onClick={termsDisplay}>
                    Terms and Conditions
                  </button>{' '}
                  |{' '}
                  <a id={'Privacy-Link'} href={PRIVACY_URL} target="_blank" rel="noreferrer">
                    Privacy
                  </a>
                </div>
              </Col>
            }
          </Row>
        </Container>
      </Flexbox>
    </>
  );
};

export default EnvelopeFooter;
