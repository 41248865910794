import {colors} from 'styles/config';

export const styles = {
  inputLabel: {
    fontSize: '0.786em',
    fontWeight: 600,
    color: colors.obsidian,
  },
  inputContainer: {
    border: `1px solid ${colors.stone}`,
    borderRadius: 2,
    height: 38,
  },
  viewPasswordContainer: {
    height: 38,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.375rem 0.75rem',
    borderRadius: 2,
    border: `1px solid ${colors.stone}`,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  linkList: {
    backgroundColor: colors.snow,
    marginTop: '1.5rem',
    marginBottom: 0,
    paddingLeft: 0,
  },
};
