import {colors} from 'styles/config';

export const styles = {
  notFoundImage: {
    minWidth: 40,
    verticalAlign: 'middle',
    borderStyle: 'none',
  },
  smallerImage: {
    maxWidth: 200,
  },
  largerImage: {
    maxWidth: 250,
  },
  bodyText: {
    fontWeight: 500,
    fontSize: '1.618rem',
    color: colors.obsidian,
    margin: '24px 0',
  },
  notFoundButton: {
    width: 'auto',
    margin: 4,
  },
};
