import {LOGIN_FAILURE} from 'config/actionTypes';
import {logout} from 'actions';

export const handleGenericError = (err, dispatch = null, authError = false) => {
  // [TODO]: either strip out message and find a way to injest this
  let code = 0;

  if (!err.response) {
    code = 0;
  } else {
    code = err.response.status;
  }

  if (authError) {
    dispatch(setAuthError(code));
    return;
  }

  if (code === 401) {
    dispatch(setAuthError(999));
    dispatch(logout(code));
    return;
  }

  console.error(err, err.stack);
  // console.error for now --> switch to modal for generic errors later?
  return;
};

export const setAuthError = (code) => {
  return {
    type: LOGIN_FAILURE,
    payload: code,
  };
};
