import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';
import {Button, CardDeck, Card, Col, Container, Row} from 'react-bootstrap';

import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';
import {FAQ_PAGE, TOPIC_CARD_DATA} from 'config/constants';
import {setSessionPath} from 'actions';
import file from '../../../assets/images/icons/file.svg';

const Tutorials = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLargeScreen = useMediaQuery({query: '(min-width: 768px)'});

  const navigateToFAQ = () => {
    const path = {pathname: FAQ_PAGE};
    dispatch(setSessionPath(path));
    history.push(path);
  };

  const navigateToArticle = (link) => {
    const path = {pathname: link};
    dispatch(setSessionPath(path));
    history.push(path);
  };

  return (
    <div>
      <Container role={'complimentary'}>
        <Row>
          <Col md={12} style={universalStyles.smallColumnPadding}>
            <h2>Tutorials</h2>
            <div style={styles.body}>Learn more about BECU Envelopes</div>
            <Button id={'FAQButton'} variant={'secondary'} style={universalStyles.noSetWidth} onClick={() => navigateToFAQ()} aria-label="Frequently asked questions and tips">
              FAQs & Tips
            </Button>
          </Col>
          <Col md={12} style={universalStyles.smallColumnPadding}>
            <CardDeck>
              {TOPIC_CARD_DATA.map((topic, index) => (
                <Card key={index} className="tutorial" style={{minWidth: isLargeScreen ? 200 : '100%', marginTop: (!isLargeScreen && index > 0) ? 20 : 0}}>
                  <Card.Body className={'tutorial-card-body'}>
                    <p className="card-title">
                      <Button
                        id={topic.title.replace(/ /g, '-')}
                        variant={'link'}
                        className={'link-button'}
                        aria-label={`${topic.title.replace(/ /g, ' ')}, Article, 5 minutes`}
                        style={{textAlign: 'left', height: 'auto', width: 'auto'}}
                        onClick={() => navigateToArticle(topic.link)}
                      >
                        {topic.title}
                      </Button>
                    </p>
                    <p id={topic.category.replace(/ /g, '-')} className="card-category subtitle">
                      {topic.category}
                    </p>
                  </Card.Body>
                  <Card.Footer className={'tutorial-card-footer'}>
                    <p className="card-type" aria-hidden="true">
                      <img src={file} />
                      Article (5 min)
                    </p>
                  </Card.Footer>
                </Card>
              ))}
            </CardDeck>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Tutorials;
