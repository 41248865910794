import {colors} from 'styles/config';

export const styles = {
  summaryBox: {
    backgroundColor: colors.cloud,
    borderRadius: 4,
    borderTop: 'none',
    borderLeft: 'none',
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
    padding: 16,
  },
  boldLabel: {
    color: colors.obsidian,
  },
  savingsTilesContainer: {
    width: '100%',
    textAlign: 'center',
  },
  savingsTile: {
    backgroundColor: colors.cloud,
    borderRadius: 4,
    padding: 16,
    flexGrow: 1,
    marginTop: 4,
  },
  savingsTileAmount: {
    marginTop: '0.5rem',
    marginBottom: 0,
  },
  savingsTileDuration: {
    fontSize: '80%',
    fontWeight: 400,
    marginBottom: 0,
  },
};
