import React from 'react';
import {useHistory} from 'react-router-dom';
import FocusLock from 'react-focus-lock';
import {Button, Modal} from 'react-bootstrap';

import {universalStyles} from 'styles/universalStyles';
import { SSO_SESSION_LOGOUT_PAGE } from 'config/constants';

const SSOLogoutModal = ({ modalVisible, setModalVisible }) => {
  const history = useHistory();

  const handleLogout = () => {
    history.replace(SSO_SESSION_LOGOUT_PAGE);
  };

  return (
    <Modal id={'SSOLogoutModal'} show={modalVisible} onHide={() => setModalVisible(false)}>
      <FocusLock>
        <Modal.Header closeButton>
          <Modal.Title style={universalStyles.modalHeader}>Log Out</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Logging out of Envelopes. Do you wish to continue?</p>
        </Modal.Body>
        <Modal.Footer style={{justifyContent: 'center'}}>
          <Button
            id="SSOCancelLogout"
            className={'envelope-cancel-button'}
            variant="secondary"
            style={universalStyles.fullWidth}
            onClick={() => setModalVisible(false)}
          >
            No, Continue to use Envelopes
          </Button>
          <Button
            id="SSOLogout"
            className={'envelope-confirmation-button'}
            variant="primary"
            style={universalStyles.fullWidth}
            onClick={handleLogout}
          >
            Yes, Log Out and Close Envelopes
          </Button>
        </Modal.Footer>
      </FocusLock>
    </Modal>
  );
};

export default SSOLogoutModal;
