import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import FocusLock from 'react-focus-lock';
import TargetIntroduction from '../../targets/TargetIntroduction';
import TargetSetup from '../../targets/TargetSetup';
import TargetStepTracker from '../../targets/TargetStepTracker';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons';

import {universalStyles} from 'styles/universalStyles';
import {createOrUpdateUserSetting, createTarget, resetTargetCreated, resetTargetUpdated, setFirstTimeUser, updateTarget} from 'actions';
import {getNumWithCommas} from 'utils/envelopeDisplay';
import {editingAccountSelector, targetCreatedSelector, targetUpdatedSelector} from 'selectors';
import TargetSavingsTiles from 'components/targets/TargetSavingsTiles';
import { USER_SETTINGS } from 'config/constants';
import { TargetEndDateMatchesToday, TargetLessThanStartAmountAlert, TargetMatchesBalanceAlert } from 'components/targets/TargetAlerts';
import { tagAddTargetEnd, tagAddTargetNext } from 'services/gtm';

const TargetSetupModal = ({targetModalVisible, setTargetModalVisible, envelope, setSuccessBanner, user, updatingTarget, toggleDeleteModal}) => {
  const dispatch = useDispatch();
  const editingAccount = useSelector(editingAccountSelector);
  const targetCreated = useSelector(targetCreatedSelector);
  const targetUpdated = useSelector(targetUpdatedSelector);
  const [step, setStep] = useState((user.userSetting && user.userSetting.hasEnvelopeTargetIntroduction) || updatingTarget ? 1 : 0);
  const [totalSaveAmount, setTotalSaveAmount] = useState(envelope.target ? envelope.target.targetAmount.toFixed(2) : (envelope.balance + 1).toFixed(2));
  const [saveByDate, setSaveByDate] = useState(envelope.target ? new Date(moment(envelope.target.targetEndDate)) : new Date(moment().add(1, 'd')));
  const [saveByDateDisplay, setSaveByDateDisplay] = useState(moment(saveByDate).format('MM/DD/YYYY'));
  const [disableContinue, setDisableContinue] = useState(false);
  const [showIntro, setShowIntro] = useState(user?.userSetting ? user.userSetting.hasEnvelopeTargetIntroduction : false);
  const [targetMatchesAmount, setTargetMatchesAmount] = useState(false);
  const [targetLessThanStartAmount, setTargetLessThanStartAmount] = useState(false);
  const [targetEndDateMatchesToday, setTargetEndDateMatchesToday] = useState(false);

  useEffect(() => {
    if (targetCreated || targetUpdated) {
      envelope.target = targetCreated ? getNewTarget(false) : getUpdatedTarget();
      setTargetModalVisible(false);
      dispatch(targetCreated ? resetTargetCreated() : resetTargetUpdated());
      resetTargetModal(true);
      setSuccessBanner({visible: true, message: targetCreated ? 'Target saved successfully' : 'Your target was updated successfully!'});
    }
  }, [targetCreated, targetUpdated]);

  const getNewTarget = (creating) => {
    return {
      startAmount: parseFloat(envelope.balance),
      targetStartDate: moment().format('YYYY-MM-DD'),
      envelopeId: envelope.envelopeId,
      targetAmount: parseFloat(totalSaveAmount),
      targetEndDate: creating ? moment(saveByDate).format('YYYY-MM-DD') : new Date(moment(saveByDate)),
    };
  };

  const getUpdatedTarget = () => {
    return {
      ...envelope.target,
      targetAmount: parseFloat(totalSaveAmount),
      targetEndDate: new Date(moment(saveByDate)),
    };
  };

  const closeAndDelete = () => {
    setTargetModalVisible(false);
    resetTargetModal();
    toggleDeleteModal(true);
  };

  const resetTargetModal = (createdOrUpdated = false) => {
    setTargetModalVisible(false);
    setTotalSaveAmount(envelope.target ? envelope.target.targetAmount.toFixed(2) : (envelope.balance + 1).toFixed(2));
    setSaveByDate(envelope.target ? new Date(moment(envelope.target.targetEndDate)) : new Date(moment().add(1, 'd')));
    setSaveByDateDisplay(envelope.target ? moment(envelope.target.targetEndDate).format('MM/DD/YYYY') : moment().add(1, 'd').format('MM/DD/YYYY'));
    setShowIntro(createdOrUpdated ? showIntro : user?.userSetting?.hasEnvelopeTargetIntroduction);
    setDisableContinue(false);
    setStep((user.userSetting && user.userSetting.hasEnvelopeTargetIntroduction) || updatingTarget || createdOrUpdated ? 1 : ((showIntro && !createdOrUpdated) ? 0 : 1 ));
    setTargetMatchesAmount(false);
    setTargetLessThanStartAmount(false);
    setTargetEndDateMatchesToday(false);
  };

  const submitTarget = () => {
    if (!updatingTarget) {
      dispatch(createTarget(getNewTarget(true), editingAccount.accountNumber));
    } else {
      const updatedTarget = {
        envelopeId: envelope.envelopeId,
        targetAmount: parseFloat(totalSaveAmount),
        targetEndDate: moment(saveByDate).format('YYYY-MM-DD'),
      };
      dispatch(updateTarget(updatedTarget, editingAccount.accountNumber));
    }

    if (user.isFirstTimeUser) {
      dispatch(setFirstTimeUser(false));
    }

    if (!user.userSetting || (user.userSetting && user.userSetting.hasEnvelopeTargetIntroduction !== showIntro)) {
      dispatch(createOrUpdateUserSetting(USER_SETTINGS.TARGET_INTRODUCTION, showIntro, !!user.userSetting));
    }
  };

  const Submit = () => {
    return (
      <Row>
        <Col style={{paddingTop: 40, paddingBottom: 16}}>
          <h2>Target Confirmation</h2>
          <h3>Target Amount:</h3>
          <p id="TargetAmountConfirmation">{getNumWithCommas(parseFloat(totalSaveAmount).toFixed(2))}</p>
          <h3>Target End Date:</h3>
          <p id="TargetEndDateConfirmation">{moment(saveByDate).format('MM/DD/YYYY')}</p>
        </Col>
        <Col md={12} style={{paddingTop: 40, paddingBottom: 40}}>
          <TargetMatchesBalanceAlert showAlert={targetMatchesAmount} setAlert={setTargetMatchesAmount} />
          <TargetLessThanStartAmountAlert showAlert={targetLessThanStartAmount} setAlert={setTargetLessThanStartAmount} closeAndDelete={closeAndDelete} />
          <TargetEndDateMatchesToday showAlert={targetEndDateMatchesToday} setAlert={setTargetEndDateMatchesToday} />
          {!(updatingTarget && (totalSaveAmount <= envelope.balance || totalSaveAmount <= envelope.target.startAmount || moment().isSame(moment(saveByDate), 'day'))) &&
            <TargetSavingsTiles saveAmount={totalSaveAmount} saveByDate={saveByDate} envelopeBalance={envelope.balance} summaryStyle={'label'} />
          }
        </Col>
      </Row>
    );
  };
  
  const NextButtonLabel = (step, updatingTarget) => {
   return ( step !== 2  ? 'Next step' + (step + 2) : (updatingTarget ? "Update Target" : "Start Target") );
  };

  return (
    <Modal id="TargetSetupModal" show={targetModalVisible} onHide={() => resetTargetModal()} size="lg">
      <FocusLock>
        <Modal.Header closeButton>
          <Modal.Title style={universalStyles.modalHeader}>{updatingTarget ? 'Edit' : 'Add'} Target</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TargetStepTracker step={step} setStep={setStep} />
          {step === 0 && <TargetIntroduction showIntro={showIntro} setShowIntro={setShowIntro} />}
          {step === 1 && (
            <TargetSetup
              totalSaveAmount={totalSaveAmount}
              setTotalSaveAmount={setTotalSaveAmount}
              saveByDate={saveByDate}
              setSaveByDate={setSaveByDate}
              saveByDateDisplay={saveByDateDisplay}
              setSaveByDateDisplay={setSaveByDateDisplay}
              envelopeBalance={envelope.balance}
              setDisableContinue={setDisableContinue}
              updatingTarget={updatingTarget}
              startAmount={updatingTarget ? envelope.target.startAmount : envelope.balance}
              closeAndDelete={closeAndDelete}
              targetMatchesAmount={targetMatchesAmount}
              setTargetMatchesAmount={setTargetMatchesAmount}
              targetLessThanStartAmount={targetLessThanStartAmount}
              setTargetLessThanStartAmount={setTargetLessThanStartAmount}
              targetEndDateMatchesToday={targetEndDateMatchesToday}
              setTargetEndDateMatchesToday={setTargetEndDateMatchesToday}
            />
          )}
          {step === 2 && Submit()}
        </Modal.Body>
        <Modal.Footer style={{justifyContent: 'flex-end'}}>
          <button
            id="CancelTargetSetup"
            className={'link-button'}
            style={{marginRight: 32}}
            onClick={() => resetTargetModal()}
          >
            Cancel
          </button>
          <Button
            id={'SetupBackButton'}
            variant={'secondary'}
            style={universalStyles.noSetWidth}
            className={step === 0 && 'btn-hide-pointer'}
            disabled={step === 0}
            aria-disabled={step === 0}
            aria-label={step>0 && `back to step ${step}`}
            aria-live="polite"
            onClick={() => setStep(step - 1)}
          >
            <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: 8}} />
            Back
          </Button>
          <Button
            id={'SetupNextButton'}
            style={universalStyles.noSetWidth}
            onClick={() => {
              if (step !== 2) {
                tagAddTargetNext(editingAccount, user, envelope.balance, step);
                setStep(step + 1);
              } else {
                tagAddTargetEnd(editingAccount, user, envelope.balance, getNewTarget(true));
                submitTarget();
              }
            }}
            disabled={disableContinue && step === 1}
            aria-disabled={disableContinue && step === 1}
            aria-label={`${NextButtonLabel(step, updatingTarget)}`}
            className={disableContinue && step === 1 ? 'btn-hide-pointer' : null}
          >
            {step !== 2 ? (
              <>
                Next
                <FontAwesomeIcon icon={faArrowRight} style={{marginLeft: 8}} />
              </>
            ) : (
              <>{updatingTarget ? 'Update' : 'Start'} Target</>
            )}
          </Button>
        </Modal.Footer>
      </FocusLock>
    </Modal>
  );
};

export default TargetSetupModal;
