import {colors} from 'styles/config';

export const styles = {
  body: {
    marginBottom: '1rem',
  },
  addEnvelopeButton: {
    width: 'max-content',
  },
  bannerCol: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingTop: 40,
    paddingBottom: 40,
  },
  bannerTitle: {
    color: colors.obsidian,
    marginBottom: '0.5rem',
  },
};
