import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Flexbox from 'flexbox-react';
import {Col, Container, Row} from 'react-bootstrap';
import PageMetaData from 'components/app/PageMetaData';
import EnvelopeHeader from 'components/app/Header';
import Subheader from 'components/app/Subheader';

import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';
import { logout } from 'actions';
import { tagLogout } from 'services/gtm';
import { mobileAppUserSelector, userSelector } from 'selectors';
import loggedOut from '../../assets/images/logged-out.svg';

const SSOLogout = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const mobileAppUser = useSelector(mobileAppUserSelector);

  useEffect(() => {
    tagLogout(user);
    dispatch(logout());
  }, []);

  return (
    <Flexbox flexDirection={'column'} style={universalStyles.container}>
      <PageMetaData pageTitle={'Logged Out | BECU Envelopes'} />
      <EnvelopeHeader noHeaderLink />
      {!mobileAppUser && <Subheader title={'Logged out of Envelopes'} hideArrowButton={true} />}
      <Container role={'main'} id="main-content">
        <Row>
          <Col md={{span: 8, offset: 2}} style={{...universalStyles.columnPadding, textAlign: 'center'}}>
            <img
              src={loggedOut}
              alt={'securely logged out'}
              style={styles.loggedOutImage}
            />
            <div>
              <h2 style={{margin: '1rem 0 2rem'}}>
                We&apos;ve securely logged you out of Envelopes.
              </h2>
              <p>{!mobileAppUser ? 'You can now close this window.' : 'Use the back arrow to return to the home screen.'}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </Flexbox>
  );
};

export default SSOLogout;
