import {colors, fontSize} from 'styles/config';

export const styles = {
  accountInfoContainer: {
    padding: 24,
    color: colors.obsidian,
  },
  allocatableAmount: {
    fontFamily: 'MuseoSans-500',
    fontWeight: 500,
    fontSize: fontSize.big,
    marginRight: '0.5rem',
  },
  allocatableLabel: {
    color: colors.stone,
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontSize: fontSize.normal,
  },
  breadcrumbList: {
    backgroundColor: colors.snow,
    padding: '0.75rem 24px',
    borderTop: '1px solid #D5D7D9',
    borderBottom: '1px solid #D5D7D9',
    marginBottom: 0,
  },
  contentContainer: {
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: colors.cloud,
  },
  headerWrapper: {
    backgroundColor: colors.cloud,
    boxShadow: '0px 3px 3px #bcbfc2',
    padding: '16px 24px',
    borderBottom: '1px solid #b8bfc3',
    position: 'sticky',
    top: 0,
    zIndex: 100,
    margin: '-16px -24px 24px',
  },
  saveChangesButton: {
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: '0.25rem',
    position: 'sticky',
    bottom: 0,
    zIndex: 100,
  },
  tabNavContainer: {
    padding: '16px 24px 0 24px',
    backgroundColor: colors.snow,
  },
  tabNavDisplayOnTarget: {
    display: 'none',
  }
};

