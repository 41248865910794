import {colors} from 'styles/config';

export const styles = {
  progressBar: {
    width: '100%',
    height: 26,
    borderRadius: 0,
    color: colors.stone,
    backgroundColor: colors.cloud,
    border: '1px solid #B8BFC3',
    boxShadow: 'inset 0px 1px 3px 1px #bcbfc2',
  },
  todayTargetButton: {
    width: 44,
    height: 44,
    position: 'absolute',
    top: -10,
    borderRadius: '100%',
    padding: 3,
    marginLeft: -22,
  },
  startLabel: {
    position: 'absolute',
    bottom: 0,
    paddingBottom: 36,
    paddingLeft: 8,
    borderLeft: '1px solid #4d5f69',
  },
  flipStartLabel: {
    paddingLeft: 0,
    paddingRight: 8,
    borderLeft: 'none',
    borderRight: '1px solid #4d5f69',
  },
  endLabel: {
    position: 'absolute',
    top: 0,
    right: 0,
    paddingTop: 36,
    paddingRight: 8,
    borderRight: '1px solid #4d5f69',
  },
};
