export const authSelector = (state) => state.auth.loggedIn;
export const authErrorSelector = (state) => state.auth.authError;
export const userSelector = (state) => state.user.user;
export const accountsSelector = (state) => state.accounts.currentAccounts;
export const editingAccountSelector = (state) => state.accounts.editingAccount;
export const updatedEnvelopesSelector = (state) => state.envelopes.envelopesUpdated;
export const updateEnvelopeScheduleSelector = (state) => state.envelopeSchedule.envelopeScheduleUpdated;
export const activeEnvelopesSelector = (state) => state.envelopes.currentEnvelopes;
export const deletedEnvelopeSelector = (state) => state.envelopes.envelopeDeleted;
export const renamedEnvelopeSelector = (state) => state.envelopes.envelopeRenamed;
export const uiAccountsModalVisibleSelector = (state) => state.ui.accountsModalVisible;
export const uiSaveChangeModalVisibleSelector = (state) => state.ui.saveChangeModalHidden;
export const uiMaxSessionModalVisibleSelector = (state) => state.ui.maxSessionModalVisible;
export const sessionPathSelector = (state) => state.ui.pathname;
export const sessionTokenSelector = (state) => state.session.token;
export const uiSurveyMonkeyFormModalSelector = (state) => state.ui.surveyMonkeyFormModal;
export const uiTermsModalSelector = (state) => state.ui.termsModal;
export const intializedSelector = (state) => state.envelopes.initialized && state.accounts.initialized && state.user.initialized;
export const saveEnvelopesFailedSelector = (state) => state.envelopes.saveFailed;
export const pageRefreshRequiredSelector = (state) => state.envelopes.refreshRequired;
export const uiApiErrorSelector = (state) => state.ui.apiError;
export const envelopeScheduleSelector = (state) => state.envelopeSchedule.schedule;
export const envelopeScheduleSaveFailedSelector = (state) => state.envelopeSchedule.saveFailed;
export const targetCreatedSelector = (state) => state.targets.targetCreated;
export const targetUpdatedSelector = (state) => state.targets.targetUpdated;
export const targetDeletedSelector = (state) => state.targets.targetDeleted;
export const targetEndedSelector = (state) => state.targets.targetEnded;
export const financialHealthGoalSelector = (state) => state.financialHealth.financialGoal;
export const financialHealthTopicSelector = (state) => state.financialHealth.financialTopic;
export const envelopeHistorySelector = (state) => state.envelopes.currentHistory;
export const mobileAppUserSelector = (state) => state.user.mobileAppUser;