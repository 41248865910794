export const FAQ_PAGE = '/faq';
export const ARTICLE_PAGE = '/article';
export const HOME_PAGE = '/';
export const LOGIN_PAGE = '/login';
export const BUSINESSACCOUNT_PAGE = '/business-account';
export const NOT_FOUND_PAGE = '/not-found';
export const MANAGE_ENVELOPES_PAGE = '/manage-envelopes';
export const SSO_PAGE = '/sso';
export const SSO_SESSION_LOGOUT_PAGE = '/logout';

export const FAQ_PAGE_TITLE = 'Frequently Asked Questions & Tips | BECU Envelopes';
export const HOME_PAGE_TITLE = 'Home | BECU Envelopes';
export const LOGIN_PAGE_TITLE = 'Login | BECU Envelopes';
export const MANAGE_ENVELOPES_PAGE_TITLE = 'Manage Envelopes | BECU Envelopes';

export const PRIVACY_URL = process.env.REACT_APP_PRIVACY_LINK;
export const SERVICE_URL = `${process.env.REACT_APP_SERVICE_API_URL}/api/V1`;
export const HEALTH_SERVICE_URL = `${process.env.REACT_APP_HEALTH_SERVICE_API_URL}/api/V1`;
export const LOGIN_SERVICE_URL = `${process.env.REACT_APP_LOGIN_SERVICE_URL}/api/V1`;
export const TOKEN_TIME = Number(process.env.REACT_APP_TOKEN_TIME);
export const MAX_SESSION_MODAL_TIME = Number(process.env.REACT_APP_MAX_SESSION_MODAL_TIME);
export const ENABLE_MAX_SESSION_MODAL = process.env.REACT_APP_ENABLE_MAX_SESSION_MODAL;
export const RETURN_TO_OLB_LINK = `${process.env.REACT_APP_RETURN_TO_OLB_LINK}`;
export const LOGIN_API = '/authentication/signon';
export const LOGIN_TOKEN_API = '/authentication/signontoken';
export const USER_API = '/user';
export const AGREEMENT_API = '/agreement';
export const SETTINGS_API = '/setting';
export const ENVELOPES_API = '/digitalenvelope';
export const ACCOUNTS_API = '/account';
export const TARGETS_API = '/envelopetarget';
export const SCHEDULE_API = '/envelopeschedule';
export const FINANCIAL_HEALTH_API = '/financialhealth';
export const FINANCIAL_GOAL = '/financialgoal';
export const FINANCIAL_TOPIC = '/financialtopicanswer';
export const HISTORY_API = '/history';

export const MOBILE_SUBSCRIBER_CODE = process.env.REACT_APP_SSO_MOBILE_SUB_CODE;

export const TOKEN_STORAGE_KEY = 'token';
export const SESSION_STATE_KEY = 'state';
export const ACTIVE_ACCOUNT_NUMBER_KEY = "active_account_number";
export const ACTIVE_TAB_KEY = 'active_tab_key';
export const ALLOCATION_TAB_KEY = 'allocation-settings';
export const ENVELOPE_TAB_KEY = 'envelopes';
export const SESSION_TIMEOUT = 'timeout';
export const SSO_AUTH_PAGE = 'sso_auth';

export const ACCOUNT_TYPE_ALL = 0;
export const ACCOUNT_TYPE_CHECKING = 1;
export const ACCOUNT_TYPE_SAVINGS = 2;

export const ENVELOPE_UPDATE = 0;
export const ENVELOPE_SORT_UPDATE = 1;

export const TERMS_AGREEMENT_TYPE = 'TC';

export const API_ERROR_MESSAGE = 'Something went wrong and your information may not be accurate. Please try again later.';

export const SAVE_MODAL_DATA = {
    title: 'Unsaved Changes',
    body: 'Changes have not been saved and will be lost when you leave this screen. Do you wish to continue?',
    yesText: 'Yes, leave page',
    noText: 'No, continue editing'
};

export const SURVEY_MONKEY_MODALS = {
    FEEDBACK: {
        type: 'Feedback',
        title: 'BECU Envelopes Feedback',
        body: 'Please use the form below to submit feedback about the app or a specific feature.',
        body2: 'Experiencing a bug or defect? Please use our',
        surveyLink: 'https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgdxZ_2FeHgIzHddbCla3jXZkGTVh7Nq4JUaQi22Kofl3gyP.js',
    },
};

export const TOPIC_CARD_DATA = [
    {
        title: 'Create your first envelope',
        category: 'Getting Started',
        link: 'article/create'
    },
    {
        title: 'Adding and removing money from your envelopes',
        category: 'Managing Envelopes',
        link: 'article/manage',
    },
    {
        title: 'Automatic Allocation',
        category: 'Managing Envelopes',
        link: '/article/allocation'
    },
];

export const USER_SETTINGS = {
    TARGET_INTRODUCTION: 'TI',
};

export const TARGET_STATUS = {
    BEHIND: -1,
    ON_TARGET: 0,
    AHEAD: 1,
    COMPLETED: 2,
    MISSED: 3,
};

export const EXTERNAL_TARGET_STATUS = {
    NOT_COMPLETE: 0,
    REACHED: 1,
    MISSED: 2,
};
