import React, {useEffect} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import EnvelopeFooter from '../components/app/Footer';
import AccountSelectionModal from '../components/modals/AccountSelectionModal';
import MaxSessionModal from '../components/modals/MaxSessionModal';
import SurveyMonkeyModal from '../components/modals/SurveyMonkeyModal';
import MetaDataAnnouncer from '../components/app/MetaDataAnnouncer';
import TermsModal from 'components/modals/TermsModal';

import {
  FAQ_PAGE,
  ARTICLE_PAGE,
  ENABLE_MAX_SESSION_MODAL,
  HOME_PAGE,
  LOGIN_PAGE,
  BUSINESSACCOUNT_PAGE,
  MANAGE_ENVELOPES_PAGE,
  MAX_SESSION_MODAL_TIME,
  SSO_PAGE,
  TOKEN_TIME,
  SSO_SESSION_LOGOUT_PAGE,
} from '../config/constants';
import {logout, setMaxSessionModal} from 'actions';
import {setAuthError} from 'services/errorConfig';
import { getSsoAuthKey } from 'services/sessionStorage';
import {authSelector, uiMaxSessionModalVisibleSelector, sessionTokenSelector} from 'selectors';

import Articles from './Articles';
import FAQ from './FAQ';
import Home from './Home';
import Login from './Login';
import ManageEnvelopes from './ManageEnvelopes';
import NotFound from './NotFound';
import BusinessAccount from './BusinessAccounts';
import Sso from './SSO';
import SSOLogout from './SSOLogout';

const App = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loginSuccess = useSelector(authSelector);
  const maxSessionModalVisible = useSelector(uiMaxSessionModalVisibleSelector);
  const sessionToken = useSelector(sessionTokenSelector);
  // const errorModal = useSelector((state) => state.ui.errorModal);

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      if (sessionStorage.getItem('sessionStart')) {
        sessionStorage.setItem('timerRefresh', moment());
      }
    });
  }, []);

  useEffect(() => {
    if (loginSuccess && sessionToken && ENABLE_MAX_SESSION_MODAL === 'true') {
      let modalTimer;
      let showModal = true;
      let timerTimeout = TOKEN_TIME * 1000 * 60;
      let modalTimeout = MAX_SESSION_MODAL_TIME * 1000 * 60;

      if (sessionStorage.getItem('timerRefresh')) {
        const timerRefresh = moment(sessionStorage.getItem('timerRefresh'));
        const sessionStart = moment(sessionStorage.getItem('sessionStart'));
        const minDiff = (timerRefresh.minute() - sessionStart.minute()) * 1000 * 60;
        const secDiff = (timerRefresh.second() - sessionStart.second()) * 1000;

        timerTimeout -= minDiff + secDiff;
        if (minDiff + secDiff > modalTimeout) {
          showModal = false;
        } else {
          modalTimeout -= minDiff + secDiff;
        }

        sessionStorage.removeItem('timerRefresh');
      } else {
        sessionStorage.setItem('sessionStart', moment());
      }

      const sessionTimer = setTimeout(() => {
        if (getSsoAuthKey() === 'true') {
          history.replace(SSO_SESSION_LOGOUT_PAGE);
        } else {
          dispatch(logout());
          history.replace(LOGIN_PAGE);
          dispatch(setAuthError(999));
        }
      }, timerTimeout);

      if (showModal) {
        modalTimer = setTimeout(() => {
          dispatch(setMaxSessionModal(true));
        }, modalTimeout);
      }

      return () => {
        clearTimeout(sessionTimer);
        if (showModal) {
          clearTimeout(modalTimer);
        }
      };
    }
  }, [loginSuccess]);

  return (
    <>
      <MetaDataAnnouncer />
      <Switch>
        <Route path={FAQ_PAGE} component={FAQ} />
        <Route path={ARTICLE_PAGE + '/:page'} component={Articles} />
        <Route exact path={HOME_PAGE} component={Home} />
        {(process.env.REACT_APP_ENV !== 'production' && process.env.REACT_APP_ENV !== 'stage') && <Route path={LOGIN_PAGE} component={Login} />}
        <Route path={BUSINESSACCOUNT_PAGE} component={BusinessAccount} />        
        <Route path={MANAGE_ENVELOPES_PAGE} component={ManageEnvelopes} />
        <Route path={SSO_PAGE} component={Sso} />
        <Route path={SSO_SESSION_LOGOUT_PAGE} component={SSOLogout} />
        <Route component={NotFound} />
      </Switch>
      <EnvelopeFooter />
      {/* (errorModal.visible) && <ErrorModal component using errorModal.message & errorModal.code> */}
      <AccountSelectionModal />
      {maxSessionModalVisible && <MaxSessionModal />}
      <SurveyMonkeyModal />
      <TermsModal />
    </>
  );
};

export default App;
