import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {useMediaQuery} from 'react-responsive';

import {styles} from './styles';

const ArticleSteps = (data, columnStyle, stepRefs) => {
  const isLargeScreen = useMediaQuery({query: '(min-width: 768px)'});

  return data?.steps?.map((step, index) => (
    <div key={index}>
      <Row>
        <Col md={(step.image && step.image.length === 1) ? 5 : 12} style={!isLargeScreen ? {paddingTop: 20, paddingBottom: 20} : {...columnStyle}}>
          <div ref={(ref) => stepRefs.current.push(ref)} className='article-step-title' tabIndex={-1} id={`step-${index + 1}`} aria-label={`Step ${index + 1}, ${step.subTitle}`}>
            <h3 aria-hidden='true'>
              <span style={styles.light}>Step {index + 1} |</span> {step.subTitle}
            </h3>
          </div>
          {step.body}
        </Col>
        {step.image && (
          <Col md={step.image.length === 1 ? 7 : 12} style={{...columnStyle, ...(!isLargeScreen && {paddingTop: 10, paddingBottom: 10}), display: 'flex', flexDirection: !isLargeScreen ? 'column' : 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            {step.image.map((image, imageIndex) => (
              <img
                key={`image-${imageIndex}`}
                src={require(`../../../assets/images/articles/${data.imageFolder}/${isLargeScreen ? 'desktop' : 'mobile'}/${image.source}`)}
                style={{width: (step.image.length === 1 || !isLargeScreen) ? '100%' : '48%', marginTop: (imageIndex > 0 && !isLargeScreen) ? 20 : 0}}
                alt={isLargeScreen ? image.altText.desktop : image.altText.mobile}
              />
            ))}
          </Col>
        )}
        {step.callout && (
          <Col md={12} style={{paddingTop: 20, paddingBottom: 20}}>
            {step.callout}
          </Col>
        )}
      </Row>
    </div>
  ));
};

export default ArticleSteps;
