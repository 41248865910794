import {colors, fontSize} from 'styles/config';

export const styles = {
  accordionCardWrapper: {
    border: `1px solid ${colors.storm}`,
    boxShadow: 'none',
    borderRadius: 0,
  },
  accordionCardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 0,
    marginBottom: 0,
  },
  availableBalance: {
    fontWeight: 400,
    color: colors.obsidian,
  },
  allocatableAmountWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '0 -24px 24px',
    padding: '16px 24px',
    borderBottom: '1px solid #B8BFC3',
  },
  allocatableAmount: {
    fontFamily: 'MuseoSans-500',
    fontSize: fontSize.big,
    color: colors.obsidian,
    marginRight: '0.5rem',
  },
  envelopeCard: {
    border: `1px solid ${colors.cloud}`,
    boxShadow: '0px 2px 2px #bcbfc2',
    marginBottom: '0.75rem',
  },
  envelopeButton: {
    overflow: 'hidden',
    hyphens: 'auto',
    textAlign: 'left',
  },
  envelopeBalance: {
    fontFamily: 'MuseoSans-500',
    fontSize: '1.618em',
    color: colors.obsidian,
  },
};
