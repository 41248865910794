import {ACTIVE_ACCOUNT_NUMBER_KEY, ACTIVE_TAB_KEY, SESSION_STATE_KEY, SESSION_TIMEOUT, SSO_AUTH_PAGE} from 'config/constants';

export const loadState = () => {
  try {
    const lastState = sessionStorage.getItem(SESSION_STATE_KEY);
    if (lastState === null) {
      return undefined;
    }
    const state = {session: JSON.parse(lastState)};
    return state;
  } catch (err) {
    return undefined;
    // if session storage is off, return undefined
  }
};

export const saveState = (state) => {
  try {
    sessionStorage.setItem(SESSION_STATE_KEY, JSON.stringify(state));
  } catch (err) {
    // ignore errors, silently fail if session storage is off
  }
};

export const getActiveAccountNumber = () => {
  try {
    const activeAccount = sessionStorage.getItem(ACTIVE_ACCOUNT_NUMBER_KEY);
    if (activeAccount === null) {
      return undefined;
    }
    return activeAccount;
  } catch (err) {
    return undefined;
  }
};

export const setActiveAccountNumber = (accountNumber) => {
  try {
    sessionStorage.setItem(ACTIVE_ACCOUNT_NUMBER_KEY, accountNumber);
  } catch (err) {
    //silent fail
  }
};

export const getActiveTabKey = () => {
  try {
    const activeKey = sessionStorage.getItem(ACTIVE_TAB_KEY);
    if (activeKey === null) {
      return undefined;
    }
    return activeKey;
  } catch (err) {
    return undefined;
  }
};

export const setActiveTabKey = (key) => {
  try {
    sessionStorage.setItem(ACTIVE_TAB_KEY, key);
  } catch (err) {
    //silent fail
  }
};

export const getSessionTimeoutKey = () => {
  try {
    const timeoutKey = sessionStorage.getItem(SESSION_TIMEOUT);
    if (timeoutKey === null) {
      return undefined;
    }
    return timeoutKey;
  } catch (err) {
    return undefined;
  }
};

export const setSessionTimeoutKey = (key) => {
  try {
    sessionStorage.setItem(SESSION_TIMEOUT, key);
  } catch (err) {
    //silent fail
  }
};

export const getSsoAuthKey = () => {
  try {
    const ssoAuthKey = sessionStorage.getItem(SSO_AUTH_PAGE);
    if (ssoAuthKey === null) {
      return undefined;
    }
    return ssoAuthKey;
  } catch (err) {
    return undefined;
  }
};

export const setSsoAuthKey = (key) => {
  try {
    sessionStorage.setItem(SSO_AUTH_PAGE, key);
  } catch (err) {
    //silent fail
  }
};