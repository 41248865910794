import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Flexbox from 'flexbox-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarAlt, faCalendarDay, faCalendarWeek} from '@fortawesome/free-solid-svg-icons';

import {colors} from 'styles/config';
import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';
import {getNumWithCommas} from 'utils/envelopeDisplay';
import { calculateDurationSummary } from 'utils/targets';

const icons = {
  Daily: faCalendarDay,
  Weekly: faCalendarWeek,
  Monthly: faCalendarAlt,
};

const TargetSavingsTiles = ({saveAmount, saveByDate, envelopeBalance, summaryStyle, updatingTarget}) => {
  const [saveLabel, setSaveLabel] = useState(saveAmount - envelopeBalance);
  const [duration, setDuration] = useState('1 Day');
  const [tiles, setTiles] = useState([]);

  useEffect(() => {
    if (saveAmount - envelopeBalance <= 0) {
      setSaveLabel(1);
    } else {
      setSaveLabel(saveAmount - envelopeBalance);
    }
  }, [envelopeBalance, saveAmount]);

  useEffect(() => {
    if (saveAmount - envelopeBalance <= 0 || saveByDate === '' || moment().isAfter(moment(saveByDate))) {
      setDuration('1 Day');
      setTiles([]);
    } else {
      const updatedValues = calculateDurationSummary(saveAmount, saveByDate, envelopeBalance, moment().format('YYYY-MM-DD'));
      setDuration(updatedValues.duration);
      setTiles(updatedValues.tiles);
    }
  }, [envelopeBalance, saveAmount, saveByDate]);

  const SummarySection = (type) => {
    switch (type) {
      case 'card':
        return SummaryCard;
      case 'tile':
        return SummaryBox;
      case 'label':
      default:
        return SummaryLabel;
    }
  };

  const SummaryBox = (
    <div style={styles.summaryBox} id="targetMeetBreakdown">
      <p style={universalStyles.noBottomMargin}>
        {updatingTarget ? 'Based on how much you\'ve saved, to meet this new target' : 'To meet this target,'} you need to save <strong style={styles.boldLabel}>{getNumWithCommas(saveLabel.toFixed(2))}</strong> over a duration of{' '}
        <strong style={styles.boldLabel}>{duration}</strong>
      </p>
    </div>
  );

  const SummaryLabel = (
    <div>
      <h3 id="DurationSummary">
        You need to save <span id="DurationAmount">{getNumWithCommas(saveLabel.toFixed(2))}</span> over a duration of <span id="DurationTime">{duration}</span>
      </h3>
      <p>This equates to saving:</p>
    </div>
  );

  const SummaryCard = (
    <div>
      <h3 style={{marginBottom: 0}}>
        <span id="DurationAmount">{getNumWithCommas(saveLabel.toFixed(2))}</span> left to save in <span id="DurationTime">{duration}</span>
      </h3>
      <p>Based on current Envelope amount</p>
      <div style={{marginTop: 16}}>
        <p>To meet this target, you will need to save:</p>
      </div>
    </div>
  );

  return (
    <>
      {SummarySection(summaryStyle)}
      <Flexbox alignItems={'center'} justifyContent={'space-between'} flexWrap={'wrap'} style={styles.savingsTilesContainer}>
        {tiles.map((tile, index) => {
          return (tile.amount >= 0.01 ?
            <div id={tile.duration} key={index} style={{...styles.savingsTile, marginRight: tiles.length > 1 && index != tiles.length - 1 ? 4 : 0}}>
              <FontAwesomeIcon icon={icons[tile.duration]} color={colors.stone} />
              <h2 style={styles.savingsTileAmount}>{getNumWithCommas(tile.amount.toFixed(2))}</h2>
              <p style={styles.savingsTileDuration}>{tile.duration}</p>
            </div>
          : null);
        })}
      </Flexbox>
    </>
  );
};

export default TargetSavingsTiles;
