import {combineReducers} from 'redux';

import {accounts} from './accounts';
import {auth} from './auth';
import {envelopes} from './envelopes';
import {envelopeSchedule} from './envelopeSchedule';
import {financialHealth} from './financialHealth';
import {session} from './session';
import {targets} from './targets';
import {ui} from './ui';
import {user} from './user';

import {LOGOUT_SUCCESS} from '../config/actionTypes';

const rootReducer = combineReducers({
  accounts,
  auth,
  envelopes,
  envelopeSchedule,
  financialHealth,
  session,
  targets,
  ui,
  user,
});

export default (state, action) => rootReducer(action.type === LOGOUT_SUCCESS ? undefined : state, action);
