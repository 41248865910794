import React from 'react';
import {Card} from 'react-bootstrap';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestion, faLightbulb} from '@fortawesome/free-solid-svg-icons';

import {colors, fontSize} from 'styles/config';

export const BoldText = (text) => (
  <strong key={text} style={{color: colors.obsidian}}>
    {text}
  </strong>
);

export const Badge = (text) => (
  <span key={text} className="badge badge-info" aria-hidden="true">
    {text}
  </span>
);

const ParagraphText = (body) => <p key={1}>{body.map((partial) => partial)}</p>;

const OrderedList = (key, textArray) => (
  <ol key={key}>
    {textArray.map((text, index) => (
      <li key={`${key}-${index}`}>{text}</li>
    ))}
  </ol>
);

const CALLOUT_TYPES = {
  QUESTION: {
    icon: faQuestion,
  },
  TIP: {
    icon: faLightbulb,
    subtitle: 'Helpful Tip',
  },
};

export const CalloutCard = (data) => {
  return (
    <Card className={'callout'}>
      <Card.Body className={'card-body'}>
        <div className={'callout-icon'}>
          <FontAwesomeIcon icon={data.icon} style={{marginRight: '0', fontSize: fontSize.small}} />
        </div>
        <p className={'subtitle'}>{data.subtitle}</p>
        <div>{data.body.map((partial) => partial)}</div>
      </Card.Body>
    </Card>
  );
};

export const ARTICLE_CREATE_DATA = {
  title: 'Create your first envelope',
  header: 'Getting Started',
  imageFolder: 'create',
  card_callout: CalloutCard({
    ...CALLOUT_TYPES.TIP,
    body: [`Whenever an image is referenced, a letter (example `, Badge('A'), `) will be used to call out the specific part of the image.`],
  }),
  steps: [
    {
      title: 'Get started',
      subTitle: 'Get started',
      body: ParagraphText([
        `Once you’re on BECU Envelopes application, click the `,
        BoldText(`Add Envelopes`),
        ` `,
        Badge(`A`),
        ` button to begin.`,
      ]),
      image: [
        {
          source: `2-getStarted.png`,
          altText: {
            desktop: `A section of the Home Page, featuring the main navigation and welcome content at the top of the page. Add Envelopes is the first link in the main navigation. There is also an Add Envelopes button in the welcome area next to the large graphic at the top of the page.`,
            mobile: `A section of the Home Page, featuring the welcome content at the top of the page. The Add Envelopes button is in the welcome area below the large graphic at the top of the page.`,
          },
        }
      ],
    },
    {
      title: 'Select an account',
      subTitle: 'Select an account',
      body: ParagraphText([
        `BECU Envelopes act as a feature that sits on top of your existing savings account. You should see your current BECU accounts. Select one of your savings accounts to start adding envelopes.`,
      ]),
    },
    {
      title: 'Add more envelopes',
      subTitle: 'Add more envelopes',
      body: ParagraphText([`Now you can start adding envelopes. Click the `, BoldText(`Add Envelopes`), ` `, Badge(`A`), ` button to begin.`]),
      image: [
        {
          source: `4-addMoreEnv.png`,
          altText: {
            desktop: `A section of the Account Envelopes page, featuring the main navigation, page header, and envelope area. The Add Envelope button is next to the right ofto the Change Order toggle for Withdrawal Order.`,
            mobile: `A section of the Account Envelopes page, featuring the page header and envelope area. The Add Envelope button is below the Change Order toggle for Withdrawal Order.`,
          },
        }
      ],
      callout: CalloutCard({
        ...CALLOUT_TYPES.TIP,
        body: [
          `Think of Envelopes as a way to organize and track savings goals all in one account. You do not need to have separate savings accounts to manage your goals. You can move money from one savings envelope to another whenever you like.`,
        ],
      }),
    },
    {
      title: 'Name your envelope',
      subTitle: 'Name your envelope',
      body: ParagraphText([`Enter a name for your envelope `, Badge(`A`), `.`]),
      image: [
        {
          source: `5-addmore.png`,
          altText: {
            desktop: `A section of the Account Envelopes page, featuring the page header, and envelope area. There is a new envelope below the Add Envelope button. The Envelope Name field is the first field for the new envelope.`,
            mobile: `A section of the Account Envelopes page, featuring the envelope area. There is a new envelope below the Add Envelope button. The Envelope Name field is the first field for the new envelope.`,
          },
        }
      ],
      callout: CalloutCard({
        ...CALLOUT_TYPES.TIP,
        body: [`Consider naming your envelopes based on your savings goals.`],
      }),
    },
    {
      title: 'Add some money',
      subTitle: 'Add some money',
      body: ParagraphText([
        `Enter a dollar amount `,
        Badge(`A`),
        ` to add to your envelope. Your allocatable funds `,
        Badge(`B`),
        ` will adjust as you add more or less money.`,
      ]),
      image: [
        {
          source: `6-save.png`,
          altText: {
            desktop: `A section of the Account Envelopes page, featuring the page header (Account Envelopes), and envelope area. There is a new envelope below the Add Envelope button. The Initial Amount field is the second field for the new envelope. The resulting Allocatable balance is at the top of the envelope area, before the Save Changes button.`,
            mobile: `A section of the Account Envelopes page, featuring the envelope area. There is a new envelope below the Add Envelope button. The Initial Amount field is the second field for the new envelope. The resulting Allocatable balance is at the top of the envelope area, before the Save button.`,
          },
        }
      ],
      callout: CalloutCard({
        ...CALLOUT_TYPES.QUESTION,
        subtitle: `What does allocatable mean?`,
        body: [BoldText(`Allocatable`), ` `, Badge(`B`), ` is the amount of funds in your savings account you can distribute to your envelope.`],
      }),
    },
    {
      title: 'Save your envelopes',
      subTitle: 'Save your envelopes',
      body: ParagraphText([
        `Click the `,
        BoldText(`Save Changes button (Save button on mobile)`),
        ` `,
        Badge(`A`),
        ` when you are done. Add more envelopes, name them, and add some money.`,
      ]),
      image: [
        {
          source: `7-manage.png`,
          altText: {
            desktop: `A section of the Account Envelopes page, featuring the page header Account Envelopes and envelope area. The Save Changes button is at the top of the envelope area, to the right of the Allocatable balance.`,
            mobile: `A section of the Account Envelopes page, featuring the envelope area. The Save Changes button is at the top of the envelope area, to the right of the Allocatable balance.`,
          },
        }
      ],
    },
    {
      title: 'Manage and monitor',
      subTitle: 'Manage and monitor',
      body: ParagraphText([`Now that you have added your first set of envelopes, you can manage and monitor their status any time.`]),
      image: [
        {
          source: `8-manage.png`,
          altText: {
            desktop: `A section of the Home page, featuring the main navigation, the welcome area, and the account area. The first account is expanded to show envelopes for that account. The second account accordion is collapsed so that the envelopes are hidden.`,
            mobile: `A section of the Home page, featuring the account area. The first account is expanded to show envelopes for that account.`,
          },
        }
      ],
      callout: CalloutCard({
        ...CALLOUT_TYPES.TIP,
        body: [` Your envelopes are organized by savings account `, Badge(`A`), `. You can add up to 12 envelopes per account.`],
      }),
    },
  ],
};

export const ARTICLE_MOVE_MONEY_DATA = {
  title: 'Moving Money Out of Your Account',
  header: 'Managing Envelopes',
  imageFolder: 'move',
  card_callout: CalloutCard({
    ...CALLOUT_TYPES.TIP,
    body: [`Whenever an image is referenced, a letter (example `, Badge('A'), `) will be used to call out the specific part of the image.`],
  }),
  steps: [
    {
      title: 'Moving money from your savings envelopes',
      subTitle: 'Moving money from your savings envelopes',
      body: ParagraphText([
        `We understand sometimes you need flexibility to move funds between your accounts. When money is removed from your savings account, we’ll remove money from your `,
        BoldText(`Allocatable balance`),
        ` `,
        Badge(`A`),
        ` first. If you have money in your envelopes and your Allocatable drops below zero, money will be pulled from your envelopes in the order prioritized from top to bottom.`,
      ]),
      image: [
        {
          source: `1-moving.png`,
          altText: {
            desktop: `A section of the Account Envelopes page, featuring the page header (Account Envelopes) and envelope area. The Allocatable balance is at the top of the envelope area, to the left of the Save Changes button. The envelopes are below. The Withdrawal Order for each envelope can be found above the envelope’s name.`,
            mobile: `A section of the Account Envelopes page, featuring the envelope area. The Allocatable balance is at the top of the envelope area, to the left of the Save button. The envelopes are below. The Withdrawal Order for each envelope can be found above the envelope’s name.`,
          },
        }
      ],
      callout: CalloutCard({
        ...CALLOUT_TYPES.TIP,
        body: [`You can see your prioritized order by the number assigned above the envelope’s name `, Badge(`B`), `.`],
      }),
    },
    {
      title: 'Setting a Withdrawal Order',
      subTitle: 'Setting a Withdrawal Order',
      body: [
        OrderedList(`WithdrawalOrder`, [
          [` To change your priority setting, simply toggle on the `, BoldText(`Change order`), ` `, Badge(`A`), ` switch on the Account Envelopes page.`],
          [
            `Next, use the up/down arrows `,
            Badge(`B`),
            ` or drag and drop`,
            Badge(`C`),
            ` your envelopes to reorder the withdrawal priority preference of your envelopes. The withdrawal order `,
            Badge(`D`),
            ` will be updated, and the new number(s) will reflect your changes.`,
          ],
        ]),
        ParagraphText([BoldText(`Remember to save your changes when you are done. Click the Save Changes button (Save button on mobile) `), Badge(`E`), `.`]),
      ],
      image: [
        {
          source: `2-toggle.png`,
          altText: {
            desktop: `A section of the Account Envelopes page, featuring the page header (Account Envelopes) and envelope area. The Withdrawal Order toggle is near the top of the envelope area, below the Allocatable balance and Save Changes button. The envelopes are below. The Withdrawal Order for each envelope is on the top left of that envelope and can be changed with the arrows on the top right of the envelope.`,
            mobile: `A section of the Account Envelopes page, featuring the envelope area. The Withdrawal Order toggle is near the top of the envelope area, below the Allocatable balance and Save Changes button. The envelopes are below. The Withdrawal Order for each envelope is on the top left of that envelope and can be changed with the arrows on the top right of the envelope.`,
          },
        }
      ],
      callout: CalloutCard({
        ...CALLOUT_TYPES.TIP,
        body: [
          `Consider designating your least important envelope to the top position
            because this is where money will be removed first, and your most important
            envelope to the bottom position.`,
        ],
      }),
    },
  ],
};

export const ARTICLE_AUTOMATIC_ALLOCATION = {
  title: 'Automatic Allocation',
  header: 'Managing Envelopes',
  imageFolder: 'allocation',
  card_callout: CalloutCard({
    ...CALLOUT_TYPES.TIP,
    body: [`Whenever an image is referenced, a letter (example `, Badge('A'), `) will be used to call out the specific part of the image.`],
  }),
  steps: [
    {
      title: 'Select an account',
      subTitle: 'Select an account',
      body: ParagraphText([
        `On the home screen, you should see your current BECU accounts. Select the savings account where you would like to add Automatic Allocation, and click on the `,
        BoldText(`Account Name`),
        ` `,
        Badge(`A`),
        ` to open the Account Envelopes screen for that account.`,
      ]),
      image: [
        {
          source: `1-select.png`,
          altText: {
            desktop: `A section of the Home Page, featuring a list of savings accounts. Each account name is a link to the Manage screen for that account.`,
            mobile: `A section of the Home Page, featuring a list of savings accounts. Each account name is a link to the Manage screen for that account.`,
          },
        }
      ],
    },
    {
      title: 'Open Allocation Settings',
      subTitle: 'Open Allocation Settings',
      body: [
        ParagraphText([
          `Click the `,
          BoldText(`Allocation Settings tab`),
          ` `,
          Badge(`A`),
          ` at the top of the Account Envelopes screen to access your settings.`,
        ]),
      ],
      image: [
        {
          source: `2-settings.png`,
          altText: {
            desktop: `A section of the Manage screen for a savings account. Allocation Settings is one of two tabs towards the top of the screen, above the Allocatable balance and Envelope area.`,
            mobile: `A section of the Manage screen for a savings account. Allocation Settings is one of two tabs towards the top of the screen, above the Allocatable balance and Envelope area.`,
          },
        }
      ],
    },
    {
      title: 'Toggle Automatic Allocation on',
      subTitle: 'Toggle Automatic Allocation on',
      body: [
        ParagraphText([
          `Click the `,
          BoldText(`switch for Automatic Allocation`),
          ` `,
          Badge(`A`),
          ` to toggle the feature on. The configuration options below will become enabled.`,
        ]),
      ],
      image: [
        {
          source: `3-toggle.png`,
          altText: {
            desktop: `A section of the Automatic Allocation page, featuring the allocatable balance and automatic allocation settings. The Automatic Allocation toggle is near the top of the setting page, below the Allocatable balance and Save Changes button. The
            settings are below. Automatic Allocation can be turned on and off by flipping the toggle left or right.`,
            mobile: `A section of the Automatic Allocation page, featuring the allocatable balance and automatic allocation settings. The Automatic Allocation toggle is near the top of the setting page, below the Allocatable balance and Save button. The settings are
            below. Automatic Allocation can be turned on and off by flipping the toggle left or right`,
          },
        }
      ],
      callout: CalloutCard({
        ...CALLOUT_TYPES.TIP,
        body: [`You must have at least one envelope in your account to turn on Automatic Allocation.`],
      }),
    },
    {
      title: 'Set your Automatic Allocation in dollars or percentages',
      subTitle: 'Set how you want to allocate money',
      body: [
        ParagraphText([`Click the`,
        BoldText(` radial button`),
        `  to use dollar amounts or percentages.`]),
      ],
      image: [
        {
          source: `4-day.png`,
          altText: {
            desktop: `A section of the Automatic Allocation page, featuring the allocatable balance and automatic allocation settings. The Automatic Allocation toggle is near the top of
            the setting page is set in the “On” position. You can set how you want to allocate your money, either by dollar amounts or percentages.`,
            mobile: `A section of the Automatic Allocation page, featuring the allocatable balance and automatic allocation settings. The Automatic Allocation toggle is near the top of the setting page is set in the “On” position. You can set how you want to allocate your money, either by dollar amounts or percentages`,
          },
        }
      ],
    },
    {
      title: 'Select when Automatic Allocation will run',
      subTitle: 'Select when Automatic Allocation will run',
      body: [
        ParagraphText([
          `Use the `,
          BoldText(`What day do you want to start and run this?" datepicker`),
          ` `,
          Badge(`A`),
          ` to select the date that Automatic Allocation will start running. This date will also determine the day of the week or month that your Automatic Allocations will occur.`,
        ]),
        ParagraphText([
          `If you select a date in the past, Automatic Allocations will run the next time your selected day occurs. For example, if you select "Every Week" and a Monday in the past, your first Automatic Allocation will be the next full Monday.`,
        ]),
      ],
      image: [
        {
          source: `5-day.png`,
          altText: {
            desktop: `A section of the Automatic Allocation page, featuring the allocatable balance and automatic allocation settings. The Automatic Allocation toggle is near the top of the setting page, below the Allocatable balance and Save Changes button. The settings are below. Automatic Allocation can be turned on and off by flipping the
            toggle left or right. A calendar view illustrates what day you want to start and run the automatic allocation.`,
            mobile: `A section of the Automatic Allocation page, featuring the allocatable balance and automatic allocation settings. The Automatic Allocation toggle is near the top of the setting page, below the Allocatable balance and Save button. The settings are below. Automatic Allocation can be turned on and off by flipping the toggle left or
            right. A calendar view illustrates what day you want to start and run the automatic allocation`,
          },
        }
      ],
    },
    {
      title: 'Select a frequency',
      subTitle: 'Select a frequency',
      body: [
        ParagraphText([
          `Use the `,
          BoldText(`How often do you want to allocate money?" dropdown`),
          ` `,
          Badge(`A`),
          ` to select the frequency that Automatic Allocation will run.`,
        ]),
        ParagraphText([`Select a frequency that works best for you, based on how often you tend to move money into your savings account.`]),
      ],
      image: [
        {
          source: `6-frequency.png`,
          altText: {
            desktop: `A section of the Automatic Allocation page, featuring the allocatable balance and automatic allocation settings. The Automatic Allocation toggle is near the top of the setting page. The settings are below. Automatic Allocation can be turned on and off by flipping the toggle left or right. A drop down field allows for selection of how often you want to allocate money.`,
            mobile: `A section of the Automatic Allocation page, featuring the allocatable balance and automatic allocation settings. A drop down field allows for selection of how often you want to allocate money`,
          },
        }
      ],
    },
    {
      title: 'Specify how money will be allocated',
      subTitle: 'Specify how money will be allocated',
      body: [
        ParagraphText([
          `You can set automatic allocation amount in dollars or percentages. Use the `,
          BoldText(`input fields for each envelope`),
          ` `,
          Badge(`A`),
          ` to specify the exact dollar amount to be moved or what percent of your allocatable balance will go to each envelope.`,
        ]),
        ParagraphText([
          `If using percentages, you can specify 0% up to 100% but the combined percentages for all envelopes cannot exceed 100%. If using dollars, you can specify $0 up to the full allocation balance dollars but the combined dollar amounts of all your envelopes cannot exceed the full allocatable balance.`,
        ]),
      ],
      image: [
        {
          source: `7-percent.png`,
          altText: {
            desktop: `A section of the Automatic Allocation page, featuring the allocatable balance and automatic allocation settings. The Automatic Allocation toggle is near the top of the setting page. The settings are below. Automatic Allocation can be turned on and off by flipping the toggle left or right. Using a date selector to start you automatic allocation and a drop down field allows for selection of how often you want to allocate money. Using a table of current envelopes, you can distribute your automatic allocation either by percentage or dollar amount.`,
            mobile: `A section of the Automatic Allocation page, featuring a date selector to start you automatic allocation and a drop down field allows for selection of how often you want to allocate money. Using a table of current envelopes, you can distribute your automatic allocation either by percentage or dollar amount.`,
          },
        }
      ],
    },
    {
      title: 'Save your changes',
      subTitle: 'Save your changes',
      body: [
        ParagraphText([
          `Be sure to click `,
          BoldText(`Save Changes (Save on mobile)`),
          ` `,
          Badge(`A`),
          ` each time you change the configuration or toggle Automatic Allocation on or off.`,
        ]),
      ],
      image: [
        {
          source: `8-save.png`,
          altText: {
            desktop: `A section of the Automatic Allocation page, featuring the allocatable balance and automatic allocation settings. The Automatic Allocation toggle is near the top of the setting page. The settings are below. Clicking the Save changes button in the top right corner will save your selections.`,
            mobile: `A section of the Automatic Allocation page, featuring the allocatable balance and automatic allocation settings. The Automatic Allocation toggle is near the top of the setting page. The settings are below. Clicking the Save button in the top right corner will save your selections.`,
          },
        }
      ],
    },
  ],
};

export const ARTICLE_ADD_REMOVE_MONEY = {
  title: 'Adding and removing money from your envelopes',
  header: 'Managing Envelopes',
  imageFolder: 'manage',
  card_callout: CalloutCard({
    ...CALLOUT_TYPES.TIP,
    body: [`Whenever an image is referenced, a letter (example `, Badge('A'), `) will be used to call out the specific part of the image.`],
  }),
  steps: [
    {
      title: 'Select Add Money or Remove Money',
      subTitle: 'Select Add Money or Remove Money',
      body: [
        ParagraphText([
          `To add money, click on the `,
          BoldText(`Add Money`),
          ` `,
          Badge(`A`),
          ` button to begin.`,
        ]),
        ParagraphText([
          `To remove money, click on the `,
          BoldText(`Remove Money`),
          ` `,
          Badge(`B`),
          ` button to begin.`,
        ]),
      ],
      image: [
        {
          source: `1-initial.png`,
          altText: {
            desktop: `In an image of Envelopes, an A symbol highlights the Add Money button. A B symbol highlights the Remove Money button. A C symbol highlights the Allocatable total amount. `,
            mobile: `In an image of Envelopes, an A symbol highlights the Add Money button. A B symbol highlights the Remove Money button. A C symbol highlights the Allocatable total amount. `,
          },
        }
      ],
      callout: CalloutCard({
        ...CALLOUT_TYPES.TIP,
        body: [`Allocatable `, Badge('C'), ` is the amount of funds in your savings account you can distribute to your envelope. Adding money to your envelope will remove money from your Allocatable total. Removing money from your envelope will add money back to your Allocatable total.`],
      }),
    },
    {
      title: 'Adjusting Amount of Money',
      subTitle: 'Adjusting Amount of Money',
      body: [
        ParagraphText([
          `To add money, click Add Money and enter a dollar amount `,
          Badge(`A`),
          ` to add to your envelope`,
        ]),
        ParagraphText([
          `To remove money, click Remove Money and enter a dollar amount `,
          Badge(`B`),
          ` to remove from your envelope`,
        ]),
      ],
      image: [
        {
          source: `2-adding.png`,
          altText: {
            desktop: `In an image of Envelopes, an A symbol highlights the amount of money to be added to the envelope.`,
            mobile: `In an image of Envelopes, an A symbol highlights the amount of money to be added to the envelope.`,
          },
        },
        {
          source: `2-removing.png`,
          altText: {
            desktop: `In an image of Envelopes, a B symbol highlights the amount of money to be removed from the envelope.`,
            mobile: `In an image of Envelopes, a B symbol highlights the amount of money to be removed from the envelope.`,
          },
        }
      ],
      callout: CalloutCard({
        ...CALLOUT_TYPES.TIP,
        body: [`To toggle between adding or removing money, click on the button that is not already activated (for example `, Badge('C'), `).`],
      }),
    },
    {
      title: 'Save Changes',
      subTitle: 'Save Changes',
      body: [
        ParagraphText([
          `Click the `,
          BoldText(`Save Changes`),
          ` `,
          Badge(`A`),
          ` button when you are done.`,
        ]),
      ],
      image: [
        {
          source: `3-save.png`,
          altText: {
            desktop: `In an image of Envelopes, an A symbol highlights how to Save Changes after adding or removing money from an envelope.`,
            mobile: `In an image of Envelopes, an A symbol highlights how to Save Changes after adding or removing money from an envelope.`,
          },
        }
      ],
      callout: CalloutCard({
        ...CALLOUT_TYPES.TIP,
        body: [`Note that before clicking the `, BoldText(`Save Changes`), ` `, Badge(`A`), ` button, New Amount `, Badge(`B`), ` reflects the total amount that will be updated.`],
      }),
    },
    {
      title: 'You\'re Done!',
      subTitle: 'You\'re Done!',
      body: [
        ParagraphText([`Now that you've saved your changes, you can continue to add and remove money from your envelopes as needed.`]),
        ParagraphText([
          `Notice that the money you have moved into the envelope `,
          Badge(`B`),
          ` has been removed from Allocatable `,
          Badge(`A`),
        ]),
      ],
      image: [
        {
          source: `4-updated.png`,
          altText: {
            desktop: `In an image of Envelopes, an A symbol highlights the Allocatable total amount. The B symbol highlights the total Envelope amount.`,
            mobile: `In an image of Envelopes, an A symbol highlights the Allocatable total amount. The B symbol highlights the total Envelope amount.`,
          },
        }
      ],
    },
  ],
};