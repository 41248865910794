import React from 'react';
import {Button, Col, Container, Row} from 'react-bootstrap';

import {toggleAccountsModal} from '../../../actions';
import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';
import newUser from '../../../assets/images/new-user.svg';
import envelopes from '../../../assets/images/envelopes.svg';

const HomeBanner = ({user, dispatch}) => (
  <div style={{...universalStyles.dropShadow, position: 'relative'}}>
    <Container>
      {user.isFirstTimeUser ? (
        <Row>
          <Col md={6} style={{...universalStyles.columnPadding, paddingBottom: 0}}>
            <img src={newUser} style={universalStyles.fullWidth} alt={'New User Icon'} />
          </Col>
          <Col md={6} style={styles.bannerCol}>
            <h1 style={styles.bannerTitle} id="WelcomeName">
              Welcome, {user.firstName}
            </h1>
            <div style={styles.body}>Use envelopes to dedicate parts of your savings account to whatever goals matter most to you!</div>
            <Button id={'AddEnvelopesButton'} variant={'primary'} style={styles.addEnvelopeButton} onClick={() => dispatch(toggleAccountsModal(true))}>
              Add Envelopes
            </Button>
          </Col>
        </Row>
      ) : (
        <Col style={universalStyles.columnPadding}>
          <h1 style={styles.bannerTitle} id="WelcomeName">
            Welcome, {user.firstName}
          </h1>
          <img
            src={envelopes}
            style={{position: 'absolute', right: 0, bottom: 0, height: '105%'}}
            alt={'Envelope Icon'}
          />
        </Col>
      )}
    </Container>
  </div>
);

export default HomeBanner;
