import {EXTERNAL_TARGET_STATUS, TARGET_STATUS} from 'config/constants';
import moment from 'moment';

export const getTargetStatusLabel = (status) => {
  switch (status) {
    case TARGET_STATUS.BEHIND:
      return 'Behind Target';
    case TARGET_STATUS.AHEAD:
      return 'Ahead of Target';
    case TARGET_STATUS.COMPLETED:
      return 'Target Reached!';
    case TARGET_STATUS.MISSED:
      return 'Target Missed.';
    default:
      return 'On Target';
  }
};

export const getTargetStatus = (target, envelopeBalance) => {
  const todayTargetAmount = calculateTodayBalance(target.targetStartDate, target.targetEndDate, target.targetAmount, target.startAmount);

  switch (true) {
    case target.targetComplete === EXTERNAL_TARGET_STATUS.MISSED:
      return TARGET_STATUS.MISSED;
    case target.targetComplete === EXTERNAL_TARGET_STATUS.REACHED:
      return TARGET_STATUS.COMPLETED;
    case envelopeBalance - todayTargetAmount < 0:
      return TARGET_STATUS.BEHIND;
    case envelopeBalance - todayTargetAmount > 0:
      return TARGET_STATUS.AHEAD;
    default:
      return TARGET_STATUS.ON_TARGET;
  }
};

export const calculateTargetPercentage = (start, end) => {
  let value = (start / end) * 100;
  if (value > 100) {
    value = 100;
  }

  return value;
};

export const calculateAmountDiff = (envelopeBalance, target) => {
  return Math.abs(envelopeBalance - calculateTodayBalance(target.targetStartDate, target.targetEndDate, target.targetAmount, target.startAmount)).toFixed(2);
};

export const calculateTodayBalance = (startDate, endDate, targetAmount, targetStartAmount) => {
  const todayTargetPercent = calculateTodayTarget(startDate, endDate, targetAmount, targetStartAmount) / 100;
  return (todayTargetPercent * targetAmount).toFixed(2);
};

export const calculateTodayTarget = (startDate, endDate, targetAmount, targetStartAmount) => {
  const duration = dateDiff(startDate, endDate);
  const current = Math.ceil(dateDiff(startDate, moment().format('YYYY-MM-DD')));
  const startAmountPercent = calculateTargetPercentage(targetStartAmount, targetAmount);
  const value = (current / duration) * 100;

  let targetPosition = startAmountPercent + (1 - startAmountPercent / 100) * value;

  if (targetPosition < 0) {
    targetPosition = 0;
  } else if (targetPosition > 100) {
    targetPosition = 100;
  }

  return targetPosition;
};

const dateDiff = (startDate, endDate) => {
  return moment(endDate).diff(moment(startDate), 'days');
};

export const calculateDuration = (startDate, endDate) => {
  let duration = '';

  const years = moment(endDate).diff(moment(startDate), 'years');
  const months = moment(endDate).subtract(years, 'years').diff(moment(startDate), 'months');
  const weeks = moment(endDate).subtract(years, 'years').subtract(months, 'months').diff(moment(startDate), 'weeks');
  const days = moment(endDate).subtract(years, 'years').subtract(months, 'months').subtract(weeks, 'weeks').diff(moment(startDate), 'days');

  duration += years > 0 ? years + ' year' + (years > 1 ? 's ' : ' ') : '';
  duration += months > 0 ? months + ' month' + (months > 1 ? 's ' : ' ') : '';
  duration += weeks > 0 ? weeks + ' week' + (weeks > 1 ? 's ' : ' ') : '';
  duration += days > 0 ? days + ' day' + (days > 1 ? 's ' : ' ') : '';

  return duration;
};

export const calculateDurationSummary = (amount, endDate, envBalance, startDate) => {
  const amountDiff = amount - envBalance;

  const start = moment().startOf('day');
  const end = moment(endDate);
  let current = moment().startOf('day');

  const years = end.diff(current, 'years');
  current.add(years, 'years');
  const months = end.diff(current, 'months');
  current.add(months, 'months');
  const weeks = end.diff(current, 'weeks');
  current.add(weeks, 'weeks');
  const days = end.diff(current, 'days');

  const totalMonths = end.diff(start, 'months');
  const totalWeeks = end.diff(start, 'weeks');
  const totalDays = end.diff(start, 'days');

  let tiles = [];
  if (totalMonths >= 1) {
    tiles.push({
      duration: 'Monthly',
      amount: Math.ceil((amountDiff / totalMonths) * 100) / 100,
    });
  }
  if (totalWeeks >= 1) {
    tiles.push({
      duration: 'Weekly',
      amount: Math.ceil((amountDiff / totalWeeks) * 100) / 100,
    });
  }
  if (totalDays >= 1) {
    tiles.push({
      duration: 'Daily',
      amount: Math.ceil((amountDiff / totalDays) * 100) / 100,
    });
  }

  let duration = '';
  duration += years > 0 ? years + ' year' + (years > 1 ? 's ' : ' ') : '';
  duration += months > 0 ? months + ' month' + (months > 1 ? 's ' : ' ') : '';
  duration += weeks > 0 ? weeks + ' week' + (weeks > 1 ? 's ' : ' ') : '';
  duration += days > 0 ? days + ' day' + (days > 1 ? 's ' : ' ') : '';

  return {
    duration,
    tiles,
  };
};