import {createUseStyles} from 'react-jss';
import {colors} from 'styles/config';

export const styles = createUseStyles({
  addEnvelopes: {
    height: 'inherit',
    borderColor: 'rgb(25, 40, 56) !important',
    color: 'rgb(25, 40, 56) !important',
    backgroundColor: `${colors.snow} !important`,
    '&[aria-disabled="true"]:hover': {
      borderColor: 'rgb(25, 40, 56) !important',
      color: 'rgb(25, 40, 56) !important',
      backgroundColor: `${colors.snow} !important`,
    },
    '&:hover': {
      backgroundColor: 'rgb(25, 40, 56) !important',
      color: `${colors.snow} !important`,
    },
  },
});