import {UPDATE_SESSION_PATH, UPDATE_SESSION_TOKEN} from '../config/actionTypes';

export const setSessionPath = (state) => (dispatch) => {
  const setSessionPath = (path) => {
    return {
      type: UPDATE_SESSION_PATH,
      payload: path,
    };
  };

  dispatch(setSessionPath(state));
};

export const setSessionToken = (token) => {
  return {
    type: UPDATE_SESSION_TOKEN,
    payload: token,
  };
};
