import React from 'react';

import {colors} from 'styles/config';
import {TOPIC_CARD_DATA} from './constants';
import file from '../assets/images/icons/file.svg';

const targetIntroductionImageMobile = require('../assets/images/targets/introduction-mobile.png');

const AllocatableExample = () => (
  <ul key={'AllocatableExample'}>
    <li>Total savings account available balance: $3,000</li>
    <li>Current Envelopes:</li>
    <li style={{marginLeft: '1.5rem'}}>New Car: $1,000</li>
    <li style={{marginLeft: '1.5rem'}}>Emergency: $1,200</li>
    <li style={{marginLeft: '1.5rem'}}>Allocatable: $800</li>
  </ul>
);

const ArticleLink = (topic) => (
  <div key={topic.title.replace(/ /g, '-')} style={{marginBottom: '1rem'}}>
    <img src={file} alt={'file icon'} style={{width: 32, marginRight: 4}} />
    <a id={topic.title.replace(/ /g, '-')} href={topic.link} rel="noreferrer">
      {topic.title}
    </a>
    {' | Article (5 min read)'}
  </div>
);

const BoldText = (text) => (
  <strong key={text} style={{color: colors.obsidian}}>
    {text}
  </strong>
);

const ParagraphText = (key, text) => <p key={key}>{text}</p>;

const Button = (text, location) => (
  <div style={{marginTop: '1.5rem'}}>
    <button
      id={`${text.replace(/ /g, '-')}`}
      key={text}
      onClick={() => window.open(location, '_self')}
      className={'btn btn-primary'}
      style={{width: 'auto', minWidth: '20%'}}
    >
      {text}
    </button>
  </div>
);

const OrderedList = (key, textArray) => (
  <ol key={key}>
    {textArray.map((text, index) => (
      <li key={`${key}-${index}`}>{text}</li>
    ))}
  </ol>
);

const UnOrderedList = (key, bottomMargin, textArray) => (
  <ul key={key} style={{marginBottom: bottomMargin ? '1em' : 0}}>
    {textArray.map((text, index) => (
      <li key={`${key}-${index}`}>{text}</li>
    ))}
  </ul>
);

// const LinkText = (key, text, link, newPage) => (
//   <a key={key} href={link} target={newPage ? '_blank' : '_self'} rel="noreferrer">
//     {text}
//   </a>
// );

const TargetIntroductionImage = () => (
  <img
    src={targetIntroductionImageMobile}
    alt={'A section of the Envelope\'s target page, featuring the target status. The status consists of the starting amount at the beginning of a progress bar, and the ending (goal) amount at the end of the progress bar. The progress bar shows how much you have saved. A daily target over the progress bar shows how much you should have in your envelope be to be on track.'}
    style={{display: 'flex', maxWidth: '100%', margin: '0 auto 1em'}}
  />
);

export const FAQ_DATA = [
  {
    title: 'Getting Started',
    topics: [
      {
        subtitle: 'Creating and Naming',
        questions: [
          {
            q: 'What is BECU envelopes?',
            a:
              'It’s a digital tool within your BECU savings account that makes it easy to set aside money for all your savings goals. Envelopes lets you save money in different containers, each with its own name, giving you the ability to organize, visualize, track and plan to reach your goals in a more targeted way. You can create up to 12 savings envelopes for each savings account.',
          },
          {
            q: 'How do I create my first envelope?',
            a: [
              ArticleLink(TOPIC_CARD_DATA[0]),
              OrderedList('create', [
                ['Click ', BoldText('Add Envelopes')],
                'Choose the BECU savings account you want to create the envelope under.',
                ['Click ', BoldText('Add Envelope')],
                'Name your envelope and then fund it with money from your selected savings account. If desired: add additional envelopes to the selected savings account.',
                ['When you’ve finished, click ', BoldText('Save Changes'), '. Your newly created envelope(s) will be displayed under your savings account.'],
              ]),
            ],
          },
          {
            q: 'What do I name my envelope?',
            a: [
              'You can name your envelope anything you like. ',
              BoldText('Helpful tip:'),
              ' Name it to reflect your goal. Example: If you are saving for a new car, you could name your envelope “New car” or “New Jeep” to provide a little extra motivation to achieve your goal.',
            ],
          },
        ],
      },
      {
        subtitle: 'Adding Money',
        questions: [
          {
            q: 'How do I add money to my envelope?',
            a: 'You can allocate an initial amount to your envelope after you create and name a new envelope.',
          },
          {
            q: 'How many envelopes can I have?',
            a: 'You can create up to 12 savings envelopes per savings account.',
          },
          {
            q: 'How do I move an existing envelope to another BECU savings account?',
            a:
              'At this time, you cannot move envelopes between accounts. However, you can delete the envelope and recreate it in your desired savings account.',
          },
          {
            q: 'I have multiple BECU savings account, how do I add envelopes to a different savings account?',
            a: 'You will be prompted to select an account before creating an envelope. Select the additional account and add your new envelope.',
          },
        ],
      },
      {
        subtitle: 'Common Questions',
        questions: [
          {
            q: 'Why do I not see my BECU checking account?',
            a:
              'BECU Envelopes is only available for certain BECU savings, including money market accounts at this time. Account ownerships not eligible for Envelopes include, Business, HSA, Early Saver, Trust, Fiduciary, and Benevolent.',
          },
          {
            q: 'Why do I not see my BECU business account?',
            a:
              'BECU envelopes is only available for certain BECU savings, including money market accounts at this time. Account ownerships not eligible for Envelopes include, Business, HSA, Early Saver, Trust, Fiduciary, and Benevolent.',
          },
          {
            q: 'Is there a minimum amount of money I need to keep in each envelope?',
            a: 'No, you can create and have an envelope with $0 in it.',
          },
          {
            q: 'What is Allocatable?',
            a: [
              'Allocatable is the amount of funds in your savings account you may distribute to your envelopes. In the example below, you can still distribute $800 to your envelopes:',
              AllocatableExample(),
            ],
          },
          {
            q: 'What is History?',
            a: [
              ParagraphText('history-description', 'It keeps track of where and when money is being moved in & out of your envelopes. You’ll see your recent envelope transactions in History as it keeps a running record of all the transactions logged in your envelopes in chronological order.'),
              ParagraphText('history-duration', 'History will show envelope transactions from the last 6 months starting from January 1, 2022.'),
              BoldText('History descriptions:'),
              UnOrderedList('descriptions', false, [
                [BoldText('Funds added:'), ' When you add money into an envelope'],
                [BoldText('Funds removed:'), ' When you remove money out of an envelope'],
                [BoldText('Funds auto allocated:'), ' When Automatic Allocation adds money to an envelope'],
                [BoldText('Account Withdrawal:'), ' When money is removed out of an envelope because money was withdrawn from your savings (or money market) account.'],
              ])
            ],
          },
          {
            q: 'Will using BECU Envelopes affect my existing BECU savings account?',
            a:
              'No, you can continue to use your account as you normally would. Activity in Envelopes will not impact your BECU account or account balance. In other words, allocating funds in Envelopes is only for budgeting purposes and will not cause any actual money movement in your BECU account. Think of BECU Envelopes as an added feature that gives you a different way to view your existing savings account.',
          },
        ],
      },
    ],
  },
  {
    title: 'Managing Envelopes',
    topics: [
      {
        subtitle: 'Adding and Moving',
        questions: [
          {
            q: 'How do I add money to my existing envelope?',
            a: [
              OrderedList('add', [
                'Go to your Account Envelopes page to move money from your Allocatable to your envelope.',
                [
                  'Click the ',
                  BoldText(`'Add Money'`),
                  ' button and enter the amount you want to add to your envelope. Your envelope balance and Allocatable will update.',
                ],
                [
                  'Review your envelope balance and click ',
                  BoldText('Save Changes'),
                  ' in the top right corner. You can add money to multiple envelopes on the same page.',
                ],
              ]),
              BoldText('Important:'),
              " Your changes won't be saved until you click ",
              BoldText('Save Changes'),
              '.',
            ],
          },
          {
            q: 'How do I remove money from my envelope?',
            a: [
              OrderedList('remove', [
                [
                  'From your Account Envelopes page, click ',
                  BoldText(`'Remove Money'`),
                  ' and enter the amount you want to subtract from your envelope. Your envelope balance and Allocatable will update.',
                ],
                [
                  'Review your envelope balance and click ',
                  BoldText('Save Changes'),
                  ' in the top right corner. You can remove money from multiple envelopes on the same page.',
                ],
              ]),
              BoldText('Important:'),
              " Your changes won't be saved until you click ",
              BoldText('Save Changes'),
              '.',
            ],
          },
          {
            q: 'What happens if money is deposited into my savings account?',
            a: 'When a deposit occurs, the money will go directly to your Allocatable. You’ll then be able to allocate the funds to your envelopes.',
          },
          {
            q:
              'What happens if money is removed from my savings account? For example when I withdraw money from my savings account, how does this impact my envelopes?',
            a: [
              ArticleLink({title: 'Moving Money Out of Your Account', link: '/article/move'}),
              'When money is removed out of your savings account, we’ll remove money from your Allocatable first. If you have envelopes and your Allocatable drops below zero, money will be pulled from your envelopes in the order you have designated. If you have more than one envelope, you may designate which envelope’s allocated amount should be reduced first.',
            ],
          },
          {
            q: 'How do I set my withdrawal priority setting?',
            a:
              'To change your priority setting, simply toggle on the “Change order” switch on the Account Envelopes page. Next, drag and drop your envelopes up or down to re-order the withdrawal priority preference of your envelopes. The priority order will update to reflect your changes. Remember to save your changes when you are done. You may want to consider designating your least important envelope to the first position since this is where money will be removed first and your most important envelope to the last position.',
          },
          {
            q:
              process.env.REACT_APP_AA_FLAG === 'true'
                ? 'Can I set recurring transfers into my envelopes when money is deposited into my savings account?'
                : '',
            a: [
              'Yes, you can use Automatic Allocation under the ',
              BoldText('Allocation Settings'),
              ' tab on the ',
              BoldText('Account Envelopes'),
              ' screen.',
              Button('View full tutorial', '/article/allocation'),
            ],
          },
          {
            q: 'Is there a limit on the number of times I can add or remove money from my envelopes?',
            a:
              'There is no limit on how often you move money between your envelopes. And remember, you can create up to 12 savings envelopes per savings account.',
          },
        ],
      },
      {
        subtitle: 'Managing and Monitoring',
        questions: [
          {
            q: 'How do I view my BECU account and envelope summary?',
            a:
              'Your account and envelope summary resides on the homepage of BECU Envelopes. You can view a breakdown of each envelope by your savings accounts. Your account transaction will not be displayed on BECU Envelopes but will continue to be displayed on Online Banking.',
          },
          {
            q: 'How do I create additional envelopes?',
            a:
              'Select “Add Envelopes” and then the BECU savings account you would like for the envelope to be created under. Once you’ve chosen your BECU savings account, select the “Add Envelope” button, give your envelope a name and you can fund your new envelope with money from your selected savings account.',
          },
          {
            q: 'How do I delete my envelope?',
            a:
              'From your Account Envelopes page, next to each envelope, you can click the “ellipse” icon (the three dots) and select “Delete Envelope.” Once the envelope has been deleted, and if there are funds in that envelope, the funds will be distributed back into your Allocatable.',
          },
          {
            q: 'Will I be able to see my ”envelopes” in my BECU mobile banking app?',
            a:
              'For now, Envelopes is only accessible via Online Banking.',
          },
        ],
      },
      {
        subtitle: 'Multiple Accounts',
        questions: [
          {
            q: 'I have a joint savings account, thus will each account holder be able to access, view or edit the envelopes each of us sets up?',
            a: 'Yes, if you have a joint account, each account holder can access, view and edit the envelopes each account holder sets up.',
          },
        ],
      },
      {
        subtitle: 'Targets',
        questions: [
          {
            q: 'What is a target?',
            a: [
              ParagraphText('target-info','Target is a feature that lets you set an amount of money to save for in an envelope and set a date for when you want to achieve the savings goal. The math is done for you so you know the ongoing amount you will need to save in order to reach your goal.'),
              [BoldText('Note:'), ' You can only assign one target to an envelope at a time.'],
            ]
          },
          {
            q: 'How do I add a target?',
            a: [
              ParagraphText('add-target', 'You can add a target from the Account Envelopes screen after you create an envelope. To add a target:'),
              OrderedList('add-target-steps', [
                ['Select ', BoldText('Add Target')],
                ['Read the ', BoldText('Target Introduction'), ' instructions'],
                'Enter the amount of money you want to save',
                'Enter the target end date',
                'Confirm the information you entered',
                ['Select ', BoldText('Start Target')],
              ]),
              [BoldText('Note:'), ' You can assign one target to each envelope you have created.'],
            ],
          },
          {
            q: 'How do I track my progress?',
            a: [
              ParagraphText('target-track-overview', 'Each envelope with a target displays a progress bar that tracks your daily progress.  This gives you an at-a-glance report of where you\'re at now and how much you have left to save.'),
              TargetIntroductionImage(),
              ParagraphText('target-track-icon', 'The target icon on progress bar indicates the amount of money you needed to save by this point in time to reach your savings goal.'),
              ParagraphText('target-track-status', 'Your target status displays above the progress bar.  Depending on how much you\'ve saved toward your goal at that point in time, your target status shows as one of the following:'),
              UnOrderedList('target-statuses', false, [
                [BoldText('On Target:'), ' You have saved the amount of money needed by this point in time to reach your goal by the target date.'],
                [BoldText('Ahead of Target:'), ' You have saved more money than needed by this point in time to reach your goal by the target date.'],
                [BoldText('Behind Target:'), ' You have saved less money than needed by this point in time to reach your goal by the target date.  The progress bar changes color and you\'ll be offered suggestions to help you get back on track.'],
              ]),
            ],
          },
          {
            q: 'What happens when my target ends?',
            a: [
              ParagraphText('target-end-overview', ['When you reach your target end date, BECU Envelopes will notify you that you either ', BoldText('Reached'), ' or ', BoldText('Missed'), ' your savings goal.']),
              ParagraphText('target-end-select', ['Select ', BoldText('End Target'), ' and choose from one of the following options before ending your target:']),
              UnOrderedList('target-end-options', false, [
                [BoldText('Leave It:'), ' Keep the money in the envelope and end the target.'],
                [BoldText('Protect It:'), ' Keep the money in the envelope, move the envelope to the bottom of the withdrawal order and end the target.'],
                [BoldText('Spend It:'), ' Move the money from the envelope to Allocatable balance and end the target.'],
                [BoldText('Delete It:'), ' Delete the envelope and end the target. Any money in the envelope will return to the Allocatable balance.'],
              ]),
            ],
          },
        ],
      },
      {
        subtitle: 'Automatic Allocation',
        questions: [
          {
            q: 'What is Automatic Allocation?',
            a: 'Automatic Allocation allows you to automatically move (allocate) money to your envelopes on a regular basis. You can specify how frequently automatic allocation occurs.',
          },
          {
            q: 'Where can I find Automatic Allocation?',
            a: [
              'To set up automatic allocation for a specific account, navigate to the ',
              BoldText('Account Envelopes'),
              ' screen  for that account and select the ',
              BoldText('Allocation Settings'),
              ' tab near the top of the screen.',
            ],
          },
          {
            q: 'How do I specify when Automatic Allocation will occur?',
            a: [
              ParagraphText(
                'allocation-on',
                [BoldText('Note:'), ' If you set up automatic allocation to start today, it may not run until the next occurrence of your specified frequency and day.']
              ),
              ParagraphText('frequency', 'Frequency setting options:'),
              UnOrderedList('allocation-run', false, [
                [BoldText('Every Day:'), ' Automatic allocation will occur daily after the selected start date.'],
                [BoldText('Every Week:'), ' Automatic allocation will occur on the same day of the week after the selected start date. For example, if you select a Monday, automatic allocation will begin the next full Monday and will occur each week on Monday.'],
                [BoldText('Every Two Weeks:'), ' Automatic allocation will occur  on the same day, every other week, after the selected start date.  For example, if you select a Monday, automatic allocation will begin the next full Monday and will occur every other week on Monday.'],
                [BoldText('Every Month:'), ' Automatic allocation will occur on the same day of the month after the selected start date.  For example, if you select the first day of the month, automatic allocation will begin on the next full 1st and will occur on the first day of each month.'],
              ]),
            ],
          },
          {
            q: 'How do I specify how much money is automatically allocated to each envelope?',
            a: [
              ParagraphText('automatic-allocation-amount', 'You can set the automatic allocation amount in dollars or percentages.'),
              UnOrderedList('dollar-amount', true, [
                [BoldText('Using dollar amount:'), ' Enter the exact dollar amount to be moved from your Allocatable balance to the envelope each time, depending on how often you set the automatic allocation to occur (e.g., every day, every week, etc.).']
              ]),
              ParagraphText('dollar-amount-note', [BoldText('Note:'), ' Automatic allocation by dollar amount will only occur if all envelope automatic allocations can be funded in full.']),
              UnOrderedList('percentage-amount', true, [
                [BoldText('Using percentages:'), ' Enter the percent of your Allocatable balance to be moved to the envelope each time, depending on how often you set the automatic allocation to occur (e.g., every day, every week, etc.). You can specify 0% up to 100%, but the combined percentages for all envelopes cannot exceed 100%.']
              ]),
              [BoldText('Note:'), ' Automatic allocations that use percentage will always occur unless the Allocatable balance is less than $1.00.'],
            ],
          },
          {
            q: 'Can I stop or pause Automatic Allocation?',
            a: 'Yes. Use the toggle to turn off Automatic Allocation. Your settings will be saved and allocations will not occur until you toggle it back on.',
          },
        ],
      },
    ],
  },
  {
    title: 'Strategies & Ideas',
    topics: [
      {
        subtitle: '',
        questions: [
          {
            q: 'Why should I use BECU Envelopes? How will this help me?',
            a:
              "BECU envelopes can help you create and monitor multiple savings goals. The application helps you organize and track savings in one account. You don't need to have separate savings accounts to manage your goals, thus fewer accounts and account numbers to manage.",
          },
          {
            q: 'How is BECU envelopes different from nicknaming accounts?',
            a:
              'Your account nicknames will remain the same and you can continue to use nicknames accounts. When you nickname an account, it’s likely because you have multiple accounts with multiple account numbers. Envelopes allow you to visually divide up the money within the savings account without adding more accounts.',
          },
        ],
      },
    ],
  },
  {
    title: 'Providing Feedback',
    topics: [
      {
        subtitle: 'Feedback',
        questions: [
          {
            q: 'How do I provide feedback?',
            a:
              'Easily share feedback by clicking the feedback tab on the bottom right side of your browser page. A window will appear with a few questions to answer. We encourage you to be candid with your feedback. You can also ',
            feedbackModalLink: true,
          },
          {
            q: 'Why should I provide feedback?',
            a: 'Your feedback will help improve the final BECU Envelopes product so that it’s truly useful to BECU members.',
          },
        ],
      },
    ],
  },
];
