export const colors = {
  becuRed: '#d12a2e',
  harbor: '#007c89',
  ocean: '#006979',
  obsidian: '#192838',
  stone: '#4d5f69',
  snow: '#ffffff',
  cloud: '#f4f5f5',
  rain: '#eaebec',
  storm: '#d5d7d9',
  error: '#ab111a',
  amber: '#ffa300',
};

export const fontSize = {
  small: '0.786rem',
  normal: '1rem',
  big: '1.272rem',
};

export const margins = {};

export const padding = {
  small: 20,
  large: 40,
};

export const finHealthParagraph = {
  fontSize:'16', 
  fontWeight:300,
  color: '#4D5F69'
};