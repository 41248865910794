import {
  CREATE_AND_UPDATE_ENVELOPES_SUCCESS,
  CREATE_ENVELOPES_SUCCESS,
  DELETE_ENVELOPE_SUCCESS,
  GET_ENVELOPES_SUCCESS,
  GET_ENVELOPE_HISTORY,
  RENAME_ENVELOPE_SUCCESS,
  RESET_DELETE_ENVELOPE_SUCCESS,
  RESET_RENAME_ENVELOPE_SUCCESS,
  SAVE_ENVELOPES_FAILURE,
  PAGE_REFRESH_REQUIRED,
  SET_ENVELOPES_UPDATED,
  UPDATE_ENVELOPES_SUCCESS,
} from '../config/actionTypes';
import {API, getAuthHeader} from '../services/apiConfig';
import {handleGenericError} from '../services/errorConfig';
import {ACCOUNT_TYPE_SAVINGS, API_ERROR_MESSAGE, ENVELOPES_API, ENVELOPE_UPDATE, HISTORY_API} from '../config/constants';
import {tagAddMoney, tagCreateEnvelope, tagSubtractMoney} from 'services/gtm';
import {setApiError} from './ui';
import {getSchedule} from './envelopeSchedule';

export const getEnvelopes = () => (dispatch) => {
  API.get(ENVELOPES_API, {
    headers: getAuthHeader(),
    params: {AccountType: ACCOUNT_TYPE_SAVINGS},
  })
    .then((res) => {
      dispatch(success(res.data));
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
      if (err.response.status !== 401) {
        dispatch(setApiError(true, API_ERROR_MESSAGE));
      }
    });

  const success = (envelopes) => {
    return {
      type: GET_ENVELOPES_SUCCESS,
      payload: envelopes,
    };
  };
};

const makeNewEnvelopeChanges = (envelopes, editingAccount, updatedAllocatableValue) => {
  envelopes.forEach((envelope, index) => {
    updatedAllocatableValue -= envelope.balance;

    envelopes[index] = {
      ...envelopes[index],
      balance: envelope.balance + envelope.envelopeUpdateAmount,
      sortNumber: index,
    };

    tagCreateEnvelope(envelope, editingAccount, updatedAllocatableValue);
  });
};

const makeUpdateEnvelopeChanges = (envelopes, newEnvelopeLength, editingAccount, updatedAllocatableValue) => {
  envelopes.forEach((envelope, index) => {
    updatedAllocatableValue -= envelope.envelopeUpdateAmount;
    if (envelope.envelopeUpdateAmount > 0) {
      tagAddMoney(envelope, editingAccount, updatedAllocatableValue);
    } else if (envelope.envelopeUpdateAmount < 0) {
      tagSubtractMoney(envelope, editingAccount, updatedAllocatableValue);
    }

    envelopes[index] = {
      ...envelopes[index],
      balance: envelope.balance + envelope.envelopeUpdateAmount,
      sortNumber: envelope.sortNumber + newEnvelopeLength,
      updateType: ENVELOPE_UPDATE,
    };
  });
};

export const createEnvelopes = (newEnvelopes, editingAccount, updatedAllocatableValue) => (dispatch) => {
  makeNewEnvelopeChanges(newEnvelopes, editingAccount, updatedAllocatableValue);

  const body = {
    envelopeRequest: newEnvelopes,
    accountTransactionRequest: {
      accountNumber: editingAccount.accountNumber,
      type: ACCOUNT_TYPE_SAVINGS,
    },
  };

  API.post(ENVELOPES_API, body, {headers: getAuthHeader()})
    .then((response) => {
      if (process.env.REACT_APP_AA_FLAG === 'true') {
        dispatch(getSchedule(editingAccount.accountNumber));
      }
      dispatch(success(response.data.envelope[0]));
    })
    .catch((error) => {
      handleGenericError(error, dispatch);
      dispatch(setEnvelopesSavedFailed(true));
    });

  const success = (data, type) => {
    return {
      type: CREATE_ENVELOPES_SUCCESS,
      payload: {
        envelopes: data.envelope,
        unAllocatedAmount: data.unAllocatedAmount,
        accountNumber: editingAccount.accountNumber,
      },
    };
  };
};

export const updateAndCreateEnvelopes = (newEnvelopes, envelopesToUpdate, editingAccount, updatedAllocatableValue) => (dispatch) => {
  makeUpdateEnvelopeChanges(envelopesToUpdate, newEnvelopes.length, editingAccount, updatedAllocatableValue);

  const createBody = {
    envelopeRequest: newEnvelopes,
    accountTransactionRequest: {
      accountNumber: editingAccount.accountNumber,
      type: ACCOUNT_TYPE_SAVINGS,
    },
  };

  const updateBody = {
    envelopeRequest: envelopesToUpdate,
    accountTransactionRequest: {
      accountNumber: editingAccount.accountNumber,
      type: ACCOUNT_TYPE_SAVINGS,
    },
  };

  API.put(ENVELOPES_API, updateBody, {headers: getAuthHeader()})
    .then((updateRes) => {
      makeNewEnvelopeChanges(createBody.envelopeRequest, editingAccount, updatedAllocatableValue);
      API.post(ENVELOPES_API, createBody, {headers: getAuthHeader()})
        .then((createRes) => {
          if (process.env.REACT_APP_AA_FLAG === 'true') {
            dispatch(getSchedule(editingAccount.accountNumber));
          }
          dispatch(success(createRes.data.envelope[0], CREATE_AND_UPDATE_ENVELOPES_SUCCESS));
        })
        .catch((createError) => {
          handleGenericError(createError, dispatch);
          dispatch(setEnvelopesSavedFailed(true));
        });
    })
    .catch((updateError) => {
      handleGenericError(updateError, dispatch);
      dispatch(setEnvelopesSavedFailed(true));
    });

  const success = (data, type) => {
    return {
      type,
      payload: {
        envelopes: data.envelope,
        unAllocatedAmount: data.unAllocatedAmount,
        accountNumber: editingAccount.accountNumber,
      },
    };
  };
};

export const updateEnvelopes = (envelopes, editingAccount, updatedAllocatableValue, updateType = ENVELOPE_UPDATE, skipCheck = false) => (dispatch) => {
  if (updateType === ENVELOPE_UPDATE) {
    makeUpdateEnvelopeChanges(envelopes, 0, editingAccount, updatedAllocatableValue);
  }

  const body = {
    envelopeRequest: envelopes,
    accountTransactionRequest: {
      accountNumber: editingAccount.accountNumber,
      type: ACCOUNT_TYPE_SAVINGS,
    },
  };

  API.get(ENVELOPES_API, {
    headers: getAuthHeader(),
    params: {AccountType: ACCOUNT_TYPE_SAVINGS},
  })
    .then((res) => {
      const existingEnvs = res && res.data && res.data.envelope.find(x => x.accountNumber === editingAccount.accountNumber).envelope;
      if (updateType === ENVELOPE_UPDATE && !skipCheck && existingEnvs.length != envelopes.length) {
        dispatch(setPageRefreshError(true));
      } else {
        API.put(ENVELOPES_API, body, {headers: getAuthHeader()})
          .then((res) => {
            if (updateType === ENVELOPE_UPDATE) {
              dispatch(success(res.data.envelope[0]));
            }
          })
          .catch((err) => {
            handleGenericError(err, dispatch);
            dispatch(setEnvelopesSavedFailed(true));
          });
      }
  });

  const success = (data) => {
    return {
      type: UPDATE_ENVELOPES_SUCCESS,
      payload: {
        envelopes: data.envelope,
        unAllocatedAmount: data.unAllocatedAmount,
        accountNumber: editingAccount.accountNumber,
      },
    };
  };
};

export const renameEnvelope = (envelope, editingAccount, index) => (dispatch) => {
  const body = {
    envelopeRequest: envelope,
    accountTransactionRequest: {
      accountNumber: editingAccount.accountNumber,
      type: ACCOUNT_TYPE_SAVINGS,
    },
  };
  
  API.get(ENVELOPES_API, {
    headers: getAuthHeader(),
    params: {AccountType: ACCOUNT_TYPE_SAVINGS},
  })
    .then((res) => {
      const existingEnvs = res && res.data && res.data.envelope.find(x => x.accountNumber === editingAccount.accountNumber).envelope;
      const idFound = existingEnvs.find(x => x.envelopeId === envelope[0].envelopeId && x.sortNumber ===  envelope[0].sortNumber);
      if (!idFound)
      {
        dispatch(setPageRefreshError(true));
      } else 
      {
        API.put(ENVELOPES_API, body, {headers: getAuthHeader()})
        .then((res) => {
          dispatch(success());
        })
        .catch((err) => {
          handleGenericError(err, dispatch);
          dispatch(setEnvelopesSavedFailed(true));
        });
      }
  });

  const success = (newName) => {
    return {
      type: RENAME_ENVELOPE_SUCCESS,
      payload: {envelopeId: envelope[0].envelopeId, newName: envelope[0].name, index, accountNumber: editingAccount.accountNumber},
    };
  };
};

export const resetRenameSuccess = () => (dispatch) => {
  const reset = () => {
    return {
      type: RESET_RENAME_ENVELOPE_SUCCESS,
      payload: null,
    };
  };

  dispatch(reset());
};

export const deleteEnvelope = (envelopeId, accountNumber) => (dispatch) => {
  API.delete(ENVELOPES_API, {
    headers: getAuthHeader(),
    params: {envelopeId, accountNumber},
  })
    .then((res) => {
      if (process.env.REACT_APP_AA_FLAG === 'true') {
        dispatch(getSchedule(accountNumber));
      }
      dispatch(success(envelopeId, res.data.envelope[0], accountNumber));
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
    });

  const success = (envelopeDeleted, data, envelopeAccountNumber) => {
    return {
      type: DELETE_ENVELOPE_SUCCESS,
      payload: {envelopeDeleted, unAllocatedAmount: data.unAllocatedAmount, envelopeAccountNumber},
    };
  };
};

export const resetDeleteSuccess = () => (dispatch) => {
  const reset = () => {
    return {
      type: RESET_DELETE_ENVELOPE_SUCCESS,
      payload: null,
    };
  };

  dispatch(reset());
};

export const setEnvelopesUpdated = (updated) => (dispatch) => {
  const envelopesUpdated = (updated) => {
    return {
      type: SET_ENVELOPES_UPDATED,
      payload: updated,
    };
  };

  dispatch(envelopesUpdated(updated));
};

export const setEnvelopesSavedFailed = (saveFailed) => {
  return {
    type: SAVE_ENVELOPES_FAILURE,
    payload: saveFailed,
  };
};

export const setPageRefreshError = (refreshRequired) => {
  return {
    type: PAGE_REFRESH_REQUIRED,
    payload: refreshRequired,
  };
};

export const getEnvelopeHistory = (envelopeId, accountNumber, page = 1) => (dispatch) => {
  API.get(ENVELOPES_API + HISTORY_API, {
    headers: getAuthHeader(),
    params: {envelopeId, accountNumber, page}
  })
    .then((res) => {
      dispatch(success(res.data));
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
    });

  const success = (history) => {
    return {
      type: GET_ENVELOPE_HISTORY,
      payload: history,
    };
  };
};
