import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Col, Container, Row} from 'react-bootstrap';
import Flexbox from 'flexbox-react';
import EnvelopeHeader from 'components/app/Header';
import HomeBanner from 'components/home/HomeBanner';
import AlertBanner from 'components/app/AlertBanner';
import EnvelopeAccordion from 'components/envelopes/EnvelopeAccordion';
import FeedbackTab from 'components/app/FeedbackTab';
import PageMetaData from 'components/app/PageMetaData';
import Tutorials from 'components/home/Tutorials';
import {padding} from 'styles/config';
import {universalStyles} from 'styles/universalStyles';
import {LOGIN_PAGE, BUSINESSACCOUNT_PAGE, HOME_PAGE_TITLE} from '../../config/constants';

import {
  accountsSelector,
  activeEnvelopesSelector,
  authSelector,
  userSelector,
  sessionTokenSelector,
  intializedSelector,
  uiApiErrorSelector,
} from '../../selectors';
import {getAccounts, getEnvelopes, setSessionPath, getUser, loginWithToken, setApiError} from '../../actions';

const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loggedIn = useSelector(authSelector);
  const user = useSelector(userSelector);
  const sessionToken = useSelector(sessionTokenSelector);
  const currentEnvelopes = useSelector(activeEnvelopesSelector);
  const currentAccounts = useSelector(accountsSelector);
  const initialized = useSelector(intializedSelector);
  const apiError = useSelector(uiApiErrorSelector);

  useEffect(() => {
    if (!loggedIn && !sessionToken) {
      const path = {pathname: LOGIN_PAGE};
      dispatch(setSessionPath(path));
      history.replace(LOGIN_PAGE);
    } else if (!loggedIn && !initialized && sessionToken) {
      dispatch(loginWithToken());
    } else {
      dispatch(getUser());
      dispatch(getAccounts());
      dispatch(getEnvelopes());
    }

    if(user && user.isBusinessUser)
    {
      const path = {pathname: BUSINESSACCOUNT_PAGE};
      dispatch(setSessionPath(path));
      history.push(path);
    }
  }, [loggedIn, sessionToken, initialized, history]);

  const ApiErrorBanner = () => {
    return (
      <AlertBanner
        type={'danger'}
        name={'ApiError'}
        show={() => (apiError.visible ? apiError.visible : false)}
        message={apiError.message}
        onClose={() => dispatch(setApiError(false, ''))}
      />
    );
  };

  const Envelopes = () => {
    return (
      <div style={universalStyles.grayBackground}>
        <Container>
          <Row>
            <Col style={universalStyles.columnPadding}>
              <ApiErrorBanner />
              <EnvelopeAccordion currentAccounts={currentAccounts} currentEnvelopes={currentEnvelopes} user={user} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  if(user && user.isBusinessUser)
  {
    const path = {pathname: BUSINESSACCOUNT_PAGE};
    dispatch(setSessionPath(path));
    history.push(path);
  }

  return (
    <Flexbox flexDirection={'column'} style={universalStyles.container}>
      <PageMetaData pageTitle={HOME_PAGE_TITLE} />
      <EnvelopeHeader />
      <main id="main-content">
        <HomeBanner user={user} dispatch={dispatch} />
        {currentAccounts.length === 0 && (
          <Container>
            <Row>
              <Col style={{paddingTop: apiError.visible ? padding.large : 0}}>
                <ApiErrorBanner />
              </Col>
            </Row>
          </Container>
        )}
        {!user.isFirstTimeUser && currentAccounts.length !== 0 && <Envelopes />}
        <Tutorials />
      </main>
      <FeedbackTab />
    </Flexbox>
  );
};

export default Home;
