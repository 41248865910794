import {colors} from 'styles/config';

export const styles = {
  termsHeader: {
    fontWeight: 600,
    color: colors.obsidian,
  },
  termsBody: {
    marginBottom: '1rem',
  },
  pdfIcon: {
    marginRight: '.5rem',
  },
};
