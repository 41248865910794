// import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';

import rootReducer from './reducers/index';
import {loadState, saveState} from './services/sessionStorage';

//let middleware = [thunkMiddleware];

// Only load logger middleware if we are in DEV
//if (process.env.NODE_ENV !== 'production') {
//  const loggerMiddleware = createLogger();
//  middleware = [thunkMiddleware, loggerMiddleware];
//}

const presistedState = {...loadState()};

let store = createStore(rootReducer, presistedState, composeWithDevTools(applyMiddleware(thunk)));

store.subscribe(() => {
  if (store.getState().session !== presistedState?.session) {
    saveState(store.getState().session);
  }
});

export default store;
