import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Flexbox from 'flexbox-react';
import {Breadcrumb, Col, Container, Row} from 'react-bootstrap';
import { ArrowUp } from 'react-bootstrap-icons';
import {useMediaQuery} from 'react-responsive';
import PageMetaData from 'components/app/PageMetaData';
import EnvelopeHeader from 'components/app/Header';
import Subheader from 'components/app/Subheader';
import Navigation from 'components/articles/Navigation';
import ArticleSteps from 'components/articles/ArticleSteps';
import FeedbackTab from 'components/app/FeedbackTab';

import {universalStyles} from 'styles/universalStyles';
import { styles } from './styles';
import {HOME_PAGE, LOGIN_PAGE} from '../../config/constants';
import {authSelector, sessionTokenSelector, intializedSelector, mobileAppUserSelector} from '../../selectors';
import {loginWithToken, setSessionPath} from '../../actions';
import {ARTICLE_CREATE_DATA, ARTICLE_AUTOMATIC_ALLOCATION, ARTICLE_MOVE_MONEY_DATA, ARTICLE_ADD_REMOVE_MONEY} from '../../config/articles';

const Articles = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedIn = useSelector(authSelector);
  const initialized = useSelector(intializedSelector);
  const sessionToken = useSelector(sessionTokenSelector);
  const mobileAppUser = useSelector(mobileAppUserSelector);
  const articleType = history.location.pathname.split('/')[2];
  const [dataSource, setDataSource] = useState({});
  const stepRefs = useRef([]);
  const isLargeScreen = useMediaQuery({query: '(min-width: 768px)'});

  useEffect(() => {
    if (!loggedIn && !sessionToken) {
      dispatch(setSessionPath({pathname: LOGIN_PAGE}));
      history.replace(LOGIN_PAGE);
    } else if (!loggedIn && !initialized && sessionToken) {
      dispatch(loginWithToken());
    }
  }, [loggedIn, initialized]);

  useEffect(() => {
    if (articleType === 'allocation' && process.env.REACT_APP_AA_FLAG === 'false') {
      history.goBack();
    }
  }, [articleType]);

  useEffect(() => {
    switch (articleType) {
      case 'manage':
        setDataSource(ARTICLE_ADD_REMOVE_MONEY);
        break;
      case 'move':
        setDataSource(ARTICLE_MOVE_MONEY_DATA);
        break;
      case 'allocation':
        setDataSource(ARTICLE_AUTOMATIC_ALLOCATION);
        break;
      case 'create':
        setDataSource(ARTICLE_CREATE_DATA);
        break;
      default:
        history.goBack();
        break;
    }
  }, []);

  const getArticlePageTitle = () => {
    return `${dataSource?.header} | BECU Envelopes`;
  };

  const redirectToHomepage = () => {
    const path = { pathname: HOME_PAGE };
    dispatch(setSessionPath(path));
    history.push({ pathname: HOME_PAGE });
  };

  const handleStepClick = (index) => {
    if (stepRefs?.current && (index - 1) < stepRefs.current.length) {
      const element = stepRefs.current[index - 1];
      const yOffset = 10;
      const y = element.getBoundingClientRect().top;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      window.scrollTo({top: (y + scrollTop - yOffset), behavior: 'smooth'});
      element.focus();
    }
  };

  return (
    <Flexbox flexDirection={'column'} style={universalStyles.container}>
      <PageMetaData pageTitle={getArticlePageTitle()} />
      <EnvelopeHeader />
      <Subheader title={dataSource?.header} hideArrowButton={true} />
      <Container role={'main'} className={'regular mb-0'} id="main-content">
        {mobileAppUser &&
        <nav>      
        <div>
          <Breadcrumb listProps={{ style: styles.breadcrumbList }}>
            <>
              <Breadcrumb.Item onClick={() => redirectToHomepage()}>Home</Breadcrumb.Item>
              <Breadcrumb.Item active>{dataSource?.title}</Breadcrumb.Item>
            </>
          </Breadcrumb>
          </div>
          </nav>
        }
        <Row>
          {Navigation(dataSource, universalStyles.columnPadding, handleStepClick)}
          <Col md={{span: 9, order: 1}} className={'tutorial-steps'} style={universalStyles.columnPadding}>
            <h2 style={{marginBottom: '1.5rem'}}>{dataSource.title}</h2>
            {dataSource?.card_callout}
            {ArticleSteps(dataSource, universalStyles.columnPadding, stepRefs)}
            {!isLargeScreen &&
              <button
                id='BackToTop'
                className='link-button'
                onClick={() => {
                  const articleNav = document.getElementById('subheader');
                  articleNav.scrollIntoView();
                  articleNav.focus();
                }}
                style={{float: 'right', margin: 30, marginBottom: 0}}
              >
                <ArrowUp style={{marginRight: 12}} />
                Back to Top
              </button>
            }
          </Col>
        </Row>
      </Container>
      <FeedbackTab />
    </Flexbox>
  );
};

export default Articles;
