import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useMediaQuery} from 'react-responsive';
import Flexbox from 'flexbox-react';
import {Col, Container, Row} from 'react-bootstrap';
import PageMetaData from 'components/app/PageMetaData';
import EnvelopeHeader from 'components/app/Header';

import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';
import {LOGIN_PAGE} from 'config/constants';
import {authSelector, intializedSelector, sessionTokenSelector} from 'selectors';
import {loginWithToken, setSessionPath} from 'actions';
import businessAccount from '../../assets/images/business-account.svg';

const BusinessAccount = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedIn = useSelector(authSelector);
  const initialized = useSelector(intializedSelector);
  const sessionToken = useSelector(sessionTokenSelector);
  const isBigScreen = useMediaQuery({query: '(min-width: 576px)'});

  useEffect(() => {
    if (!loggedIn && !sessionToken) {
      dispatch(setSessionPath({pathname: LOGIN_PAGE}));
      history.replace(LOGIN_PAGE);
    } else if (!loggedIn && !initialized && sessionToken) {
      dispatch(loginWithToken());
    }
  }, [loggedIn, initialized]);

  return (
    <Flexbox flexDirection={'column'} style={universalStyles.container}>   
      <PageMetaData pageTitle={'Business Account'} />
      <EnvelopeHeader noHeaderLink /> 
      <Container role={'main'} id="main-content">
        <Row>
          <Col md={{span: 8, offset: 2}} style={{...universalStyles.columnPadding, textAlign: 'center'}}>
            <img
              src={businessAccount}
              alt={'business account'}
              style={{...styles.businessAccountImage, ...(isBigScreen ? styles.largerImage : styles.smallerImage)}}
            />
            <div><h1 style={{margin: '2rem 0 2rem'}}>BECU Envelopes is not available for Organization and Business deposit accounts.</h1></div>            
            
            <div><h2 style={styles.bodyText}>If you have a personal savings account, you can use BECU Envelopes by logging in with your personal User ID associated with your savings account.</h2></div>
            <h2 style={styles.bodyText}>Use the back arrow to return to the home screen.</h2>           
          </Col>
        </Row>
      </Container>
    </Flexbox>
  );
};

export default BusinessAccount;
