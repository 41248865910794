import React from 'react';
import {useSelector} from 'react-redux';
import {Card} from 'react-bootstrap';

import {styles} from './styles';
import { activeEnvelopesSelector, editingAccountSelector, envelopeScheduleSelector } from 'selectors';
import { ALLOCATION_TAB_KEY } from 'config/constants';

const TargetRecommendedActions = ({ envelope, toggleTargetModal, selectTab, toggleMoveMoneyModal }) => {
  const editingAccount = useSelector(editingAccountSelector);
  const currentEnvelopes = useSelector(activeEnvelopesSelector);
  const envelopeSchedule = useSelector(envelopeScheduleSelector);
  const editingAccountEnvelopes = currentEnvelopes.find((c) => c.accountNumber === editingAccount?.accountNumber);
  const editingEnvelopes = editingAccountEnvelopes && editingAccountEnvelopes.envelope !== null ? [...editingAccountEnvelopes.envelope] : [];

  const showTransferFunds = () => {
    const unallocatedAmount = editingAccountEnvelopes ? editingAccountEnvelopes.unAllocatedAmount : editingAccount?.availableBalance;
    const envelopesWithBalance = editingEnvelopes.filter((env) => env.balance > 0 && env.envelopeId !== envelope.envelopeId);
  
    return (unallocatedAmount !== 0 || envelopesWithBalance.length !== 0);
  };

  const showUpdateAA = () => {
    const activeEnvelopeSchedule = envelopeSchedule && envelopeSchedule.isActive;
    const envelopeDistributionAmount = envelopeSchedule?.envelope ? envelopeSchedule.envelope.find((env) => env.envelopeId === envelope.envelopeId).percentageDistributionAmount : 0;

    return (!activeEnvelopeSchedule || envelopeDistributionAmount === 0);
  };

  const options = [
    {
      title: 'Move money from other envelopes',
      subtitle: 'Move money from your allocatable balance or other envelopes to get closer to your target.',
      visible: () => showTransferFunds(),
      action: () => toggleMoveMoneyModal(true),
      label: 'Transfer funds to this envelope',
      id: 'Transfer-Money',
    },
    {
      title: 'Edit target',
      subtitle: 'Change the target date or target amount to re-evaluate your goal.',
      visible: () => false,
      action: () => toggleTargetModal(true),
      label: 'Edit current target',
      id: 'Edit-Current-Target',
    },
    {
      title: 'Update automatic allocation',
      subtitle: 'Schedule money to automatically move into this envelope.',
      visible: () => showUpdateAA(),
      action: () => selectTab(ALLOCATION_TAB_KEY),
      label: 'Navigate to Automatic Allocation tab',
      id: 'Update-Allocation',
    },
    {
      title: 'Learn more saving strategies from BECU',
      subtitle: 'Read about long and short term strategies to help you save more money.',
      visible: () => true,
      action: () => window.open('https://www.becu.org/articles/saving-money', '_blank'),
      label: 'External link to BECU saving strategy resources',
      id: 'BECU-Money-Strategies',
    },
  ];

  return (
    <Card style={styles.card}>
      <h3 id={'Behind-Target-Recommendation'}>Recommended Actions</h3>
      <ul className={'recommended-actions-list'} style={styles.recommendationList} role="list">
        {options.map((option, index) => {
          return (
            option.visible() ? (
              <li key={index} style={styles.listItem} role="listitem">
                <button id={option.id} className={'link-button stretched-link'} onClick={option.action} aria-label={option.label}>
                  {option.title}
                </button>
                <p>{option.subtitle}</p>
                {index < (options.length - 1) && <hr/>}
              </li>
            ) : null
          );
        })}
      </ul>
    </Card>
  );
};

export default TargetRecommendedActions;