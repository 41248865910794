import {GET_SCHEDULE, SET_ENVELOPE_SCHEDULE_UPDATED, UPDATE_SCHEDULE_ERROR, UPDATE_SCHEDULE_SUCCESS} from '../config/actionTypes';

const INITIAL_STATE = {
  schedule: {},
  saveFailed: false,
  envelopeScheduleUpdated: false,
};

export const envelopeSchedule = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SCHEDULE:
      return {
        ...state,
        schedule: action.payload,
      };
    case UPDATE_SCHEDULE_ERROR:
      return {
        ...state,
        saveFailed: true,
        envelopeScheduleUpdated: false,
      };
    case UPDATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        schedule: action.payload,
        saveFailed: false,
        envelopeScheduleUpdated: true,
      };
    case SET_ENVELOPE_SCHEDULE_UPDATED:
      return {
        ...state,
        envelopeScheduleUpdated: action.payload,
      };
    default:
      return state;
  }
};
