import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Col, Modal, Row} from 'react-bootstrap';
import ConfirmationModal from '../ConfirmationModal';

import {MANAGE_ENVELOPES_PAGE, SAVE_MODAL_DATA} from 'config/constants';
import {setEditingAccount, setSaveChangeModalStatus, setSessionPath, toggleAccountsModal} from '../../../actions';
import {colors} from 'styles/config';
import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';
import {tagSelectAccount} from 'services/gtm';
import {accountsSelector, editingAccountSelector, uiAccountsModalVisibleSelector, uiSaveChangeModalVisibleSelector} from 'selectors';
import {accountType} from 'types';
import {setActiveAccountNumber} from '../../../services/sessionStorage';

const AccountSelectionModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const accountsModalVisible = useSelector(uiAccountsModalVisibleSelector);
  const currentAccounts = useSelector(accountsSelector);
  const editingAccount = useSelector(editingAccountSelector);
  const unSaveChangesModalHidden = useSelector(uiSaveChangeModalVisibleSelector);
  const [saveChangeModalVisible, setSaveChangeModalVisible] = useState(false);
  const [saveChangeModalFunction, setSaveChangeModalFunction] = useState();

  useEffect(() => {
    if (accountsModalVisible) {
      window.onpopstate = (e) => {
        dispatch(toggleAccountsModal(false));
      };
    }
  }, [accountsModalVisible]);

  const selectAccount = (account) => {
    if (!unSaveChangesModalHidden) {
      setSaveChangeModalVisible(false);
      dispatch(setSaveChangeModalStatus(true));
    }
    dispatch(setEditingAccount(account));
    dispatch(toggleAccountsModal(false));

    tagSelectAccount(account, 'AccountSelectModal');
    setActiveAccountNumber(account.accountNumber);
    const path = {pathname: MANAGE_ENVELOPES_PAGE};
    dispatch(setSessionPath(path));
    history.push(path);
  };

  const selectionHandler = (account) => {
    if (editingAccount && editingAccount.accountNumber === account.accountNumber) {
      dispatch(toggleAccountsModal(false));
    } else if (unSaveChangesModalHidden) {
      selectAccount(account);
    } else {
      dispatch(toggleAccountsModal(false));
      const callback = () => selectAccount(account);
      setSaveChangeModalFunction(() => callback);
      setModalVisibleStatus(true);
    }
  };

  const setModalVisibleStatus = (status) => {
    setSaveChangeModalVisible(status);
  };

  const AccountTile = (props) => {
    const {account, index} = props;
    const title = account.accountNumber + ' * ' + (account.accountNickName ? account.accountNickName : account.accountType);

    return (
      <Row
        style={
          index < currentAccounts.length - 1
            ? {...styles.separator, margin: index !== 0 ? '1rem -12px 0' : '0 -12px'}
            : {margin: index !== 0 ? '1rem -12px 0' : '0 -12px'}
        }
      >
        <Col style={universalStyles.columnPadding}>
          <button name={'AccountSelectionTitle'} className={'link-button stretched-link'} style={{textAlign: 'left'}} onClick={() => selectionHandler(account)}>
            {title}
          </button>
          <div name="AvailableBalance">Available Balance ${account.availableBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
        </Col>
      </Row>
    );
  };

  AccountTile.propTypes = {
    account: accountType,
    index: PropTypes.number,
  };

  return (
    <>
      <Modal id={'AccountSelectionModal'} show={accountsModalVisible} onHide={() => dispatch(toggleAccountsModal(false))} scrollable>
        <Modal.Header closeButton>
          <Modal.Title style={universalStyles.modalHeader}>Select an Account</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{color: colors.stone}}>
          {currentAccounts.map((account, index) => {
            return <AccountTile key={index} account={account} index={index} />;
          })}
        </Modal.Body>
      </Modal>
      <ConfirmationModal
        modalData={SAVE_MODAL_DATA}
        modalFunction={saveChangeModalFunction}
        showModal={saveChangeModalVisible}
        setModalVisibleStatus={setModalVisibleStatus}
      />
    </>
  );
};

export default AccountSelectionModal;
