import React from 'react';
import {Card} from 'react-bootstrap';
import TargetSavingsTiles from 'components/targets/TargetSavingsTiles';

import {styles} from './styles';

const TargetSavingsCard = ({target, envelopeBalance}) => {

  return (
    <Card style={styles.card}>
      <TargetSavingsTiles saveAmount={target.targetAmount.toFixed(2)} saveByDate={target.targetEndDate} envelopeBalance={envelopeBalance} summaryStyle={'card'} />
    </Card>
  );
};

export default TargetSavingsCard;
