import {colors, fontSize} from 'styles/config';

export const styles = {
  tile: {
    position: 'absolute',
    left: 8,
    top: -8,
    height: 32,
    padding: '6px 16px',
    fontSize: fontSize.small,
    fontWeight: 600,
    borderRadius: '0 0 4px 0',
  },
  reorderTile: {
    position: 'absolute',
    left: '-0.5%',
    top: -8,
    width: '101%',
    height: 'auto',
    minHeight: 32,
    justifyContent: 'space-between',
    padding: '6px 16px',
    fontSize: fontSize.small,
    fontWeight: 600,
    borderRadius: '0.25rem',
  },
  withdrawImage: {
    width: 16,
    height: 16,
    marginTop: -4,
    marginRight: '0.25rem',
  },
  previousIndex: {
    fontWeight: 400,
    marginLeft: 4,
    color: colors.stone,
  },
  currentIndex: {
    color: colors.stone,
  },
};
