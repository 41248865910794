import React from 'react';
import {PropTypes} from 'prop-types';
import Flexbox from 'flexbox-react';
import {Button, ButtonGroup} from 'react-bootstrap';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faCaretUp} from '@fortawesome/free-solid-svg-icons';

import {colors} from 'styles/config';
import {styles} from './styles';
import withdraw from '../../../assets/images/icons/withdraw.svg';

const withdrawImage = <img src={withdraw} alt={'withdraw'} style={styles.withdrawImage} />;

const WithdrawOrderTile = ({currentIndex, previousIndex, numEnvelopes, reordering, draggableId}) => {
  const indexChanged = currentIndex !== previousIndex;
  const firstEnvelope = currentIndex === 1;
  const lastEnvelope = currentIndex === numEnvelopes;

  const move = (direction) => {
    const moveEvent = new CustomEvent('move', {detail: {draggableId, direction}});
    window.dispatchEvent(moveEvent);
  };

  return (
    <Flexbox
      alignItems={'center'}
      style={{
        ...(reordering ? styles.reorderTile : styles.tile),
        border: indexChanged ? `1px solid ${colors.harbor}` : '1px solid #b8bfc3',
        backgroundColor: indexChanged ? '#f2f8f9' : colors.cloud,
      }}
    >
      <Flexbox alignItems={'center'}>
        {withdrawImage}
        <div name="currentIndex" style={styles.currentIndex}>{`#${currentIndex}`}</div>
        {indexChanged && <div name="prevIndex" style={styles.previousIndex}>{`(was #${previousIndex})`}</div>}
      </Flexbox>
      {reordering && (
        <ButtonGroup style={{margin: '-6px -16px -6px 0'}}>
          <Button
            variant={'tertiary'}
            name="MoveUpArrow"
            className={'reorder-button' + (firstEnvelope ? ' btn-hide-pointer' : '')}
            style={{borderRadius: 0, minWidth: 44, minHeight: 44, marginRight: 2}}
            aria-disabled={firstEnvelope}
            disabled={firstEnvelope}
            onClick={() => move('up')}
          >
            <FontAwesomeIcon icon={faCaretUp} />
          </Button>
          <Button
            variant={'tertiary'}
            name="MoveDownArrow"
            className={'reorder-button' + (lastEnvelope ? ' btn-hide-pointer' : '')}
            style={{minWidth: 44, minHeight: 44, backgroundColor: '#B8bfc3'}}
            aria-disabled={lastEnvelope}
            disabled={lastEnvelope}
            onClick={() => move('down')}
          >
            <FontAwesomeIcon icon={faCaretDown} />
          </Button>
        </ButtonGroup>
      )}
    </Flexbox>
  );
};

WithdrawOrderTile.propTypes = {
  currentIndex: PropTypes.number,
  previousIndex: PropTypes.number,
  numEnvelopes: PropTypes.number,
  reordering: PropTypes.bool,
  draggableId: PropTypes.string,
};

export default WithdrawOrderTile;
