import {GET_ACCOUNTS_SUCCESS, SET_EDITING_ACCOUNT} from '../config/actionTypes';

const INITIAL_STATE = {
  currentAccounts: [],
  editingAccount: null,
  initialized: false,
};

export const accounts = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        currentAccounts: action.payload.accountInformation,
        initialized: true,
      };
    case SET_EDITING_ACCOUNT:
      return {
        ...state,
        editingAccount: action.payload,
      };
    default:
      return state;
  }
};
