import {colors} from 'styles/config';

export const styles = {
  headerWrapper: {
    position: 'sticky',
    top: 0,
    zIndex: 100,
    backgroundColor: colors.cloud,
    boxShadow: '0px 3px 3px #bcbfc2',
    padding: '16px 24px 0',
    marginLeft: -24,
    marginRight: -24,
    borderBottom: '1px solid #b8bfc3',
  },
  headerText: {
    fontSize: '1.272rem',
    fontWeight: 500,
    color: colors.obsidian,
    marginBottom: 0,
    maxWidth: '60%',
    overflow: 'hidden',
    hyphens: 'auto',
    wordBreak: 'break-word',
  },
  smallText: {
    fontSize: '80%',
    fontWeight: 400,
    color: colors.stone,
  },
  envelopeNameContainer: {
    overflow: 'hidden',
    paddingBottom: 30,
  },
  noTargetCard: {
    border: `1px solid ${colors.cloud}`,
    boxShadow: '0px 2px 2px #bcbfc2',
    padding: 24,
    marginBottom: 32,
  },
  noTargetTitle: {
    marginBottom: '1.5rem',
  },
  alertContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '16px -24px -16px',
    border: 0,
    borderRadius: 0,
  },
};
