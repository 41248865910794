import React, {useEffect, useRef, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import moment from 'moment';
import PropTypes from 'prop-types';
import Flexbox from 'flexbox-react';
import {Badge, Button, ButtonGroup, Card, Dropdown, Form, InputGroup} from 'react-bootstrap';
import WithdrawOrderTile from '../WithdrawOrderTile';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBullseye, faEnvelope, faPencilAlt, faTimesCircle, faTrash} from '@fortawesome/free-solid-svg-icons';

import {colors} from 'styles/config';
import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';
import {envelopeType} from 'types';
import {getNumWithCommas} from 'utils/envelopeDisplay';
import TargetTracker from 'components/targets/TargetTracker';
import {calculateAmountDiff, getTargetStatus, getTargetStatusLabel} from 'utils/targets';
import {TARGET_STATUS} from 'config/constants';
import dots from '../../../assets/images/icons/dots.svg';

const CustomDropdownToggle = React.forwardRef(({children, onClick, id, menuOpen, menuButtonEnabled, ...props}, ref) => (
  <button
    ref={ref}
    disabled={!menuButtonEnabled}
    id={id}
    aria-label={'Envelope options'}
    {...props}
    type={'button'}
    className={'link-button button-padding envelope-delete-ellipsis'}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </button>
));

CustomDropdownToggle.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

CustomDropdownToggle.displayName = 'Custom Dropdown Toggle';

const EnvelopeDropdown = ({ envelope, index, menuButtonEnabled, viewTarget, setAccessiblityValues, setRenameModal, setDeleteModal }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleId = `envelope-menu-${envelope.envelopeId}`;

  return (
    <Dropdown onToggle={() => setMenuOpen(!menuOpen)}>
      <Dropdown.Toggle
        ref={(refProps) => {
          if (refProps) {
            return { ...refProps };
          }
          return null;
        }}
        as={CustomDropdownToggle}
        id={toggleId}
        menuOpen={menuOpen}
        menuButtonEnabled={menuButtonEnabled}
      >
        <img          
          src={dots}
          style={{width: 24, opacity: !menuButtonEnabled ? 0.5 : 1}}
          alt={'envelope menu'}          
        />
      </Dropdown.Toggle>
      <Dropdown.Menu alignRight aria-labelledby={toggleId} role="menu" style={{ touchAction: 'manipulation' }}>
        {!envelope.target &&
          <Dropdown.Item
            role="menuitem"
            className={'envelope-target-icon dropdown-item-link'}
            aria-label={'Add Target'}
            onClick={() => {
              viewTarget(envelope);
              setAccessiblityValues(`Target-${envelope.envelopeId}`);
            }}
          >
            <FontAwesomeIcon icon={faBullseye} style={universalStyles.iconMarginRight} />
            Add Target
          </Dropdown.Item>
        }
        <Dropdown.Item
          role="menuitem"
          className={'envelope-target-icon dropdown-item-link'}
          aria-label={'Details and History'}
          onClick={() => {
            viewTarget(envelope);
            setAccessiblityValues(`Target-${envelope.envelopeId}`);
          }}
        >
        <FontAwesomeIcon icon={faEnvelope} style={universalStyles.iconMarginRight} />
          Details and History
        </Dropdown.Item>
        <Dropdown.Item
          role="menuitem"
          className={'envelope-rename-icon dropdown-item-link'}
          aria-label={'Rename Envelope'}
          onClick={() => {
            setRenameModal({visible: true, envelope, index});
            setAccessiblityValues(`Rename-${envelope.envelopeId}`);
          }}
        >
          <FontAwesomeIcon icon={faPencilAlt} style={universalStyles.iconMarginRight} />
          Rename Envelope
        </Dropdown.Item>
        <Dropdown.Item
          role="menuitem"
          className={'envelope-delete-icon dropdown-item-link'}
          aria-label={'Delete Envelope'}
          onClick={() => {
            setDeleteModal({visible: true, envelopeId: envelope.envelopeId, amount: envelope.balance});
            setAccessiblityValues(`Delete-${envelope.envelopeId}`);
          }}
        >
          <FontAwesomeIcon icon={faTrash} style={universalStyles.iconMarginRight} />
          Delete Envelope
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const EnvelopeCard = ({
  envelope,
  index,
  updateEnvelopeValues,
  setRenameModal,
  setDeleteModal,
  currentIndex,
  menuButtonEnabled,
  setAccessibilityLinkId,
  setOnFocus,
  viewTarget,
}) => {
  const [envelopeBalance, setEnvelopeBalance] = useState(envelope.envelopeUpdateAmount !== 0 ? Math.abs(envelope.envelopeUpdateAmount * 100) : '');
  const [newAmount, setNewAmount] = useState('');
  const inputRef = useRef(null);
  const isSmallScreen = useMediaQuery({query: '(max-width: 575px)'});
  const targetStatus = envelope.target ? getTargetStatus(envelope.target, envelope.balance) : null;

  useEffect(() => {
    const value = getNumWithCommas(parseFloat(envelope.balance + envelope.envelopeUpdateAmount).toFixed(2));
    setNewAmount(value[1] !== '-' ? value : value.slice(1, 2) + '$' + value.slice(2));
  }, [envelope.balance, envelope.envelopeUpdateAmount]);

  useEffect(() => {
    if (!envelope.active) {
      setEnvelopeBalance('');
      envelope.additionMultiplier = 0;
    }
  }, [envelope.active, envelope.additionMultiplier]);

  const getNewAmount = (valueToCheck, multiplierValue) => {
    const calculatedNewAmount = getNumWithCommas((parseFloat(envelope.balance) + multiplierValue * valueToCheck).toFixed(2));
    return calculatedNewAmount[1] !== '-' ? calculatedNewAmount : calculatedNewAmount.slice(1, 2) + '$' + calculatedNewAmount.slice(2);
  };

  const setAccessiblityValues = (id) => {
    setAccessibilityLinkId(id);
    setOnFocus('');
  };

  const handleParentUpdate = (invalidEntry = false) => {
    setNewAmount(getNewAmount(invalidEntry ? 0 : envelopeBalance, envelope.additionMultiplier));
    updateEnvelopeValues(index, envelopeBalance, envelope.additionMultiplier, invalidEntry);
  };

  const additionMultiplierButtonHandler = (multiplierValue) => {
    if (!envelope.active) {
      envelope.active = true;
    }
    envelope.additionMultiplier = multiplierValue;
    setNewAmount(getNewAmount(envelope.errors?.invalidEntry ? 0 : envelopeBalance, multiplierValue));
    updateEnvelopeValues(index, envelopeBalance, multiplierValue, envelope.errors?.invalidEntry);
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);
  };

  const undoChanges = () => {
    setEnvelopeBalance('');
    setNewAmount(getNewAmount(0, envelope.additionMultiplier));
    updateEnvelopeValues(index, 0, envelope.additionMultiplier, false, false);
  };

  const validInput = (value) => {
    setEnvelopeBalance(value);
    handleParentUpdate();
  };

  const formatInput = (value) => {
   return  envelope.additionMultiplier === 1 ?  ('+' + value) : ('-' + value);
  };

  return (
    <Card
      style={
        envelope.envelopeUpdateAmount ? {...universalStyles.envelopeCard.card, boxShadow: `0px 0px 4px ${colors.harbor}`} : universalStyles.envelopeCard.card
      }
    >
      <WithdrawOrderTile currentIndex={currentIndex} previousIndex={envelope.originalSort + 1} reordering={false} />
      <Card.Body style={!envelope.active ? styles.cardBody : styles.cardBodyActive}>
        <Flexbox justifyContent={'space-between'} alignItems={'center'} style={{marginBottom: 32}}>
          <h3 name="EnvelopeName" style={styles.envelopeName}>
            {envelope.name}
          </h3>
          <EnvelopeDropdown          
            envelope={envelope}
            index={index}
            menuButtonEnabled={menuButtonEnabled}
            viewTarget={viewTarget}
            setAccessiblityValues={setAccessiblityValues}
            setRenameModal={setRenameModal}
            setDeleteModal={setDeleteModal}
          />
        </Flexbox>
        {envelope.target && (
          <>
            <p style={{marginBottom: (targetStatus === TARGET_STATUS.COMPLETED || targetStatus === TARGET_STATUS.MISSED) ? '1rem' : 0}} name="targetStatusSummary">
              <strong>{getTargetStatusLabel(targetStatus)}</strong>
              {' '}
              {(targetStatus === TARGET_STATUS.BEHIND || targetStatus === TARGET_STATUS.AHEAD) && `by ${getNumWithCommas(calculateAmountDiff(envelope.balance, envelope.target))}`}
              {(targetStatus === TARGET_STATUS.COMPLETED || targetStatus === TARGET_STATUS.MISSED) &&
                <button
                  id={`CompleteTarget-${envelope.envelopeId}`}
                  className={'link-button'}
                  onClick={() => viewTarget(envelope, true)}
                >
                  End Target
                </button>
              }
            </p>
            {(targetStatus !== TARGET_STATUS.COMPLETED && targetStatus !== TARGET_STATUS.MISSED) && <TargetTracker status={targetStatus} target={envelope.target} envelopeBalance={envelope.balance} smallDisplay={true} />}
          </>
        )}
        <Flexbox justifyContent={'space-between'} alignItems={'center'} style={{marginBottom: !envelope.active ? (isSmallScreen ? 16 : 0) : 16}}>
          {envelope.target ? (
            <Badge
              variant={'light'}
              id={`targetSummary-${envelope.envelopeId}`}
              name="targetSummary"
              style={styles.clickableBadge}
              tabIndex={0}
              onClick={() => viewTarget(envelope, (targetStatus === TARGET_STATUS.COMPLETED || targetStatus === TARGET_STATUS.MISSED))}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  viewTarget(envelope, (targetStatus === TARGET_STATUS.COMPLETED || targetStatus === TARGET_STATUS.MISSED));
                }
              }}
            >
              <FontAwesomeIcon icon={faBullseye} style={universalStyles.iconMarginRight} />
              <span>
                {`${getNumWithCommas(envelope.target.targetAmount.toFixed(2))} by ${moment(envelope.target.targetEndDate).format('MM/DD/YYYY')}`}
              </span>
            </Badge>
          ) : (
            <button
              name="AddTargetButton"
              className={'link-button'}
              onClick={() => viewTarget(envelope)}
            >
              <FontAwesomeIcon icon={faBullseye} style={universalStyles.iconMarginRight} />
              Add Target
            </button>
          )}
          <h2 style={{marginBottom: 0}} name="Balance" aria-label={`Balance: ${getNumWithCommas(envelope.balance.toFixed(2))}`}>
            {getNumWithCommas(envelope.balance.toFixed(2))}
          </h2>
        </Flexbox>
        <div style={!envelope.active ?  (isSmallScreen ? styles.envelopeControlsMobile : styles.envelopeControls) : {...styles.envelopeControlsActive, flexWrap: isSmallScreen ? 'wrap' : 'inherit'}}>
          
          <InputGroup.Prepend
            style={
              envelope.active
                ? isSmallScreen
                  ? {marginBottom: 16, width:'100%'}
                  : {marginRight: 16, width:'100%', marginBottom: envelope.errors?.invalidEntry || newAmount[0] === '-' ? '1.4rem' : 0}
                : null
            }
          >   
            <ButtonGroup id={`EnvelopeControls-${envelope.envelopeId}`} aria-label={'Envelope Controls'} style={isSmallScreen ? {width: '100%',  flexDirection: 'column'} : null}>
              <Button
                aria-label={'Add money to envelope'}
                id={`AddBalanceButton-${envelope.envelopeId}`}
                name="AddFunds"
                variant={'secondary'}
                className={envelope.additionMultiplier === 1 && 'secondary-button-selected'}
                style={ isSmallScreen ?  {...styles.addButton, width: '100%', marginBottom: '5px'} :styles.addButton}
                onClick={() => additionMultiplierButtonHandler(1)}
              >Add Money
              </Button>
              <Button
                aria-label={'Remove money from envelope'}
                id={`SubtractBalanceButton-${envelope.envelopeId}`}
                name="RemoveFunds"
                variant={'secondary'}
                className={envelope.additionMultiplier === -1 && 'secondary-button-selected'}
                style={isSmallScreen ?  {...styles.removeButton, width: '100%'} :styles.removeButton}
                onClick={() => additionMultiplierButtonHandler(-1)}
              >Remove Money
              </Button>
            </ButtonGroup>
          </InputGroup.Prepend>
          <InputGroup style={{flexGrow: 1, display: !envelope.active ? 'none' : 'flex'}}>
            <InputGroup.Prepend>
              <span style={universalStyles.form.dollarSpan}>$</span>
            </InputGroup.Prepend>
            <Form.Control
              id={`EnvelopeBalanceInput-${envelope.envelopeId}`}
              name="NewBalanceInput"
              aria-label={`Dollar amount`}
              aria-describedby={`EnvelopeControls-${envelope.envelopeId}`}
              ref={inputRef}
              type={'text'}
              inputMode={'decimal'}
              maxLength={18}
              value= {formatInput(envelopeBalance)}
              style={{
                ...universalStyles.form.inputContainer,
                textAlign: 'right',
                borderColor: newAmount[0] !== '-' ? colors.stone : '#dc3545',
                borderRadius: 0,
              }}
              onChange={(e) => {
                const regex = /[\d.]*/;
                const value = e.target.value.replace(/[a-zA-Z$\-,\s+-]/g, '').match(regex);
                if (value !== null) {
                  setEnvelopeBalance(value[0]);
                }
              }}
              onFocus={(e) => {
                var val = e.target.value;
                e.target.value = '';
                e.target.value = val;
              }}
              onBlur={(e) => {
                const regex = /^\d*\.?\d{1,2}$/;
                const value = e.target.value.replace(/^0{2,}/g, 0).replace(/^0{2,}|\$|,|-/g, '').replace(/[+-]/g , '');
                if (value.match(regex) !== null) {
                  validInput(parseFloat(value).toFixed(2));
                } else {
                  if (value !== '') {
                    handleParentUpdate(true);
                  } else {
                    validInput('');
                  }
                }
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13 || e.keyCode === 32) {
                  e.preventDefault();
                  inputRef.current.blur();
                }
              }}
              isInvalid={envelope.errors?.invalidEntry || newAmount[0] === '-'}
            />
            <InputGroup.Append>
              <Button
                aria-label={'Cancel amount change'}
                id={`CancelAmountChange-${envelope.envelopeId}`}
                name="CancelAmountChange"
                variant={'secondary'}
                style={styles.cancelButton}
                onClick={undoChanges}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </Button>
            </InputGroup.Append>
            <Form.Control.Feedback type={'invalid'} role={'alert'} style={{textAlign: 'right'}}>
              {newAmount[0] === '-' ? 'New amount cannot be negative. Please decrease the amount to remove.' : 'Please enter a valid amount.'}
            </Form.Control.Feedback>
          </InputGroup>
        </div>
      </Card.Body>
      {envelope.active && (
        <Card.Footer
          style={{
            borderTop: 'none',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div> New Amount </div>
          <div name="NewBalance" style={{...styles.newAmount, color: newAmount[0] !== '-' ? colors.obsidian : '#ab111a'}}>
            {newAmount}
          </div>
        </Card.Footer>
      )}
    </Card>
  );
};

EnvelopeCard.propTypes = {
  envelope: envelopeType,
  index: PropTypes.number,
  updateEnvelopeValues: PropTypes.func,
  setDeleteModal: PropTypes.func,
  menuButtonEnabled: PropTypes.bool,
  currentIndex: PropTypes.number,
};

export default EnvelopeCard;
