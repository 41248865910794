import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {tagAccountOpen, tagSelectAccount} from 'services/gtm';
import {setActiveAccountNumber} from 'services/sessionStorage';

import Flexbox from 'flexbox-react';
import {Accordion, Badge, Button, Card} from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import {useAccordionToggle} from 'react-bootstrap/AccordionToggle';
import InfoTip from 'components/app/InfoTip';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp, faEnvelope} from '@fortawesome/free-solid-svg-icons';

import {MANAGE_ENVELOPES_PAGE} from 'config/constants';
import {setEditingAccount, setSessionPath} from 'actions';
import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';
import {envelopeType, accountType} from 'types';
import {colors} from 'styles/config';

const EnvelopeAccordion = ({currentAccounts, currentEnvelopes, user}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeAccordion, setActiveAccordion] = useState('');

  const selectAccount = (account, triggerLocation) => {
    dispatch(setEditingAccount(account));
    tagSelectAccount(account, triggerLocation);
    setActiveAccountNumber(account.accountNumber);
    const path = {pathname: MANAGE_ENVELOPES_PAGE};
    dispatch(setSessionPath(path));
    history.push(path);
  };

  const EnvelopeList = ({envelopes, account}) => {
    return (
      <>
        {!envelopes || envelopes.envelope === null ? (
          <div>
            <div style={{marginBottom: 24}}>No Envelopes here.</div>
            <Button
              id={`AddEnvelopesButton-${account.accountNumber}`}
              variant={'primary'}
              style={universalStyles.noSetWidth}
              onClick={() => selectAccount(account, 'NoEnvelopesButton')}
            >
              Add Envelopes
            </Button>
          </div>
        ) : (
          <Flexbox flexWrap={'wrap'} className={'envelope-columns'}>
            {envelopes.envelope.map((envelope, index) => {
              return (
                <Card key={index} style={styles.envelopeCard}>
                  <Card.Body
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '1.25rem',
                    }}
                  >
                    <button
                      id={'EnvelopeNameButton'}
                      className={'link-button'}
                      style={styles.envelopeButton}
                      onClick={() => selectAccount(account, 'EnvelopeCard')}
                    >
                      {envelope.name}
                    </button>
                    <div style={styles.envelopeBalance}>
                      ${envelope && envelope.balance ? envelope.balance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0.00'}
                    </div>
                  </Card.Body>
                </Card>
              );
            })}
          </Flexbox>
        )}
      </>
    );
  };

  EnvelopeList.propTypes = {
    envelopes: PropTypes.exact({
      accountNumber: PropTypes.string,
      unAllocatedAmount: PropTypes.number,
      envelope: PropTypes.arrayOf(envelopeType),
    }),
    account: accountType,
  };

  const CustomAccordionToggle = ({account, eventKey, callback, toggleActiveAccordion}) => {
    const currentEventKey = useContext(AccordionContext);
    const isCurrentEventKey = currentEventKey === eventKey;

    const decoratedOnClick = useAccordionToggle(eventKey, () => {
      if (!isCurrentEventKey) {
        tagAccountOpen(account, user);
        toggleActiveAccordion(eventKey);
      } else {
        toggleActiveAccordion('');
      }
      callback && callback(eventKey);
    });

    return (
      <button
        id={`AccordionToggle-${account.accountNumber}`}
        aria-expanded={isCurrentEventKey}
        aria-controls={'#Card-' + account.accountNumber}
        aria-label={isCurrentEventKey ? 'Close account display' : 'Open account display'}
        className={'link-button'}
        style={{width: 50}}
        onClick={decoratedOnClick}
      >
        <FontAwesomeIcon icon={isCurrentEventKey ? faChevronUp : faChevronDown} />
      </button>
    );
  };

  return (
    <Accordion id="HomepageAccountList" defaultActiveKey={currentAccounts.length === 1 ? '0' : null}>
      {currentAccounts.map((account, index) => {
        const accordionTitle = account.accountNumber + ' * ' + (account.accountNickName ? account.accountNickName : account.accountType);
        const availableBalance = `Available Balance $${account.availableBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
        const envelopesByAcount = currentEnvelopes.find((c) => c.accountNumber === account.accountNumber);
        const numberOfEnvelopesByAcount = envelopesByAcount && envelopesByAcount.envelope !== null ? envelopesByAcount.envelope.length : 0;
        const allocatableAmountByAccount = envelopesByAcount ? envelopesByAcount.unAllocatedAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0.00';
        const ariaText =
          (account.accountNickName ? account.accountNickName : account.accountType) +
          ` ${numberOfEnvelopesByAcount} envelopes available select to add or remove envelopes`;
        return (
          <Card key={index} style={{...styles.accordionCardWrapper, borderBottom: (index !== currentAccounts.length - 1) ? 0 : `1px solid ${colors.storm}`}}>
            <Card.Header className={'accordion-header'} style={{...styles.accordionCardHeader, backgroundColor: (index.toString() === activeAccordion) ? colors.snow : colors.cloud}}>
              <Flexbox alignItems={'center'}>
                <Badge variant={'primary'} style={{fontWeight: 600, marginRight: '1rem'}} aria-label={`${numberOfEnvelopesByAcount} envelope${numberOfEnvelopesByAcount === 1 ? '' : 's'}`}>
                  <FontAwesomeIcon icon={faEnvelope} style={universalStyles.iconMarginRight} />
                  {numberOfEnvelopesByAcount}
                </Badge>
                <div>
                  <button
                    className={'link-button'}
                    aria-label={ariaText}
                    style={{textAlign: 'left'}}
                    onClick={() => selectAccount(account, 'AccountAccordion')}
                  >
                    {accordionTitle}
                  </button>
                  <div name="availableBalance" style={styles.availableBalance}>
                    {availableBalance}
                  </div>
                </div>
              </Flexbox>
              <CustomAccordionToggle account={account} eventKey={index.toString()} toggleActiveAccordion={setActiveAccordion} />
            </Card.Header>
            <Accordion.Collapse
              eventKey={index.toString()}
              style={{backgroundColor: colors.cloud}}
              role="region"
              aria-labelledby={`AccordionToggle-${account.accountNumber}`}
              id={'Card-' + account.accountNumber}
            >
              <Card.Body style={{padding: '0 1.25rem 1.25rem', backgroundColor: colors.snow}}>
                <div style={styles.allocatableAmountWrapper}>
                  <div id={`AllocatableBalance-${account.accountNumber}`} style={styles.allocatableAmount}>
                    ${allocatableAmountByAccount}
                  </div>
                  <div>
                    Allocatable
                    <InfoTip
                      index={index}
                      popoverId={account.accountNumber}
                      ariaLabel={'View allocatable tooltip'}
                      content={'Funds available to distribute to Envelopes.'}
                      imgName={'question'}
                    />
                  </div>
                </div>
                <EnvelopeList envelopes={envelopesByAcount} account={account} />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        );
      })}
    </Accordion>
  );
};

EnvelopeAccordion.propTypes = {
  currentAccounts: PropTypes.arrayOf(accountType),
  currentEnvelopes: PropTypes.arrayOf(envelopeType),
};

export default EnvelopeAccordion;
