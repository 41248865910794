import {GET_ACCOUNTS_SUCCESS, SET_EDITING_ACCOUNT} from '../config/actionTypes';
import {API, getAuthHeader} from '../services/apiConfig';
import {handleGenericError} from '../services/errorConfig';
import {ACCOUNTS_API, ACCOUNT_TYPE_SAVINGS, API_ERROR_MESSAGE} from '../config/constants';
import {setApiError} from './ui';

export const getAccounts = () => (dispatch) => {
  API.get(ACCOUNTS_API, {
    headers: getAuthHeader(),
    params: {AccountType: ACCOUNT_TYPE_SAVINGS},
  })
    .then((res) => {
      dispatch(success(res.data));
    })
    .catch((err) => {
      handleGenericError(err, dispatch);
      if (err.response.status !== 401) {
        dispatch(setApiError(true, API_ERROR_MESSAGE));
      }
    });

  const success = (accounts) => {
    return {
      type: GET_ACCOUNTS_SUCCESS,
      payload: accounts,
    };
  };
};

export const setEditingAccount = (account) => (dispatch) => {
  const editingAccount = (account) => {
    return {
      type: SET_EDITING_ACCOUNT,
      payload: account,
    };
  };

  dispatch(editingAccount(account));
};
