import React, { useRef, useState } from 'react';
import {useSelector} from 'react-redux';
import {editingAccountSelector, userSelector} from 'selectors';
import PropTypes from 'prop-types';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import {useMediaQuery} from 'react-responsive';

import {styles} from './styles';
import {tagEngagesWithAllocatableTooltip, tagEngagesWithChangeOrderTooltip} from 'services/gtm';

const InfoTip = ({index, popoverId, content, ariaLabel, imgName, allocatableAmount = 0}) => {
  const editingAccount = useSelector(editingAccountSelector);
  const user = useSelector(userSelector);
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const isSmallScreen = useMediaQuery({query: '(max-width: 575px)'});

  const handleClick = (toggle) => {
    setShow(toggle);
    setTimeout(() => {
      const popoverElement = document.getElementById(`InfoTipOverlay-${popoverId}`);
      if (popoverElement && toggle) {
        popoverElement.focus();
      } else {
        setTimeout(() => {
          const buttonElement = document.getElementById(`tooltip-button-${popoverId}`);
          buttonElement.blur();
        }, 100);
      }
    }, 100);
    
  };

  return (
    <div style={{display: 'inline'}} ref={ref}>
      <OverlayTrigger
        key={index}
        trigger={isSmallScreen ? 'click' : ['click', 'hover']}
        placement={'top'}
        show={show}
        overlay={
          <Popover
            id={`InfoTipOverlay-${popoverId}`}
            className='tooltip-popover'
            tabIndex={-1}
            onKeyDown={(e) => {
              if (e.keyCode === 27) {
                setShow(false);
                const buttonElement = document.getElementById(`tooltip-button-${popoverId}`);
                buttonElement.focus();
              }
            }}
          >
            <Popover.Content>{content}</Popover.Content>
          </Popover>
        }
        container={ref.current}
        onToggle={handleClick}
      >
        <button
          id={`tooltip-button-${popoverId}`}
          type={'button'}
          className={'link-button'}
          style={styles.toggleButton}
          aria-label={ariaLabel}
          aria-describedby={show ? `InfoTipOverlay-${popoverId}` : undefined}
          onClick={() => {
            switch (popoverId) {
              case 'Withdrawal':
                tagEngagesWithChangeOrderTooltip(editingAccount, user, allocatableAmount);
                break;
              case 'Allocatable':
                tagEngagesWithAllocatableTooltip(editingAccount, user, allocatableAmount);
                break;
              default:
                break;
            }
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 27) {
              setShow(false);
            }
          }}
        >
          <img id={`InfoTipToggle-${popoverId}`} style={styles.toggleIcon} src={require(`../../../assets/images/icons/${imgName}.svg`)} alt={`${imgName} icon`} />
        </button>
      </OverlayTrigger>
    </div>
  );
};

InfoTip.propTypes = {
  index: PropTypes.number,
  popoverId: PropTypes.string,
  content: PropTypes.node,
  ariaLabel: PropTypes.string,
  imgName: PropTypes.string,
  allocatableAmount: PropTypes.number,
};

export default InfoTip;
