import {
    CREATE_FINANCIAL_GOAL_SUCCESS,
    CREATE_FINANCIAL_TOPIC_SUCCESS,
    DELETE_FINANCIAL_GOAL_SUCCESS,
    DELETE_FINANCIAL_TOPIC_SUCCESS,
    GET_FINANCIAL_GOAL_SUCCESS,
    GET_FINANCIAL_TOPIC_SUCCESS,
  } from '../config/actionTypes';
  
  const INITIAL_STATE = {
    financialGoal: [],
    financialTopic: [],
    financialGoalAdded: false,
    financialTopicAdded: false,
    financialGoalDeleted: null,
    financialTopicDelete: null,
  };

  export const financialHealth = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case GET_FINANCIAL_GOAL_SUCCESS:
        return {
          ...state,
          financialGoal: action.payload,
        };
      case GET_FINANCIAL_TOPIC_SUCCESS:
        return {
          ...state,
          financialTopic: action.payload,
        };
        case CREATE_FINANCIAL_GOAL_SUCCESS:
        return {
          ...state,
          financialGoalAdded: action.payload,
        };
        case CREATE_FINANCIAL_TOPIC_SUCCESS:
        return {
          ...state,
          financialTopicAdded: action.payload,
        };
        case DELETE_FINANCIAL_GOAL_SUCCESS:
          return {
            ...state,
            financialGoalDeleted: {
              deletedId: action.payload.financialGoalId,
              financialGoal: action.payload.financialGoal,
            },
          };
          case DELETE_FINANCIAL_TOPIC_SUCCESS:
          return {
            ...state,
            financialTopicDelete: {
              deletedId: action.payload.financialProfileAnswerId,
              financialTopic: action.payload.financialTopic,
            },
          };
      default:
        return state;
    }
  };
  