import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Modal} from 'react-bootstrap';
import FocusLock from 'react-focus-lock';

import {colors} from 'styles/config';
import {universalStyles} from 'styles/universalStyles';
import {setSurveyMonkeyFormModal} from '../../../actions';
import {uiSurveyMonkeyFormModalSelector} from 'selectors';

const SurveyMonkeyModal = () => {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const surveyMonkeyFormModal = useSelector(uiSurveyMonkeyFormModalSelector);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (visible && surveyMonkeyFormModal.visible) {
      window.onpopstate = (e) => {
        hideModal();
      };
    }
  }, [visible, surveyMonkeyFormModal.visible]);

  useEffect(() => {
    if (formRef.current && surveyMonkeyFormModal.visible && !visible) {
      let o, a, c;
      const link = surveyMonkeyFormModal?.modal?.surveyLink;
      window.SMCX = window.SMCX || [];
      o = document.getElementById('smForm');
      a = o;
      c = document.createElement('script');
      c.type = 'text/javascript';
      c.async = !0;
      c.id = 'smcx-sdk';
      c.src = link;
      a.parentNode.insertBefore(c, a);
      setVisible(true);
    }
  }, [surveyMonkeyFormModal, visible]);

  const hideModal = () => {
    dispatch(setSurveyMonkeyFormModal(false, null));
    setVisible(false);
  };

  return (
    <Modal id={`SurveyMonkeyModal-${surveyMonkeyFormModal?.modal?.type}`} show={surveyMonkeyFormModal.visible} onHide={() => hideModal()} size={'lg'}>
      <FocusLock>
        <Modal.Header closeButton>
          <Modal.Title style={universalStyles.modalHeader}>{surveyMonkeyFormModal?.modal?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{color: colors.stone}}>
          <div style={{marginBottom: '1rem'}}>{surveyMonkeyFormModal?.modal?.body}</div>
        </Modal.Body>
        <div style={{...universalStyles.fullWidth, height: '50vh'}}>
          <div ref={formRef} id={'smForm'} />
        </div>
      </FocusLock>
    </Modal>
  );
};

export default SurveyMonkeyModal;
