import {LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT_SUCCESS} from '../config/actionTypes';

const INITIAL_STATE = {
  loggedIn: false,
  authError: null,
};

export const auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_FAILURE:
      return {
        ...state,
        authError: action.payload,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: action.payload,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        loggedIn: action.payload,
      };
    default:
      return state;
  }
};
