import {colors} from 'styles/config';

export const styles = {
  trackerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: colors.cloud,
    padding: '24px 0',
    margin: '-1rem -1rem 0',
    borderRadius: '4px 4px 0 0',
  },
  trackerItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  currentStep: {
    position: 'relative',
    height: 24,
    width: 24,
    backgroundColor: colors.snow,
    border: `1px solid ${colors.harbor}`,
    borderRadius: '100%',
    marginTop: 6,
    marginBottom: 8,
  },
  step: {
    position: 'relative',
    backgroundColor: colors.cloud,
    marginTop: 6,
    marginBottom: 8,
  },
  inactiveStep: {
    position: 'relative',
    marginTop: 6,
    marginBottom: 8,
  },
  trackerLine: {
    position: 'absolute',
    top: 40,
    height: 4,
    backgroundColor: colors.storm,
  },
  filledTrackerLine: {
    height: 4,
    backgroundColor: colors.harbor,
    transition: 'width 0.5s linear',
  },
};
