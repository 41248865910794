import React from 'react';
import {Alert} from 'react-bootstrap';

import {styles} from './styles';
import warning from '../../../assets/images/icons/warning.svg';

export const NoEnvelopesAlert = ({showAlert, alertFunction}) => {
  return (
    <Alert role='status' id="Settings-NoEnvelopes" variant={'warning'} show={showAlert} style={{...styles.alertContainer, backgroundColor: '#ffeccc'}}>
      <img src={warning} style={{marginRight: 16}} aria-hidden='true' />
      <div className={'alert-no-envelope'}>
        No envelopes yet.{' '}
        <button id={'btn-create-envelope-tab'} className={'link-button'} onClick={() => alertFunction('envelopes')}>
          Create one on the Envelopes tab
        </button>{' '}
        to enable all settings and features.
      </div>
    </Alert>
  );
};
