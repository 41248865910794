import {CREATE_TARGET_SUCCESS, DELETE_TARGET_SUCCESS, END_TARGET_SUCCESS, SAVE_TARGET_FAILURE, UPDATE_TARGET_SUCCESS} from 'config/actionTypes';

const INITIAL_STATE = {
  targetCreated: false,
  targetDeleted: false,
  targetUpdated: false,
  targetEnded: false,
  saveFailed: false,
};

export const targets = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_TARGET_SUCCESS:
      return {
        ...state,
        targetCreated: action.payload,
      };
    case DELETE_TARGET_SUCCESS:
      return {
        ...state,
        targetDeleted: action.payload,
      };
    case END_TARGET_SUCCESS:
      return {
        ...state,
        targetEnded: action.payload,
      };
    case SAVE_TARGET_FAILURE:
      return {
        ...state,
        saveFailed: action.payload,
      };
    case UPDATE_TARGET_SUCCESS:
      return {
        ...state,
        targetUpdated: action.payload,
      };
    default:
      return state;
  }
};
