import {colors, padding} from './config';

export const universalStyles = {
  // APP
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: colors.snow,
  },
  fullWidth: {
    width: '100%',
  },
  grayBackground: {
    backgroundColor: colors.cloud,
  },
  noBottomMargin: {
    marginBottom: 0,
  },
  // BOOTSTRAP
  columnPadding: {
    paddingTop: padding.large,
    paddingBottom: padding.large,
  },
  smallColumnPadding: {
    paddingTop: padding.small,
    paddingBottom: padding.small,
  },
  // BUTTON
  noSetWidth: {
    width: 'auto',
  },
  // CARD
  envelopeCard: {
    card: {
      marginBottom: 32,
      border: `1px solid ${colors.cloud}`,
      boxShadow: '0px 2px 2px #bcbfc2',
      minWidth: 200,
    },
  },
  //  FORM
  form: {
    inputLabel: {
      fontSize: '0.786em',
      fontWeight: 600,
      color: colors.obsidian,
    },
    inputContainer: {
      border: `1px solid ${colors.stone}`,
      borderRadius: '0.25rem',
      height: 44,
    },
    dollarSpan: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: '0.375rem 0.75rem',
      backgroundColor: '#f4f5f5',
      color: '#495057',
      border: '1px solid #4d5f69',
      borderRadius: '0.25rem',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  // ICONS
  iconMarginRight: {
    marginRight: 8,
  },
  // MODAL
  modalHeader: {
    fontSize: '1.25rem',
    fontWeight: 500,
    color: colors.stone,
    lineHeight: 1.5,
  },
  // SHADOW
  dropShadow: {
    boxShadow: '0px 3px 3px #bcbfc2',
  },
};
