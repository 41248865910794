import React, {useState, useEffect} from 'react';
import {PropTypes} from 'prop-types';
import {Alert} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {styles} from './styles';

const AlertBanner = ({show, name, message, type, icon, iconPlacement, dismissible, template, onClose, imgName, alertStyle}) => {
  const [visible, setVisible] = useState(show);
  useEffect(() => {
    setVisible(show);
  }, [show]);

  const classes = styles();

  const exitAlert = () => {
    setVisible(false);
    if (onClose !== undefined) {
      onClose();
    }
  };

  if (visible) {
    return (
      <Alert id={name} onClose={exitAlert} dismissible={dismissible} className={classes[type]} style={alertStyle}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: iconPlacement}}>
          {imgName ? (
            <img style={{marginRight: 16}} src={require(`../../../assets/images/icons/${imgName}.svg`)} aria-hidden='true' />
          ) : (
            <FontAwesomeIcon icon={icon} style={{marginTop: iconPlacement === 'flex-start' ? 4 : 0}} aria-hidden='true' />
          )}
          <div>
            {message}
            {template ? template : ''}
          </div>
        </div>
      </Alert>
    );
  } else {
    return '';
  }
};

AlertBanner.propTypes = {
  show: PropTypes.func,
  onClose: PropTypes.func,
  name: PropTypes.string,
  message: PropTypes.string,
  icon: PropTypes.object,
  iconPlacement: PropTypes.string,
  template: PropTypes.object,
  dismissible: PropTypes.bool,
  type: PropTypes.string, // Options: info, danger, warning
  alertStyle: PropTypes.object,
};

AlertBanner.defaultProps = {
  message: 'Error',
  name: 'error',
  show: () => false,
  icon: faInfoCircle,
  iconPlacement: 'center',
  type: 'info',
  dismissible: true,
  imgName: '',
  alertStyle: {},
};

export default AlertBanner;
