import {colors} from 'styles/config';

export const styles = {
  card: {
    border: `1px solid ${colors.cloud}`,
    boxShadow: '0px 2px 2px #bcbfc2',
    padding: 24,
    marginBottom: 32,
  },
  noHistory: {
    marginTop: 12,
    marginBottom: 0,
  },
};
