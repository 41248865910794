import {colors, fontSize} from 'styles/config';

export const styles = {
  countdownText: {
    fontWeight: 500,
    fontSize: fontSize.big,
    color: colors.obsidian,
    marginBottom: '0.5rem',
  },
};
