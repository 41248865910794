import React, {useRef, useState} from 'react';
import moment from 'moment';
import Flexbox from 'flexbox-react';
import {Col, Form, InputGroup, Row} from 'react-bootstrap';
import TargetSavingsTiles from '../TargetSavingsTiles';
import { TargetEndDateMatchesToday, TargetLessThanStartAmountAlert, TargetMatchesBalanceAlert } from '../TargetAlerts';
import DatePicker from 'react-datepicker';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendar} from '@fortawesome/free-solid-svg-icons';

import { colors } from 'styles/config';
import {universalStyles} from 'styles/universalStyles';
import {getNumWithCommas} from 'utils/envelopeDisplay';

// TODO: Refactor target modal + combine onBlur into common function
const TargetSetup = ({
  totalSaveAmount,
  setTotalSaveAmount,
  saveByDate,
  setSaveByDate,
  saveByDateDisplay,
  setSaveByDateDisplay,
  envelopeBalance,
  setDisableContinue,
  updatingTarget,
  startAmount,
  closeAndDelete,
  targetMatchesAmount,
  setTargetMatchesAmount,
  targetLessThanStartAmount,
  setTargetLessThanStartAmount,
  targetEndDateMatchesToday,
  setTargetEndDateMatchesToday,
}) => {
  const amountInputRef = useRef(null);
  const [totalSaveInputError, setTotalSaveInputError] = useState(false);
  const [validDate, setValidDate] = useState(true);
  const [updatedSaveAmount, setUpdatedSaveAmount] = useState(totalSaveAmount);
  const [updatedSaveByDate, setUpdatedSaveByDate] = useState(saveByDate);
  const datePickerRef = useRef(null);
  const appendRef = useRef(null);

  const checkDisableContinue = (value) => {
    if ((!updatingTarget && value <= envelopeBalance) || !validDate) {
      setDisableContinue(true);
    } else if (validDate) {
      setDisableContinue(false);
    }
  };

  const checkValidity = (endDate) => {
    const dateIsValid = (updatingTarget) ? moment().isSameOrBefore(endDate, 'day') : moment().isBefore(endDate);

    setValidDate(dateIsValid);
    if (!dateIsValid) {
      setDisableContinue(true);
    } else if (totalSaveAmount > envelopeBalance || (updatingTarget && totalSaveAmount > startAmount)) {
      setDisableContinue(false);
    }

    if (updatingTarget && moment().isSame(endDate, 'day')) {
      setTargetEndDateMatchesToday(true);
    }

    if (endDate.isValid() === false) {
      setUpdatedSaveAmount(null);
    } else {
      setUpdatedSaveByDate(new Date(endDate));
    }
  };

  const dateInteract = () => {
    if (datePickerRef && datePickerRef.current) {
      datePickerRef.current.input.focus();
    }
  };

  return (
    <div>
      <div style={{paddingTop: 40, paddingBottom: 16}}>
        <h2>Target Details</h2>
      </div>
      <Row>
        <Col md={6} style={{paddingTop: 16, paddingBottom: 16}}>
          <h3>Amount</h3>
          <p id="targetInfoStartingAmount">
            Your Envelope already has <strong>{getNumWithCommas(parseFloat(envelopeBalance).toFixed(2))}</strong>
          </p>
          <Form.Group controlId={'TargetSaveAmount'}>
            <Form.Label style={universalStyles.form.inputLabel}> What is the total amount to save? </Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <span style={universalStyles.form.dollarSpan}>$</span>
              </InputGroup.Prepend>
              <Form.Control
                ref={amountInputRef}
                inputMode={'decimal'}
                maxLength={18}
                value={totalSaveAmount}
                style={{...universalStyles.form.inputContainer, borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                onChange={(e) => {
                  const regex = /[\d.]*/;
                  const value = e.target.value.replace(/[a-zA-Z$\-,\s]/g, '').match(regex);
                  if (value !== null) {
                    setTotalSaveAmount(value[0]);
                  }
                }}
                onBlur={(e) => {
                  const regex = /^\d*\.?\d{1,2}$/;
                  const value = e.target.value.replace(/^0{2,}/g, 0).replace(/^0{2,}|\$|,|-/g, '');
                  if (value.match(regex) !== null) {
                    const newValue = parseFloat(value).toFixed(2);
                    setTotalSaveAmount(newValue);
                    setUpdatedSaveAmount(newValue);
                    checkDisableContinue(newValue);
                    setTotalSaveInputError((!updatingTarget && newValue <= envelopeBalance) || (updatingTarget && newValue <= startAmount));
                    setTargetMatchesAmount(updatingTarget && newValue <= envelopeBalance && newValue > startAmount);
                    setTargetLessThanStartAmount(updatingTarget && newValue <= startAmount);
                  } else {
                    if (value !== '') {
                      setUpdatedSaveAmount(0);
                      checkDisableContinue(0);
                      setTotalSaveInputError(true);
                    } else {
                      setTotalSaveAmount(value);
                      setUpdatedSaveAmount(value);
                      checkDisableContinue(value);
                      setTotalSaveInputError((!updatingTarget && value <= envelopeBalance) || (updatingTarget && value <= startAmount));
                      setTargetMatchesAmount(updatingTarget && value <= envelopeBalance && value > startAmount);
                      setTargetLessThanStartAmount(updatingTarget && value <= startAmount);

                    }
                  }
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13 || e.keyCode === 32) {
                    e.preventDefault();
                    amountInputRef.current.blur();
                  }
                }}
                isInvalid={totalSaveInputError}
              />
              <Form.Control.Feedback type={'invalid'} role={'alert'}>
                {updatingTarget && totalSaveAmount <= startAmount ? (
                  `Amount to save must be greater than your Target's Starting Amount of ${getNumWithCommas(startAmount.toFixed(2))}`
                ) : (totalSaveAmount <= envelopeBalance
                  ? 'Amount to save must be greater than the amount your Envelope already has.'
                  : 'Please enter a valid amount.'
                )}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col md={6} style={{paddingTop: 16, paddingBottom: 16}}>
          <h3>Duration</h3>
          {!updatingTarget ? (
            <p id="targetInfoStartingDate">
              You are starting on <strong>{moment().format('MM/DD/YYYY')}</strong>
            </p>
          ) : (
            <div style={{height: 24, marginBottom: '1rem'}} />
          )}
          <Form.Group controlId={'date'}>
            <Form.Label style={universalStyles.form.inputLabel}> When do you need this saved by? </Form.Label>
            <InputGroup>
              <Flexbox style={{width: '100%'}}>
                <Form.Control
                  as={DatePicker}
                  ref={datePickerRef}
                  minDate={updatingTarget ? new Date(moment().add(1, 'd')) : new Date()}
                  selected={saveByDate ? saveByDate : null}
                  value={saveByDateDisplay}
                  onChange={(endDate, e) => {
                    if (e && (e.type === 'click' || (e.type === 'keydown' && e.keyCode === 13))) {
                      if (endDate) {
                        setSaveByDate(endDate);
                        setSaveByDateDisplay(moment(endDate).format('MM/DD/YYYY'));
                        checkValidity(moment(endDate));
                        appendRef.current.focus();
                      }
                    } 
                  }}
                  onBlur={(e) => {
                    const endDate = moment(e.target.value);

                    if (endDate.isValid() === false) {
                      setSaveByDate(null);
                      setUpdatedSaveAmount(null);
                    } else {
                      setSaveByDate(new Date(endDate));
                      setSaveByDateDisplay(endDate.format('MM/DD/YYYY'));
                    }

                    checkValidity(endDate);
                    appendRef.current.focus();
                  }}
                  onChangeRaw={(e) => {
                    if (!e.target.value || e.target.value === undefined) {
                      setSaveByDateDisplay(e.target.value);
                      return;
                    }

                    const dateInput = e.target.value.replace(/[^(\d|/)]/g, '');
                    setSaveByDateDisplay(dateInput);
                  }}
                  isInvalid={!validDate}
                  className={'input-height-44'}
                />
                <InputGroup.Append>
                  <button
                    ref={appendRef}
                    id={'OpenCalendar'}
                    aria-label={'Open Calendar'}
                    type={'button'}
                    className={'append-button'}
                    onClick={() => dateInteract()}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 || e.keyCode === 32) {
                        e.preventDefault();
                        dateInteract();
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faCalendar} color={colors.stone} />
                  </button>
                </InputGroup.Append>
              </Flexbox>
              <Form.Control.Feedback id={'invalid-date'} type={'invalid'} role={'alert'} style={{textAlign: 'right', display: (!validDate) ? 'inline-block' : 'none'}}>
                {"Please enter a valid date (mm/dd/yyyy) that is after today's date."}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col md={12} style={{paddingTop: 16, paddingBottom: 16}}>
          <TargetMatchesBalanceAlert showAlert={targetMatchesAmount} setAlert={setTargetMatchesAmount} />
          <TargetLessThanStartAmountAlert showAlert={targetLessThanStartAmount} setAlert={setTargetLessThanStartAmount} closeAndDelete={closeAndDelete} />
          <TargetEndDateMatchesToday showAlert={targetEndDateMatchesToday} setAlert={setTargetEndDateMatchesToday} />
          {!(updatingTarget && (updatedSaveAmount <= envelopeBalance || updatedSaveAmount <= startAmount || moment().isSame(moment(saveByDate), 'day'))) &&
            <TargetSavingsTiles saveAmount={updatedSaveAmount} saveByDate={updatedSaveByDate} envelopeBalance={envelopeBalance} summaryStyle={'tile'} updatingTarget={updatingTarget} />
          }
        </Col>
      </Row>
    </div>
  );
};

export default TargetSetup;
