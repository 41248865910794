export const styles = {
  businessAccountImage: {
    minWidth: 40,
    verticalAlign: 'middle',
    borderStyle: 'none',
  },
  smallerImage: {
    maxWidth: 200,
  },
  largerImage: {
    maxWidth: 250,
  },
  bodyText: {
    margin: '1rem 0 2rem', 
    color: '#4D5F69', 
    textAlign: 'center',
  },
  liText: {
    padding: '5px 10px'
  },
  ulText: {
    textAlign: 'Left',
    fontWeight: 400,
    fontSize: '16px',
  }
};
