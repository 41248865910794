import React from 'react';
import Flexbox from 'flexbox-react';
import {Button, Card} from 'react-bootstrap';
import TargetTracker from '../TargetTracker';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons';

import {styles} from './styles';
import { universalStyles } from 'styles/universalStyles';
import {TARGET_STATUS} from 'config/constants';
import {getNumWithCommas} from 'utils/envelopeDisplay';
import {calculateAmountDiff, getTargetStatus, getTargetStatusLabel} from 'utils/targets';

const TargetProgressCard = ({target, envelopeBalance, toggleDeleteModal, targetStatusRef}) => {
  const targetStatus = getTargetStatus(target, envelopeBalance);

  return (
    <Card style={styles.card}>
      <Flexbox alignItems={'center'} justifyContent={'space-between'}>
        <h2 tabIndex={-1} ref={targetStatusRef} id="targetStatus">
          {getTargetStatusLabel(targetStatus)}
          {(targetStatus === TARGET_STATUS.BEHIND || targetStatus === TARGET_STATUS.AHEAD) && (
            <>
              <br />
              <span className={'small-text'}>by {getNumWithCommas(calculateAmountDiff(envelopeBalance, target))}</span>
            </>
          )}
        </h2>
        <div>
          <Button id="DeleteTarget" aria-label="Delete Target" variant={'secondary'} onClick={() => toggleDeleteModal(true)} style={universalStyles.noSetWidth}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      </Flexbox>
      <TargetTracker status={targetStatus} target={target} envelopeBalance={envelopeBalance} smallDisplay={false} />
    </Card>
  );
};

export default TargetProgressCard;
