export const getEnvelopeValue = (value) => {
  let parts = (value / 100).toFixed(2).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return Number(parts.join('.').replace(/,/g, ''));
};

export const getNumWithCommas = (value) => {
  let parts = value.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return '$' + parts.join('.');
};
