import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FocusLock from 'react-focus-lock';
import {Button, Form, Modal} from 'react-bootstrap';

import {universalStyles} from 'styles/universalStyles';
import {validateNewEnvelope} from 'modules/ManageEnvelopes/validation';
import {renameEnvelope, setFirstTimeUser} from 'actions';
import { userSelector } from 'selectors';

const RenameEnvelopeModal = ({renameModal, setRenameModal, renamedWithSameName, envelopes, editingAccount}) => {
  const [name, setName] = useState('');
  const [errors, setErrors] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const nameInputRef = useRef(null);

  useEffect(() => {
    if (renameModal.envelope) {
      setName(renameModal.envelope.name);
      setErrors([]);
    }
  }, [renameModal]);

  const updateNewEnvelopeName = (index, name) => {
    const envelopesCopy = [...envelopes];
    envelopesCopy[index] = {
      ...envelopesCopy[index],
      name,
    };
    setErrors(validateNewEnvelope(renameModal.index, envelopesCopy, true));
  };

  const onSubmit = () => {
    const updateEnvelope = [
      {
        ...renameModal.envelope,
        name,
      },
    ];

    if (user.isFirstTimeUser) {
      dispatch(setFirstTimeUser(false));
    }

    if (name === renameModal.envelope?.name) {
      renamedWithSameName();
    } else {
      setRenameModal({visible: false});
      dispatch(renameEnvelope(updateEnvelope, editingAccount, renameModal.index));
    }
  };

  return (
    <Modal id={'Rename-Modal'} show={renameModal.visible} onHide={() => setRenameModal({visible: false})}>
      <FocusLock>
        <Modal.Header closeButton>
          <Modal.Title style={universalStyles.modalHeader}>Rename Envelope</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId={`EnvelopeNameInput-${renameModal.envelope?.envelopeId}`}>
              <Form.Label style={universalStyles.form.inputLabel}> Envelope Name </Form.Label>
              <Form.Control
                required
                type={'text'}
                value={name}
                ref={nameInputRef}
                onChange={(e) => {
                  setName(e.target.value);
                  updateNewEnvelopeName(renameModal.index, e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    if (errors.length === 0) {
                      onSubmit();
                    }
                  }
                }}
                style={universalStyles.form.inputContainer}
                isInvalid={errors.length > 0}
              />
              <Form.Control.Feedback type={'invalid'}>
                {errors.map((error, index) => (
                  <div role={'alert'} key={'e' + index}>
                    {error}
                  </div>
                ))}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer style={{justifyContent: 'center'}}>
          <Button
            className={`envelope-confirmation-button ${errors.length > 0 && 'btn-hide-pointer'}`}
            variant="primary"
            style={{width: '100%'}}
            onClick={onSubmit}
            disabled={errors.length > 0}
            aria-disabled={errors.length > 0}
          >
            Save
          </Button>
          <Button className={'envelope-cancel-button'} variant="secondary" style={{width: '100%'}} onClick={() => setRenameModal({visible: false})}>
            Cancel
          </Button>
        </Modal.Footer>
      </FocusLock>
    </Modal>
  );
};

export default RenameEnvelopeModal;
