import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useMediaQuery} from 'react-responsive';
import moment from 'moment';
import isEqual from 'lodash.isequal';
import Flexbox from 'flexbox-react';
import {Badge, Button, Col, Form, InputGroup, Row, Table} from 'react-bootstrap';
import AllocationInput from '../AllocationInput';
import {NoEnvelopesAlert} from '../AllocationAlerts';
import {SaveSuccessfulAllocationAlert} from 'components/envelopes/EnvelopeAlerts';
import AlertBanner from 'components/app/AlertBanner';
import DatePicker from 'react-datepicker';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendar, faSave, faInfoCircle} from '@fortawesome/free-solid-svg-icons';

import {colors} from 'styles/config';
import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';
import {getSchedule, updateSchedule, setSaveChangeModalStatus, setEnvelopesScheduleUpdated, setFirstTimeUser} from 'actions';
import {envelopeScheduleSelector, updateEnvelopeScheduleSelector} from 'selectors';
import {ALLOCATION_TAB_KEY} from '../../../config/constants';
import {OVER_MAX_ALLOCATION} from 'config/errorMessaging';
import {tagAllocationSettings, tagAllocationToggle} from 'services/gtm';
import InfoTip from 'components/app/InfoTip';
import { getNumWithCommas } from 'utils/envelopeDisplay';

const TILES = [
  {
    title: 'Automatic Allocation',
    body: 'Automatically allocate money to your envelopes so you can effortlessly contribute to your savings goals.',
    type: 'automatic-allocation',
  },
];

const frequencyOptions = [
  {
    label: 'Every Day',
    value: 'Day',
  },
  {
    label: 'Every Week',
    value: 'Week',
  },
  {
    label: 'Every Two Weeks',
    value: 'Biweekly',
  },
  {
    label: 'Every Month',
    value: 'Month',
  },
];

const AllocationSettings = ({editingAccount, user, alertFunction, tabKey, allocatableAmount}) => {
  const dispatch = useDispatch();
  const schedule = useSelector(envelopeScheduleSelector);
  const scheduleSuccessful = useSelector(updateEnvelopeScheduleSelector);
  const [envelopeSchedule, setEnvelopeSchedule] = useState({envelope: [], frequencyType: '', startDate: null, isActive: false, isDollarAllocation: true});
  const [frequencyLabel, setFrequencyLabel] = useState('');
  const [overallPercent, setOverallPercent] = useState(0);
  const [overallDollarAmount, setOverallDollarAmount] = useState(0);
  const [noEnvelopesAlert, setNoEnvelopesAlert] = useState(false);
  const [validDate, setValidDate] = useState(true);
  const [dateDisplay, setDateDisplay] = useState('');
  const [allocationSavedBanner, setAllocationSavedBanner] = useState(false);
  const isBigScreen = useMediaQuery({query: '(min-width: 576px)'});
  const datePickerRef = useRef(null);
  const appendRef = useRef(null);
  const envelopeNameCellRef = useRef([]);

  useEffect(() => {
    if (allocationSavedBanner) {
      const test = setTimeout(() => {
        updateEnvelopeSchedule();
      }, 10000);

      return () => {
        clearTimeout(test);
      };
    }
  }, [allocationSavedBanner]);

  useEffect(() => {
    if (scheduleSuccessful) {
      setAllocationSavedBanner(true);
    }
  }, [scheduleSuccessful]);

  useEffect(() => {
    if (editingAccount || (editingAccount && tabKey && tabKey == ALLOCATION_TAB_KEY)) {
      dispatch(getSchedule(editingAccount.accountNumber));
      updateEnvelopeSchedule();
    }
  }, [editingAccount, tabKey]);

  useEffect(() => {
    let percentage = 0;
    let dollarAmount = 0;
    let date = '';

    if (schedule && schedule.envelope) {
      schedule.envelope.forEach((env) => {
        percentage += env.percentageDistributionAmount;
        dollarAmount += env.dollarDistributionAmount;
      });
      setOverallPercent(percentage);
      setOverallDollarAmount(dollarAmount);
      setNoEnvelopesAlert(false);
    } else {
      setOverallPercent(0);
      setOverallDollarAmount(0);
      setNoEnvelopesAlert(true);
    }

    if (schedule.startDate) {
      date = new Date(moment(schedule.startDate));
    } else {
      date = new Date();
    }
    setDateDisplay(moment(date).format('MM/DD/YYYY'));

    setEnvelopeSchedule({...schedule, startDate: date});
  }, [schedule]);

  useEffect(() => {
    const labelIndex = frequencyOptions
      .map((frequency) => {
        return frequency.value;
      })
      .indexOf(envelopeSchedule.frequencyType);
    const newFrequency = frequencyOptions[labelIndex];

    if (labelIndex !== -1) {
      setFrequencyLabel(' ' + newFrequency.label.toLowerCase());
    }
  }, [envelopeSchedule.frequencyType]);

  useEffect(() => {
    dispatch(setSaveChangeModalStatus(saveDisabled()));
  }, [envelopeSchedule, schedule, dateDisplay, validDate]);

  const scheduleUnchanged = () => {
    const startDate = schedule.startDate !== null ? moment(schedule.startDate).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY');
    return (
      schedule.isActive === envelopeSchedule.isActive &&
      schedule.isDollarAllocation === envelopeSchedule.isDollarAllocation &&
      schedule.frequencyType === envelopeSchedule.frequencyType &&
      startDate === dateDisplay &&
      isEqual(schedule.envelope, envelopeSchedule.envelope)
    );
  };

  const saveDisabled = () => {
    return scheduleUnchanged() || envelopeSchedule.frequencyType === '' || !validDate || overallPercent > 100;
  };

  const saveAllocationSettings = () => {
    if (!saveDisabled()) {
      tagAllocationSettings(envelopeSchedule, (envelopeSchedule.isDollarAllocation) ? overallDollarAmount : overallPercent, editingAccount, user);
      dispatch(updateSchedule(envelopeSchedule, editingAccount.accountNumber));
      dispatch(setSaveChangeModalStatus(true));

      if (user.isFirstTimeUser) {
        dispatch(setFirstTimeUser(false));
      }
    }
  };

  const AllocationHeader = () => (
    <Flexbox justifyContent={'space-between'} alignItems={'center'}>
      <Flexbox flexDirection={'row'} flexWrap={'wrap'} alignItems={'center'}>
        <div id="AllocatableBalance" style={{...styles.allocatableAmount, color: allocatableAmount >= 0 ? colors.obsidian : '#ab111a'}}>
          {(allocatableAmount >= 0 ? '$' : '-$') +
            Math.abs(allocatableAmount)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </div>
        <div style={styles.allocatableLabel}>
          Allocatable
          <InfoTip
            ariaLabel={'View allocatable tooltip'}
            index={0}
            popoverId={'Allocatable'}
            content={'Funds available to distribute to Envelopes.'}
            imgName={'question'}
            allocatableAmount={allocatableAmount}
          />
        </div>
      </Flexbox>
      <Button
        id={'SaveSettingChangesButton'}
        style={{...styles.saveChangesButton, width: isBigScreen ? 180 : 80}}
        aria-disabled={saveDisabled()}
        disabled={saveDisabled()}
        onClick={saveAllocationSettings}
        className={saveDisabled() ? 'btn-hide-pointer' : null}
      >
        <FontAwesomeIcon icon={faSave} style={universalStyles.iconMarginRight} />
        {isBigScreen ? 'Save Changes' : 'Save'}
      </Button>
    </Flexbox>
  );

  const updateEnvelopeValue = (value, index) => {
    const envelopeCopy = [...envelopeSchedule.envelope];

    if (envelopeSchedule.isDollarAllocation) {
      let newDollarAmount = 0;
      envelopeCopy[index] = {...envelopeCopy[index], dollarDistributionAmount: value};
      envelopeCopy.forEach((env) => {
        newDollarAmount += env.dollarDistributionAmount;
      });
      setOverallDollarAmount(newDollarAmount);
    } else {
      let newPercentage = 0;
      envelopeCopy[index] = {...envelopeCopy[index], percentageDistributionAmount: value};
      envelopeCopy.forEach((env) => {
        newPercentage += env.percentageDistributionAmount;
      });
      setOverallPercent(newPercentage);
    }

    updateEnvelopeSchedule();
    setEnvelopeSchedule({...envelopeSchedule, envelope: envelopeCopy});
  };

  const updateEnvelopeSchedule = () => {
    setAllocationSavedBanner(false);
    dispatch(setEnvelopesScheduleUpdated(false));
  };

  const dateInteract = () => {
    if (datePickerRef && datePickerRef.current) {
      datePickerRef.current.input.focus();
    }
  };

  const AllocationTile = (index, title, body, type) => (
    <Row style={styles.allocationTile} key={index}>
      <Col xl={'7'} style={{paddingTop: 40}}>
        <div>
          <h3>{title}</h3>
          <p>{body}</p>
        </div>
        <Flexbox alignItems={'center'} style={{marginBottom: 16}}>
          <div className={'custom-control custom-switch settings-switch'}>
            <input
              id={'AutomaticAllocationToggle'}
              type={'checkbox'}
              readOnly
              className={envelopeSchedule.isActive ? 'custom-control-input toggle-enabled' : 'custom-control-input'}
              checked={envelopeSchedule.isActive || false}
              onClick={(e) => {
                tagAllocationToggle(e.target.checked, editingAccount, user);
                setEnvelopeSchedule({...envelopeSchedule, isActive: e.target.checked});
                updateEnvelopeSchedule();
              }}
              disabled={noEnvelopesAlert}
              aria-disabled={noEnvelopesAlert}
              aria-label={envelopeSchedule.isActive ? 'Toggle Automatic Allocation Off' : 'Toggle Automatic Allocation On'}
            />
            <label htmlFor={'AutomaticAllocationToggle'} className={'custom-control-label'} style={styles.toggleLabel}>
              {envelopeSchedule.isActive ? 'On' : 'Off'}
            </label>
          </div>
          <Badge variant="light" id="friendlyAllocationSummary">
            {envelopeSchedule.isActive ? (
              <>
                <FontAwesomeIcon icon={faCalendar} style={universalStyles.iconMarginRight} />
                <span id="friendlyPercent">{envelopeSchedule.isDollarAllocation ? getNumWithCommas(overallDollarAmount.toFixed(2)) : `${overallPercent}%`} </span>
                {frequencyLabel.trim() !== frequencyOptions[0].label.toLowerCase().trim() && dateDisplay && (
                  <span id="friendlyFrequencyDay">
                    on{' '}
                    {frequencyLabel.trim() !== frequencyOptions[3].label.toLowerCase().trim()
                      ? moment(dateDisplay).format('dddd')
                      : `day ${moment(dateDisplay).date()} of`}
                  </span>
                )}
                <span id="friendlyFrequency">{frequencyLabel}</span>
              </>
            ) : (
              <span id="friendlyToggle">Turned Off</span>
            )}
          </Badge>
        </Flexbox>
      </Col>
      <Col xs={'12'} style={{paddingBottom: 32}}>
        <Row style={{marginBottom: '1rem'}}>
          <Col xs={'12'} md={'8'}>
            <Form>
              <Form.Group controlId={'isDollarAllocation'}>
                <Form.Label style={styles.inputLabel}>How do you want to allocate your money?</Form.Label>
                <Form.Check
                  type={'radio'}
                  name={'isDollarAllocation'}
                  id={'dollar-amount-allocation'}
                  style={{marginBottom: '1rem'}}
                >
                  <Form.Check.Input type={'radio'} checked={envelopeSchedule.isDollarAllocation} onChange={() => setEnvelopeSchedule({...envelopeSchedule, isDollarAllocation: true})} disabled={!envelopeSchedule.isActive} aria-disabled={!envelopeSchedule.isActive} />
                  <Form.Check.Label style={styles.radioInputLabel}>Using Dollar amounts ($)</Form.Check.Label>
                </Form.Check>
                <Form.Check
                  type={'radio'}
                  name={'isDollarAllocation'}
                  id={'percentage-allocation'}
                >
                  <Form.Check.Input type={'radio'} checked={!envelopeSchedule.isDollarAllocation} onChange={() => setEnvelopeSchedule({...envelopeSchedule, isDollarAllocation: false})} disabled={!envelopeSchedule.isActive} aria-disabled={!envelopeSchedule.isActive} />
                  <Form.Check.Label style={styles.radioInputLabel}>Using Percentages (%)</Form.Check.Label>
                </Form.Check>
              </Form.Group>
              <Form.Group controlId={'date'}>
                <Form.Label style={styles.inputLabel}> What day do you want to start and run this? </Form.Label>
                <InputGroup>
                  <Flexbox style={{width: '100%'}}>
                    <Form.Control
                      as={DatePicker}
                      ref={datePickerRef}
                      minDate={new Date()}
                      selected={envelopeSchedule.startDate ? envelopeSchedule.startDate : null}
                      value={dateDisplay}
                      onChange={(startDate, e) => {
                        if (e && (e.type === 'click' || (e.type === 'keydown' && e.keyCode === 13))) {
                          if (startDate) {
                            updateEnvelopeSchedule();
                            setEnvelopeSchedule({...envelopeSchedule, startDate});
                            setDateDisplay(moment(startDate).format('MM/DD/YYYY'));
                            appendRef.current.focus();
                          }
                        } 
                      }}
                      onBlur={(e) => {
                        const newStartDate = moment(e.target.value);
                        setValidDate(newStartDate.isValid());
                        updateEnvelopeSchedule();

                        if (newStartDate.isValid() === false) {
                          setEnvelopeSchedule({...envelopeSchedule, startDate: null});
                        } else {
                          setEnvelopeSchedule({...envelopeSchedule, startDate: new Date(newStartDate)});
                          setDateDisplay(newStartDate.format('MM/DD/YYYY'));
                        }
                        appendRef.current.focus();
                      }}
                      onChangeRaw={(e) => {
                        if (!e.target.value || e.target.value === undefined) {
                          setDateDisplay(e.target.value);
                          return;
                        }

                        const dateInput = e.target.value.replace(/[^(\d|/)]/g, '');
                        setDateDisplay(dateInput);
                      }}
                      disabled={!envelopeSchedule.isActive}
                      aria-disabled={!envelopeSchedule.isActive}
                      isInvalid={!moment(envelopeSchedule.startDate).isValid()}
                    />
                    <InputGroup.Append>
                      <button
                        ref={appendRef}
                        id={'OpenCalendar'}
                        aria-label={'Open Calendar'}
                        type={'button'}
                        className={'append-button'}
                        onClick={() => {
                          if (envelopeSchedule.isActive) {
                            dateInteract();
                          }
                        }}
                        onKeyDown={(e) => {
                          if (envelopeSchedule.isActive) {
                            if (e.keyCode === 13 || e.keyCode === 32) {
                              e.preventDefault();
                              dateInteract();
                            }
                          }
                        }}
                        disabled={!envelopeSchedule.isActive}
                        aria-disabled={!envelopeSchedule.isActive}
                      >
                        <FontAwesomeIcon icon={faCalendar} color={colors.stone} />
                      </button>
                    </InputGroup.Append>
                  </Flexbox>
                  <Form.Control.Feedback id={'invalid-date'} type={'invalid'} role={'alert'} style={{display: !moment(envelopeSchedule.startDate).isValid() ? 'inline-block' : 'none'}}>
                    Please enter a valid date (mm/dd/yyyy).
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Form>
          </Col>
          <Col xs={'12'} md={'8'}>
            <Form.Group controlId={'frequency'}>
              <Form.Label style={styles.inputLabel}> How often do you want to allocate money? </Form.Label>
              <Form.Control
                as={'select'}
                custom
                value={envelopeSchedule.frequencyType ? envelopeSchedule.frequencyType : 'Day'}
                onChange={(e) => {
                  setEnvelopeSchedule({...envelopeSchedule, frequencyType: e.target.value});
                  updateEnvelopeSchedule();
                }}
                style={styles.inputContainer}
                disabled={!envelopeSchedule.isActive}
                aria-disabled={!envelopeSchedule.isActive}
              >
                {frequencyOptions.map((frequencyOption, index) => (
                  <option key={index} value={frequencyOption.value}>
                    {frequencyOption.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <AlertBanner show={() => !envelopeSchedule.isDollarAllocation && overallPercent > 100} dismissible={false} message={OVER_MAX_ALLOCATION} type={'danger'} imgName={'danger'} />
        <Row style={{marginBottom: 16}}>
          <Col xs={'12'} md={'8'}>
            <label htmlFor="AllocationTable" style={styles.inputLabel}>{`What ${!envelopeSchedule.isDollarAllocation ? 'percent of your allocatable balance' : 'dollar amount'} would you like to distribute?`}</label>
            <Table id="AllocationTable" style={{marginBottom: 0, tableLayout: 'fixed'}}>
              <thead>
                <tr>
                  <th style={{...styles.tableHeader, ...styles.smallBoldText, borderBottomWidth: 0}}>Envelope Name</th>
                  <th style={{...styles.tableHeader, ...styles.smallBoldText, borderBottomWidth: 0, textAlign: 'right'}}>{envelopeSchedule.isDollarAllocation ? 'Dollar Amount' : 'Percent'}</th>
                </tr>
              </thead>
              <tbody>
                {envelopeSchedule?.envelope?.map((env, index) => (
                  <tr key={`row-${index}`}>
                    <td style={{...styles.tableInputAppend, ...styles.envelopeName, borderRadius: index === envelopeSchedule.envelope.length - 1 ? '0 0 0 4px' : 0}}>
                      <div name={env.name} ref={el => envelopeNameCellRef.current[index] = el}>
                        {env.name}
                      </div>
                    </td>
                    <td style={{padding: 0, verticalAlign: 'inherit', border: '1px solid rgb(211, 211, 204)'}}>
                      <AllocationInput
                        key={index}
                        envSchedule={env}
                        index={index}
                        updateParent={updateEnvelopeValue}
                        envelopeSchedule={envelopeSchedule}
                        overallPercent={overallPercent}
                        isDollarAllocation={envelopeSchedule.isDollarAllocation}
                        envelopeNameCellRef={envelopeNameCellRef?.current[index]}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr style={{padding: 12, border: '1px solid #D3D3CC', borderTopWidth: 0}}>
                  <td style={styles.smallBoldText}>
                    Total
                  </td>
                  <td style={{...styles.smallBoldText, textAlign: 'right'}}>
                    {envelopeSchedule.isDollarAllocation ? getNumWithCommas(overallDollarAmount.toFixed(2)) : `${overallPercent}%`}
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
        <AlertBanner
          show={() => true}
          type={'info'}
          name={'alert-banner'}
          icon={faInfoCircle}
          iconPlacement={'flex-start'}
          dismissible={false}
          alertStyle={{marginBottom: 0}}
          message={''}
          template={
            !envelopeSchedule.isDollarAllocation ? (
              <>
                <p key="AllocationTipBody-1" style={{marginBottom: '1em'}}>Automatic allocations run between 7:00 AM and 10:00 AM PST and will only occur if:</p>
                <p key="AllocationTipBody-2">
                  You have <strong>$1.00 or more</strong> in your allocatable balance.
                  <br />
                  You have set <strong>at least 1%</strong> to be allocated to your envelopes.
                </p>
              </>
            ) : (
              <>
                <p key="AllocationTipBody-1">Automatic allocations run between 7:00 AM and 10:00 AM PST and will only occur if all envelope automatic allocations can be funded in full.</p>
              </>
            )
          }
        />
      </Col>
    </Row>
  );

  return (
    <div style={styles.container}>
      <div style={styles.headerWrapper}>
        {AllocationHeader()}
        <SaveSuccessfulAllocationAlert allocationSavedBanner={allocationSavedBanner} setAllocationSavedBanner={setAllocationSavedBanner} />
        <NoEnvelopesAlert showAlert={noEnvelopesAlert} alertFunction={alertFunction} />
      </div>
      {TILES.map((tile, index) => AllocationTile(index, tile.title, tile.body, tile.type))}
    </div>
  );
};

export default AllocationSettings;
