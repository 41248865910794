import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';

import {styles} from './styles';
import {universalStyles} from 'styles/universalStyles';
import {modalType} from 'types';
import FocusLock from 'react-focus-lock';

const ConfirmationModal = ({modalData, modalFunction, setModalVisibleStatus, showModal}) => {
  return (
    <>
      <Modal id={`ConfirmationChangeModal-${modalData.title}`} show={showModal} onHide={() => setModalVisibleStatus(false)}>
        <FocusLock>
          <Modal.Header closeButton>
            <Modal.Title style={universalStyles.modalHeader}>{modalData.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={styles.modalBody}>{modalData.body}</Modal.Body>
          <Modal.Footer style={styles.modalFooter}>
            <Button className={'envelope-confirmation-button'} variant="primary" style={universalStyles.fullWidth} onClick={() => modalFunction()}>
              {modalData.yesText}
            </Button>
            <Button className={'envelope-cancel-button'} variant="secondary" style={universalStyles.fullWidth} onClick={() => setModalVisibleStatus(false)}>
              {modalData.noText}
            </Button>
          </Modal.Footer>
        </FocusLock>
      </Modal>
    </>
  );
};

ConfirmationModal.propTypes = {
  modalData: modalType,
  modalFunction: PropTypes.func,
  setModalVisibleStatus: PropTypes.func,
  showModal: PropTypes.bool,
};

export default ConfirmationModal;
